.searchSubTitleQna {
  top: 5px;
  margin-left: 7px;
  float: right;
}

.writeBtn {
  color: black;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  background: #fff;
  border: 1px solid #dddddd;
  border-radius: 5px;
  padding: 4px 15px;
  font-weight: bold;
  text-align: center;
  text-shadow: none;
  text-transform: uppercase;
  -webkit-transition: all 280ms ease;
  transition: all 280ms ease;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  cursor: pointer;
  -webkit-appearance: none;
  display: inline-block;
}

.fixTextarea {
  width: 100%;
  height: 200px;
  resize: none; /* 크기고정 */
  float: left;
  /*   resize: horizontal; // 가로크기만 조절가능 
	resize: vertical;  세로크기만 조절가능  */
}
.fixSpan {
  padding: 2px;
  width: 100%;
  display: inline-block;
  border: solid 1px;
  height: 300px;
  /* border-width: 1px; */
  /* border-style: solid; */
}

.fixSpan2 {
  padding: 2px;
  width: 100%;
  display: inline-block;
  height: 300px;
  /* border-width: 1px; */
  /* border-style: solid; */
}

.answerForm {
  width: 100%;
}

.inputUnvisible {
  width: 100%;
  border: none;
}
.inputVisible {
  width: 50%;
}

.wrterNm {
  width: 50%;
}

.gridContents {
  border: ridge gray;
  border-width: 0.01em;
  text-align: left;
  padding-left: 1%;
  padding-top: 1%;
  background-color: white;
}

.gridContents .ctnt {
  padding-left: 1%;
}

.gridContents .fileList {
  background-color: whitesmoke;
  border: 0.0625rem solid #d8d8d8;
  color: #5d5d5d;
  font-size: 0.8125rem;
  width: auto;
  margin-left: 0.625rem;
}

.gridContents .downBtn {
  color: black;
  border-color: #ababab;
  background-color: #c1c1c1;
  border-radius: 2px;
  padding: 4px 8px;
  box-sizing: border-box;
  border-width: 1px;
  border-style: solid;
  font-size: 14px;
  line-height: 1.42857;
  font-family: inherit;
  text-align: center;
  text-decoration: none;
  white-space: nowrap;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  vertical-align: middle;
  -webkit-user-select: none;
  user-select: none;
  cursor: pointer;
  outline: none;
  -webkit-appearance: none;
  position: relative;
}

  .searchTerm {
    margin-left: 5px;
    width: 300px;
    font-size: 14px;
    border-radius: 4px;
    border: 1px solid #dddddd;
    padding: 5px 4px;
    color: rgb(8, 8, 8);
    vertical-align: -2px;
    background-color: white;
  }
  
.qnaBtn {
  padding: 8px;
  margin-left: 5px;
  background: #e4e4e4;
  border-radius: 5px;
}

.qnaBtn:hover,
.qnaBtn:focus {
  background-color: white;
  border: 1px solid #b9b0b0;
}

.qnaLoading {
  position: relative;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 60vh;
  pointer-events: none; 
  /* z-index: -1; */
}