/* 데스크탑 일반 */
@media (min-width: 768px) {
  .installerMeain thead tr th:nth-of-type(3) {
    border-right: none;
  }
}

.installerMeain-info {
  /* width: 647px; */
  /* height: 390px; */
  /* border: none; */
}
.installerMeain-info .k-window-titlebar {
  padding: 5px 8px;
}
.installerMeain-info .meainInfoTab {
  padding: 5px 5px;
}
.installerMeain-info .meainInfoTab .k-animation-container {
  width: 100%;
}

.installerMeain-info table {
  width: 100%;
}

.installerMeain-info table th {
  font-size: 13px;
  width: 20%;
  text-align: center;
  /* border: 1px solid #dddddd; */
}
.installerMeain-info table td {
  width: 30%;
  text-align: right;
  padding-right: 1em;
}

.installerMeain .meainStatCount {
  text-align: center;
  font-size: 14px;
}

.installerMeain {
  border-spacing: 0px;
  width: 100%;
  border: 1px solid #dddddd;
}
.installerMeain thead tr td {
  color: #fff;
  font-size: 15px;
  padding: 4px 0 4px 8px;
  border-bottom: 1px solid #dddddd;
}
.installerMeain thead tr th {
  padding: 5px 0;
  border-right: 1px solid #dddddd;
  background-color: #fff;
  /* cursor: pointer; */
}

.installerMeain thead tr th:nth-of-type(3) {
  border-right: none;
}
.installerMeain tbody tr th {
  color: #333;
  background-color: #eeeeee;
  font-size: 14px;
  padding: 2px;
  border-top: 1px solid #dddddd;
}
.installerMeain tbody tr td {
  width: 90px;
  text-align: center;
  color: #1e88e5;
  font-size: 12px;
  padding: 2px;
  border-top: 1px solid #dddddd;
  text-overflow: ellipsis;
  background-color: #fff;
}


.installerMeainPopW {
  border-spacing: 0px;
  width: 100%;
  border-top: 1px solid #dddddd;
  border-left: 1px solid #dddddd;
  /* border: 1px solid #dddddd; */
}
.installerMeainPopW thead tr td {
  color: #fff;
  font-size: 15px;
  padding: 4px 0 4px 8px;
  border-bottom: 1px solid #dddddd;
}
.installerMeainPopW thead tr th {
  padding: 5px 0;
  border-right: 1px solid #dddddd;
  background-color: #fff;
  /* cursor: pointer; */
}

.installerMeainPopW thead tr th:nth-of-type(3) {
  border-right: none;
}
.installerMeainPopW tbody tr th {
  color: #333;
  background-color: #eeeeee;
  font-size: 14px;
  padding: 2px;
  border-bottom: 1px solid #dddddd;
}
.installerMeainPopW tbody tr td {
  width: 90px;
  text-align: right;
  color: #1e88e5;
  font-size: 13px;
  padding: 5px;
  padding-right: 10px;
  /* border-top: 1px solid #dddddd; */
  text-overflow: ellipsis;
  background-color: #fff;
  border-right: 1px solid #dddddd;
  border-bottom: 1px solid #dddddd;
}
