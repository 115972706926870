.searchPadding {
  padding: 15px 10px !important;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
  border-radius: 5px;
}

.monitorASLoading {
  position: absolute;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 90%;
  pointer-events: none;
  bottom: 45%;
  /* z-index: -1; */
}

.monitorASModalLoading {
  position: absolute;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 90%;
  pointer-events: none;
  bottom: 45%;
}

.noHistoryCommentBox{
  height: 80%;
  overflow: auto;
  border: 1px solid #acacac;
  border-radius: 0.5rem;
  padding: 2%;
  clear: both;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin : 5% 0px;
}

.addlHistoryCommentBox {
  height: 308px;
  overflow: auto;
  /* border-bottom: 1px solid #acacac; */
}

.addlHistoryComment {
  border: 1px solid #acacac;
  border-radius: 0.5rem;
  margin-bottom: 3%;
  padding: 2%;
  clear: both;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}

.addlHistoryCommentId {
  font-size: 15px;
  font-weight: bolder;
  color: black;
}

.addlHistoryCommentBody {
  font-size: 15px;
  /* font-weight: bold; */
  color: black;
  margin-top: -2%;
}

.addlHistoryAddBtn {
  margin-top: 0%;
  margin-bottom: -2%;
  height: 60px;
  border-color: #acacac;
  border-top-right-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
}

.addlHistoryInputBox {
  margin-top: 0%;
  margin-bottom: -3%;
  margin-right: -1%;
  height: 60px;
  width: 90.5%;
  float: left;
  border-color: #acacac;
  border-top-left-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
}

.addlHistoryCommentBtnSpace {
  float: right;
}

.addlHistoryCommentBtn {
  border-radius: 0.5rem;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}

.addlHistoryPopUpBtn {
  border-radius: 0.5rem;
  border: 1px solid #acacac;
  float: right;
  background-color: #5B9BD5;
  color: white;
  margin-left: 3%;
  width: 22%;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
}