.produceTb tr td {
  padding: 20px 0;
  line-height: 35px;
}

.produceTb{
  border-spacing: 10px;
  border-collapse: separate;
    width: 100%;
    margin: 30px 0 0 40px;
}

.checkBoxForm{
  display: inline-block !important;
  width: 19px;
  height: 19px;
  margin: -2px 5px 0 0;
  vertical-align: middle;
  cursor: pointer;
}

.notiRcpmsgTm{
  width: 200px;
  border-radius: 4px;
  border: 1px solid #e3e3e3;
  /* font-family: "Noto Sans KR", sans-serif; */
  font-weight: 200;
  box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0);
  padding: 0px 10px;
  background: #fff;
  height: 31px;
}