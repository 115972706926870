.rems {
  font-weight: bold;
  font-size: 19px;
  padding-right: 5px;
  color: #1e88e5;
}
.dropDownList {
  margin-left: 20px;
  margin-right: 20px;   
}
.areaHeaderSub {
  font-size: 13px;
  font-family: "Noto Sans KR", sans-serif;
  font-weight: 600;
  text-align: center;
  padding: 2px 0 2px 0;
  color: #333;
  background-color: #fafafa;
  border-bottom: 1px solid #dddddd;
}
/* pc css */
@media (min-width: 767px) {
  .cityTerm {
    font-weight: bold;
    padding-right: 5px;
    font-size: 19px;
  }

  .meainStatCntBox {
    position: relative;
    display: block;
    width: 100.7%;
  }

  .meainStatCnt {
    margin: 0;
    padding: 0;
    border-spacing: 0px;
    float: left;
    border-left: 1px solid #dddddd;
    width: 14.3%;
    margin-left: -1px;
  }
  .meainStatCntEnso {
    padding: 9px 0 8px 0;
    border-top: 1px solid #dddddd;
    border-bottom: 1px solid #dddddd;
    border-right: 1px solid #dddddd;
    background-color: #fafafa;
  }
  .meainStatCntType {
    text-align: center;
    padding: 6px 4px 6px 4px;
    font-size: 15px;
    vertical-align: bottom;
    font-weight: 500;
    font-size: 14px;
    border-bottom: 1px solid #dddddd;
    border-right: 1px solid #dddddd;
    background-color: #f5f5f5;
  }
  .meainStatCntVal {
    border-bottom: 1px solid #dddddd;
    border-right: 1px solid #dddddd;
    text-align: right;
    font-weight: 500;
    font-size: 18px;
    padding-right: 10px;
    padding-left: 10px;
    background-color: #fff;
  }
  .meainStatCntTerm {
    color: #999;
    padding-left: 5px;
    font-size: 14px;
  }
  .meainStatCntBox > table thead tr{
    cursor: pointer !important;
  }
  .meainStatCntBox > table > tbody > tr > th,
  .meainStatCntBox > table > tbody > tr > td {
    cursor: pointer !important;
  }    
}
/* 모바일 css */
@media (max-width: 767px) {
  .cityTerm {
    font-weight: bold;
    padding-left: 5%;
    font-size: 19px;
  }

  .meainStatCntBox {
    width: 100%;
  }

  .meainStatCnt {
    margin: 0;
    padding: 0;
    border-spacing: 0px;
    border-left: 1px solid #dddddd;
    width: 100%;
  }
  .meainStatCnt tbody tr th {
    width: 40%;
  }
  .meainStatCnt tbody tr td {
    width: 60%;
  }
  .meainStatCntEnso {
    padding: 9px 0 8px 0;
    border-top: 1px solid #dddddd;
    border-bottom: 1px solid #dddddd;
    border-right: 1px solid #dddddd;
    background-color: #fafafa;
  }
  .meainStatCntType {
    text-align: center;
    padding: 6px 4px 6px 4px;
    font-size: 15px;
    vertical-align: bottom;
    font-weight: 500;
    font-size: 14px;
    border-bottom: 1px solid #dddddd;
    border-right: 1px solid #dddddd;
    background-color: #f5f5f5;
  }
  .meainStatCntVal {
    border-bottom: 1px solid #dddddd;
    border-right: 1px solid #dddddd;
    text-align: right;
    font-weight: 500;
    font-size: 18px;
    padding-right: 10px;
    padding-left: 10px;
    background-color: #fff;
  }
  .meainStatCntTerm {
    color: #999;
    padding-left: 5px;
    font-size: 14px;
  }
}
.rgnTerm {
  font-weight: bold;
  padding-right: 5px;
}

.totPowerNum {
  color: #ee7951;
}

.colorG {
  color: #82b440;
}

.areaTerm {
  cursor: pointer;
}

.areaTerm:hover {
  background: #1e88e5;
  color: #fff;
}

/* 로딩스피너 */
/* .areaTable {
  pointer-events: none;
  opacity: 0.5;
} */

.areaTableLoading {
  position: absolute;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 90%;
  pointer-events: none;
  bottom: 50%;
}

.TitleHistory{margin-bottom: 15px;}
.TitleHistory .rgnTerm{font-size:19px;}