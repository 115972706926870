.meainModelGrid{
  border : 1px solid gainsboro;
}
.meainModelGrid .k-toolbar{
  border: none;
  border-bottom : 1px solid gainsboro;
  height: 50px;
}

.meainModelGrid table thead tr th input {
  width: 100%;
}

.meainModelGrid thead tr th {
  text-align: center;
}
