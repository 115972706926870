/* iotkt 틀 */
.iotktBody {
  /* border: 1px solid #e3e3e3; */
  background-color: white;
  height: 85vh;
  display: flex;
  justify-content: space-around;
  padding: 5px 0;
}
/* 좌측 div */
.leftForm {
  /* border: 1px dotted #e3e3e3; */
  width: 45%;
  margin-top: 2%;
  height: 90%;
}
/* 좌측 div - 그리드 상단 */
.iotktGridCate {
  /* border: 1px dotted red; */
  margin: 0 0 2px 0;
}
/* 좌측 div - 그리드 상단 - 버튼 */
.iotktGridCate button {
  /* border: 1px dotted red; */
  border-radius: 5px;
  background: white;
}
/* 우측 div */
.rightForm {
  /* border: 1px dotted #e3e3e3; */
  margin-top: 2%;
  width: 45%;
  height: 90%;
}
/* 우측 div - p태그 */
.rightForm p {
  margin: 0;
  font-weight: bold;
  color: #656565;
}
/* 우측 div - 개별 div */
.rightForm div {
  /* border: 1px dotted red; */
  margin: 0 0 20px 5px;
}
/* 우측 div - 버튼 */
.rightForm button {
  /* border: 1px dotted red; */
  margin: 0 5px 0 0;
  border-radius: 5px;
  background: white;
}