/* 하단 엑셀 다운로드 버튼 */
.makrExcelBtn {
  border: 1px solid #434343;
  border-radius: 6px;
  padding: 6px 10px;
  text-align: center;
  text-shadow: none;
  text-transform: uppercase;
  -webkit-transition: all 280ms ease;
  transition: all 280ms ease;
  color: white;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  cursor: pointer;
  -webkit-appearance: none;
  display: inline-block;
  background-color: #0c0c0c;
  margin-right: 7px;
  margin-top: 3px;
  margin-bottom: 3px;
  vertical-align: -2px;
}

.makrExcelBtn:hover {
  background-color: #0c0c0c;
  border: 1px solid #0082ff;
}

.makrExcelBtn img {
  background-color: white;
  color: white;
  width: 16px;
  margin-top: -2px;
  margin-right: 5px;
}

/* 하단 그리드 컬럼 */
.makrGrid div thead {
  text-align: center;
  font-weight: bold;
  font-size: 15px;
}
.makrGrid div thead tr {
  height: 64px;
}
.makrGrid div thead tr th {
  padding: 20px 12px;
}
.makrGrid table tbody tr :nth-of-type(5) {
  text-align: right;
}
.makrGrid table tbody tr :nth-of-type(3) {
  text-align: right;
}
.makrGrid table tbody tr :nth-of-type(4) {
  text-align: right;
}

.makrGrid table tbody tr {
  display: table-row;
}

.makrExcelBtnClass {
  margin-top: 5px;
  margin-bottom: 5px;
  margin-left: 5px;
  margin-right: 5px;
  border: 1px solid #d4cece;
  color: #333;
  background-color: white;
}

.makrExcelBtnClass:hover {
  border: 1px solid #1e88e5;
  /* color: #0082ff; */
  /* background-color: #0c0c0c; */
}
