.searchBtn{
  position: absolute;
  width: 40px;
  height: 28px;
  border: solid 1px #e3e3e3;
  border-left: none;
  background: #fff;
  text-align: center;
  cursor: pointer;
  font-size: 14px;
}
.expctConsmGrid{
  border: 1px solid gainsboro;
  height: 100%;
}
.expctConsmGrid thead {
  text-align: center;
  /* font-weight: bold; */
}