.filterBox {
  background-color: rgb(235, 235, 235);
  padding: 20px;
}
.filterTable tr td {
  padding: 4px;
}
.excelBtn {
  margin: 5px;
  background-color: white;
}
.statConsmGridDiv {
}

.statConsmGrid {
  border: 1px solid rgb(228, 228, 228);
}

.statConsmGrid thead tr th {
  vertical-align: middle !important;
  font-size: 13px;
  text-align: center;
  padding: 5px;
}

/* 켄도 그리드 바디 */
.statConsmGrid div div div table tbody tr td {
  text-align: left;
  padding: 7px;
}

.statConsmGrid div div div table tbody tr td:first-child {
  text-align: center;
}
