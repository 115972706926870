#sidebar_main .menu_section ul > li {
    display: flex;
    justify-content: center;
    height: 80px;
  }
  #sidebar_main .menu_section ul > li:hover {
    cursor: pointer;
    /* background: #fff; */
  }
  #sidebar_main .menu_section ul > li:hover svg {
    font-size: 42px;
    color: #1e88e5;
  }
  
  .menu_section .k-menu-vertical .k-item .k-icon.k-menu-expand-arrow {
    display: none;
  }
  
  .menu_section .k-menu-vertical .k-item > .k-link {
    padding-right: 0px;
    padding-left: 0px;
    justify-content: center;
  }
  .menu_section .menu_icon svg {
    font-size: 35px;
    color: #c5c5c5;
  }
  
  .menu_section .menu_icon .material-icons-active {
    font-size: 38px;
    color:white;
  }
  .menuIconTxtActive{  
    font-weight: bold;
    color:white;
  }
  .sideNaviMenu > ul > li{
    z-index: 9999;
  }
  .sideNaviMenu .k-menu-item:hover .menuIconTxtActive,
  .sideNaviMenu .k-menu-item:hover .menuIconTxt{
    color: #1e88e5;
  }
  /* .menu_section .menu_icon svg:hover {
    font-size: 36px;
    color: #1e88e5;
  } */
  
  .sideNaviMenu >ul > li{
    text-align: center;
  }
  
  .iconActive{  
    color:#1e88e5 ;
  }
  .k-state-active .menuIconTxt{
    /* color:#1e88e5 ; */
  }.k-state-active .menuIconTxtActive{
    /* color:#1e88e5 ; */
  }
  .menuIconTxt:hover{
      color:red!;
  }
  .menuIconTxt{
    font-weight: bold;
    color:rgb(189, 189, 189);
  }