/* 전체 페이지 틀 */
.makrBody {
  /* border: 1px dotted red; */
  /* height: 100vh; */
  /* display: flex; */
  /* flex-direction: column; */
}
/* 상단 카테고리 틀 */
.makrCate {
  margin: 0 0 20px;
  border: 1px solid #dddddd;
  background: #fafafa;
  border-radius: 5px;
  padding: 20px 10px;
  margin: 0 0 25px;
}
/* 상단 카테고리 틀 내부 엘리먼트 */
.makrCate > span {
  /* border: 1px dotted blue; */
  margin-left: 10px;
}
/* 상단 카테고리 틀 내부 3번째 엘리먼트 */
.makrCate > span:nth-child(3) {
  /* border: 1px dotted blue; */
  margin-right: 10px;
}
/* 상단 신호 보드 */
.makrBoard {
  margin: 0 0 25px;
  /* background: #abcdef; */
  height: 15%;
  width: 100%;
  display: flex;
  justify-content: space-between;
}
/* 상단 신호 보드 내부 DIV */
.makrBoardSub {
  cursor: pointer;
  /* color: white; */
  border: 1px solid #dddddd;
  background: #fafafa;
  border-radius: 5px;
  width: 23.5%;
  height: 70%;
  padding: 20px 0;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
/* 상단 신호 보드 내부 제목 */
.makrBoardSub div span:nth-child(1) {
  /* border: 1px dotted red; */
  font-size: 19px;
}
/* 상단 신호 보드 내부 내용 - 0 건 */
.makrBoardContent {
  /* border: 1px dotted blue; */
  display: table;
  margin-left: auto;
  margin-right: auto;
  margin-top: 5px;
}
/* 상단 신호 보드 내부 내용 - 숫자 */
.makrBoardContent b {
  /* border: 1px dotted red; */
  color: #82b440;
  font-weight: 500;
  font-size: 30px;
}
/* 상단 신호 보드 내부 내용 - 글씨(건) */
.makrBoardContent span {
  /* border: 1px dotted red; */
  color: #9090a7;
  font-size: 17px;
  padding-left: 10px;
}
/* 상단 신호 보드 manaTm 내부 DIV */
.makrBoardSubManaTm {
  border: 1px solid #dddddd;
  border-radius: 5px;
  background: #fafafa;
  width: 23.5%;
  height: 70%;
  padding: 20px 0;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
/* 상단 신호 보드 manaTm 내부 제목 */
.makrBoardSubManaTm div span:nth-child(1) {
  font-size: 19px;
}
.makrGrid table thead tr th {
  text-align: center;
}

/* 하단 이벤트 및 상세정보 틀 */
.makrArticle {
  /* background: #abcdef; */
  height: 80%;
  display: flex;
  justify-content: space-between;
}
/* 하단 이벤트 틀 */
.makrArticleSub {
  /* border: 1px solid white; */
  width: 49%;
  height: 100%;
}
/* 하단 이벤트 틀 제목 */
.makrArticleSubTitle {
  border: 1px solid #dddddd;
  background: #eeeeee;
  height: 3%;
  display: flex;
  justify-content: space-between;
}
/* 하단 이벤트 틀 제목 글씨 */
.makrArticleSubTitle h3 {
  /* line-height: 40px; */
  font-weight: 600;
  font-size: 19px;
  padding-top: 10px;
  padding-left: 10px;
}
/* 하단 이벤트 틀 - 에너지원별 이벤트 - 차트 */
.makrArticleEngCharts {
  border: 1px solid #dddddd;
  /* border: 1px dotted red; */
  height: 89%;
}
.makrArticleEngCharts > div {
  border: 1px solid #dddddd;
  /* border: 1px dotted red; */
  height: 49.7%;
  width: 99.7%;
}
/* 하단 이벤트 틀 - 시간대별 이벤트 - 차트 */
.makrArticleTimeCharts {
  border: 1px solid #dddddd;
  height: 44.5%;
}
/* 하단 이벤트 틀 - 시간대별 이벤트 - 그리드 */
.makrArticleTimeGrid {
  border: 1px solid #dddddd;
  height: 44.5%;
}

.makrArticleEngCharts text {
  fill: black !important;
}

/* .makrArticleTimeCharts text {
  fill: white !important;
} */

/* ----------------------------------------------------- */
