.monitorASSearchDropDown {
  width: 9%;
  margin-right: 1%;
}

.monitorASSearchBtnRight {
  float: right;
}

.monitorASSearchBtnRight .excelBtn {
  width: 120px;
  height: 30px;
  margin-left: -10px;
}

.monitorASFloatRight {
  float: right;
  text-align: center;
  margin-left: 2%;
  width: 15%;
  border: 1px solid #acacac;
  padding-top: 0.5%;
}

.monitorASCapa {
  width: 20%;
}

.monitorASGrid {
  height: calc(100vh - 210px);
  border: 1px solid gainsboro;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
}

.monitorASGrid th {
  font-weight: bold;
  font-size: 15px;
  text-align: center;
}

.monitorASGrid td:nth-child(1) {
  font-size: 17px;
  font-weight: bold;
}
.monitorASGrid td:nth-child(2) {
  text-align: left;
  padding-left: 4%;
}
.monitorASGrid td:nth-child(3) {
  text-align: left;
  padding-left: 3.5%;
}
.monitorASGrid td:nth-child(4) {
  text-align: left;
  padding-left: 3%;
}
.monitorASGrid td:nth-child(5) {
  text-align: left;
  padding-left: 4.5%;
}
.monitorASGrid td:nth-child(6) {
  text-align: left;
  padding-left: 3.5%;
}

.monitorASGrid table tbody tr td {
  border-bottom: 1px solid gainsboro;
}

.monitorASGrid table tbody td b{
  font-size: 20px;
}

.loadingState {
  opacity: 0.6;
  pointer-events: none;
}

.monitorASBtn {
  width: 7.3%;
  height: 100%;
  border: 1px solid #acacac;
  margin-right: -0.05%;
  margin-bottom: -75px;
  text-align: center;
  font-size: 17px;
  font-weight: bold;
}

.monitorASModalBtnSpace {
  width: 100%;
  margin-bottom: 40px;
}

.addlHistoryBox {
  margin-top: 5%;
}