.weathChartDiv {
  height: 400px;
}

.radioForm {
  margin-left: 10px;
}

.radioForm > label {
  margin-top: 3px;
}
.weathCmp {
  pointer-events: none; 
  /* padding-top: 1px; */
  width: 94.5%;
  position: absolute;
  opacity: 0.5;
}

.weathCmpLoading {
  position: relative;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 60vh;
  pointer-events: none; 
  /* z-index: -1; */
}
.searchBtn {
  background: white;
  border-radius: 5px;
  padding-left: 20px;
  padding-right: 20px;
}