.menuWrap {
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  padding: 0.625rem;
  height: 100%;
}

.chmapTBox{
  border: 1px solid #e06c5f;
  /* height: 900px; */
  margin: 40px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  background-color: #fafafa;
  margin-top: 55px;
}

.chmapTbox .k-pager-wrap.k-pager-lg .k-pager-numbers, .k-pager-wrap.k-pager-md .k-pager-numbers, .k-pager-wrap.k-pager-sm .k-pager-numbers {
    border-color: rgba(0, 0, 0, 0.08);
    background-color: #f6f6f6;
    display: none;
}

.ChMapTb-A{
  margin-left: 1555px;
  margin-top: -830px;
  width: 180px;
  position: absolute;
  z-index: 10;
  background-color: #fafafa;
  border: 1px solid #dddddd;
  padding: 10px;
}

.searchDropDown{
  width: 198px;
}

.chmapTable-D tbody tr th{
  width: 25%;
}
.installerPager{
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  border-bottom: 1px solid #e06c5f;
}


.installerPager div.k-pager-info{
  display: block !important;
}

/* 마커 설비정보CSS */
.tdCloseBtn{
  text-align: right !important;
}

/* 설비 마커 정보창 CSS */
.MeainInfo {
  position: absolute;
  z-index: 10;
  border: 1px solid #dddddd;
  width: 250px;
  height: 1000px;
  -webkit-box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16),
    0 3px 6px rgba(0, 0, 0, 0.23);
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}
.MeainInfo table {
  border-spacing: 0px;
  width: 100%;
}
.MeainInfo table thead tr th {
  background-color: #fff;
  text-align: left;
  padding: 7px 0 7px 10px;
  border-bottom: 1px solid #dddddd;
}
.MeainInfo table thead tr td {
  background-color: #fff;
  padding: 7px 10px 7px 10px;
  border-bottom: 1px solid #dddddd;
  text-align: right;
}
.MeainInfo table thead tr th:nth-of-type(2) {
  border-left: 1px solid #dddddd;
  width: 330px;
}
.MeainInfo table tbody tr th {
  background-color: #eeeeee;
  text-align: left;
  padding: 7px 0 7px 10px;
  border-bottom: 1px solid #dddddd;
}
.MeainInfo table tbody tr td {
  background-color: #fff;
  padding: 7px 10px 7px 10px;
  border-bottom: 1px solid #dddddd;
  text-align: left;
}

.moveInstaller{
  border: solid 1px #e3e3e3;
  background: #fff;
  width: 40px;
  height: 31px;
  cursor: pointer;
}

/*설비 마커 정보창 닫기  */
.closeMeainInfoBtn{
  cursor: pointer;
}


/* 클러스터 정보창 CSS */
.plcNmWrapper {
  /* border-radius: 0.625rem 0.625rem 0 0; */
  background-color: #e06c5f;
  color: white;
  padding: 0.27rem;
  /* padding: 0.3125rem; */
  /* font-size: 0.875rem; */
}

#iWindow {
  font-size: 0.875rem;
}

#iWindow > table th {
  /* text-align: right; */
  padding-right: 0.3125rem;
  text-align: center;
}

#iWindow > table td {
  /* text-align: right; */
  padding-right: 0.3125rem;
  text-align: center;
}

.meainMarkerInfo {
  width: 300px;
  background-color: hsl(0, 0%, 100%);
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.meainMarkerInfo.absolute {
  position: absolute;
  top:100px;
  left: 300px;
}

.meainMarkerInfo .chmapTable-D th,
.meainMarkerInfo .chmapTable-D td {
  font-weight: 400;
  font-size: 13px;
  padding-top: 0px;
  padding-bottom: 0px;
  line-height: 20px;
}
.meainMarkerInfo .chmapTable-D th {
  text-align: center;
  width: 40%;
}
.meainMarkerInfo .chmapTable-D td {
  text-align: right;
  padding-right: 10px;
  width: 50%;
}

.meainMarkerInfo .meainMarkerInfoHeader {
  padding: 5px 5px 5px 15px;
  border-bottom: 1px solid #4d4d4d;
  font-weight: 500;
  vertical-align: middle;
}

.meainMarkerInfo .meainMarkerInfoGo {
  margin-left: auto;
  float: right;
  cursor: pointer;
  height: 30px;
}

.meainMarkerInfo .meainMarkerInfoClose {
  float:right;
  color: #fff;
  margin-left: 10px;
  height: 30px;
  cursor: pointer;
}



.meainInfoTable {
  border-spacing: 0px;
  width: 100%;
}
.meainInfoTable thead tr th {
  background-color: #fafafa;
  border-bottom: 1px solid #dddddd;
  text-align: left;
  font-size: 16px;
  font-weight: 500;
  padding: 8px 0 11px 8px;
}
.meainInfoTable thead tr td {
  background-color: #fafafa;
  text-align: right;
  padding-right: 10px;
  border-bottom: 1px solid #dddddd;
}
.meainInfoTable tbody th {
  border-bottom: 1px solid #dddddd;
}
.meainInfoTable tbody tr td:nth-of-type(1) {
  background-color: #eeeeee;
  border-bottom: 1px solid #dddddd;
  font-size: 14px;
  font-weight: 500;
  padding: 6px 0 6px 7px;
  width: 110px;
  border-right: 1px solid #dddddd;
  text-align: right;
}
.meainInfoTable tbody tr td:nth-of-type(2) {
  text-align: right;
  color: #ee7951;
  padding-right: 15px;
  border-bottom: 1px solid #dddddd;
}
.meainInfoTable tbody tr td span {
  color: #a9a9a9;
  float: right;
  display: inline-block;
  width: 35px;
  padding-left: 5px;
  padding-right: 5px;
}
.meainInfoTable tbody tr td label{
  display: block;
  text-align: center;
}