.searchPadding {
  padding: 15px 10px !important;
}

.none {
  height: 65vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  margin-top: 10px;
  border: 1px solid #dddddd;
  flex-direction: column;
  gap: 10px;
  font-size: 20px;
  font-weight: 500;
  color: #7a7a7a;
  white-space: pre;
  line-height: 1;
  text-align: center;
}