/* 그리드 */
.sysGrid {
  height: 100%;
}
/* 그리드 툴바 */
.sysGrid>div:first-child {
  /* border: 1px dotted yellow; */
  display: list-item;
  height: 4vh;
  margin: 0;
  padding: 0;
  background: #EEEEEE;
  color: black;
}
/* 그리드 컬럼 */
.sysGrid div table thead tr {
  /* border: 1px dotted yellow; */
  text-align: center;
  font-weight: bold;
  font-size: 1.05rem;
  color: black;
  background: #FAFAFA;
}
/* 그리드 - '데이터가 존재하지 않습니다' */
.sysGrid table tbody tr {
  /* border: 1px dotted red; */
  display: table-row;
}
.sysGrid div:nth-child(3) {
  height: 100%;
}
/* 좌 하단 DISK 하이차트 */
.sysKendoTab {
  width: 100%;
}