.bottomChartArea {
  /* height: 343px; */
  margin: 20px 10px 10px 10px;
}

.weekEnergyTitle {
  font-weight: 500;
  font-size: 15px;
  padding: 10px 0 5px 15px;
}

/* 데스크탑 일반 */
@media (min-width: 768px) {
  .diagramBox {
    width: 855px;
    /* height: 440px; */
    margin-top: 2px;
    /* background-color: black; */
  }
  .diagramImg {
    height: 420px;
  }
}
/* 모바일 */
@media (max-width: 767px) {
  .diagramBox {
    display: none;
  }
}

.infoTableBackground {
  background-color: #fafafa;
}
.marginLeft10 {
  margin-left: 10px;
}
.recentGathDtm {
  float: right;
  margin-right: 20px;
}

/* 데스크탑 일반 */
@media (min-width: 768px) {
  .latestGathInfo {
    padding: 0px 30px 0px 20px;
    /* background-color: #fafafa; */
    margin: auto auto;
    height: 420px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}
/* 모바일 */
@media (max-width: 767px) {
  .latestGathInfo {
    padding: 30px 30px 0px 20px;
    /* background-color: #fafafa; */
    margin: auto auto;
    height: 420px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}
.gathItem {
  border-spacing: 0px;
  display: flex;
  font-size: 17px;
  align-items: center;
  width: 100%;
  padding: 2px 10px;
  margin: auto 0;
}
.gathTerm {
  display: flex;
  align-items: center;
  width: 60%;
  height: 25px;
  justify-content: center;
}
.gathValue {
  flex: 1;
  border: 1px solid #dddddd;
  background-color: #fff;
  text-align: right;
  width: 40%;
  padding: 3px 5px 3px 0;
}
.gathUnit {
  color: #c5c5c5;
  padding-left: 10px;
}

/* 로딩스피너 */
.loadingArea {
  pointer-events: none;
  opacity: 0.5;
}

.installerGathLoading {
  position: absolute;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  pointer-events: none;
  bottom: 30%;
}

/* .statusViewHelp {
  padding: 5px;
  display: flex;
  justify-content: flex-end;
} */

.statusViewHelp {
  font-weight: bold;
  height: 25px;
  color: #bfbfbf;
}

.statusHelpImg {
  position: absolute;
  top: 7%;
  /* left: 10%; */
  right: 3%;
  z-index: 9999;
  width: 700px;
  visibility: hidden;
  background-color: #fff;
  color: #231f20;
  font-size: 14px;
  display: block;
  padding: 15px 15px;
  box-shadow: 2px 2px 5px #999;
}

.statusViewHelp:hover .statusHelpImg {
  visibility: visible;
}

