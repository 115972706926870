.bpmRpsSearchDropDown {
  width: 9%;
  margin-right: 1%;
}

.bpmRpsSearchBtnRight {
  float: right;
}

.bpmRpsSearchBtnRight .excelBtn {
  width: 120px;
  height: 30px;
  margin-left: -10px;
}

.bpmRpsFloatRight {
  background-image: linear-gradient(to right, #f6f6f6, #ffffff);
  /* background: #428bca; */
  /* color: white; */
  border-radius: 10px;
  display : flex;
  justify-content: center;
  align-items : center;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
  float: right;
  text-align: center;
  vertical-align: middle;
  margin-right: 0.5%;
  font-size: 16px;
  font-weight: bold;
  padding: 5px 15px;
  cursor: pointer;
}
.bpmRpsCapa td{
  padding-right: 10px;
}
.bpmRpsCapa table{
  width: 100%;
}

.bpmRpsGrid {box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
  height: calc(100vh - 320px);
}

.bpmRpsGrid th {
  font-weight: bold;
  font-size: 15px;
  text-align: center;
}

.bpmRpsGrid table tbody tr td {
  border-bottom: 1px solid gainsboro;
}

.loadingState {
  opacity: 0.6;
  pointer-events: none;
}

.bpmRpsBtn {
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  border-radius: 5px;
  width: 7.3%;
  height: 100%;
  margin-right: -0.05%;
  margin-bottom: -75px;
  text-align: center;
  font-size: 17px;
  font-weight: bold;
}