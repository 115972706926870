

.edCmpDiv{
    width: 100%;
    height: 80vh;
}
.edCmpSrchDiv{
    margin-bottom: 1%;
    width: 100%;
    height: 65px;
    background-color: white;
    border: 1px solid gainsboro;
    border-radius: 5px;
    vertical-align: middle;
    display: table;
}
.edCmpContent{
    width: 100%;
    height: 90%;
}
.edCmpChartDiv,
.edCmpGridDiv{
    width: 100%;
    height: 40%;
    border: 1px solid gainsboro;
}
.edCmpChartHeader,
.edCmpGridHeader{
    margin-top: 10px;
    border: 1px solid gainsboro;
    border-bottom: 0px;
    padding: 10px;
    width: calc(100% - 20px);
    vertical-align: middle;
    font-weight: bold;
    background-color: white;
}

.edCmpChartContent,
.edCmpGridContent{
    height: 100%;
    width: 100%;
    background-color: white;
}
.edCmpGrid table .k-master-row{
    cursor: pointer;
}
.edCmpGrid thead{
    font-weight: bold;
}

.edCmpSrchLeft{
    padding-left: 20px;
    position: relative;
    float: left;
    top: 50%;
    transform: translateY(-50%);
}
.edCmpSrchRight{
    padding-right: 20px;
    position: relative;
    float: right;
    top: 50%;
    transform: translateY(-50%);
}

.edCmpSrchDdl,
.edCmpDtStrt{
    margin-left: 10px;
    margin-right: 5px;
}
.edCmpSrchInp,
.edCmpDtEnd{
    margin-left: 5px;
    margin-right: 10px;
}

.edCmpGrid{
    width: 100%;
    height: 100%;
}
.edCmpGrid tr th {
    text-align: center;
}
.edCmpGrid tr td{
    border-bottom: 1px solid gainsboro !important;
}

.edCmptitleName{
    border: 1px solid gainsboro;
    border-bottom: 0px;
    background-color: white;
  padding: 10px;
  color: black;
  font-size: 0.9375rem;
  font-weight: bold;
}

.edCmpChartContent > div,
.edCmpChartContent > div >div{
    height: 100% !important;
}
.edCmpRadGrp{
    margin-right: 10px;
}
.edCmpRadGrp .k-radio-label{
    margin-left: 10px;
    margin-right: 10px;
}