.uk-gridExpctConsmSeach {
  margin-top: 10px;
  margin-bottom: 10px;
  height: 50px;
  padding-left: 10px;
  padding-top: 20px;
  background-color: #F0F2F3;
}

.expctConsmExcelBtn {
  margin-top: -10px;
  background-color: white;
}

.expctConsmContent{
  height: 39%;
}


.expctConsmContent .highcharts-xaxis-labels text:nth-last-child(1),
.expctBuildContent .highcharts-xaxis-labels text:nth-last-child(1) {
  font-weight: bold;
  color: #f44630 !important;
  fill: #f44630 !important;
}
.expctConsmContent .highcharts-xaxis-labels text:nth-last-child(2),
.expctBuildContent .highcharts-xaxis-labels text:nth-last-child(2) {
  font-weight: bold;
  color: #4dc9e7 !important;
  fill: #4dc9e7 !important;
}
.expctConsmBackground{
  margin-top: 15px;
  margin-bottom: 5px;
}
/*

.expctConsmBackground .highcharts-xaxis-labels text:last-child {
  font-weight: bold; 
  fill: #f44630 ;
}

.expctConsmBackground .highcharts-series rect:nth-last-child(2) {
  fill: #4dc9e7;
}

.expctConsmBackground .highcharts-series rect:last-child {
  fill: #f44630;
} */

.expctConsmGrid table thead tr th {
  text-align: center;
}

.expctConsmGrid table tbody tr :nth-of-type(5){
  text-align: right;
}

.expctConsmGrid table tbody tr :nth-of-type(6){
  text-align: right;
}

.expctConsmGrid table tbody tr :nth-of-type(7){
  text-align: right;
}

.expctConsmGrid table tbody tr :nth-of-type(8){
  text-align: right;
}

.expctConsmGrid table tbody tr :nth-of-type(9){
  text-align: right;
}

.expctConsmGrid table tbody tr :nth-of-type(10){
  text-align: right;
}

.expctConsmGrid table tbody tr :nth-of-type(11){
  text-align: right;
}

.expctConsmGrid table tbody tr :nth-of-type(12){
  text-align: right;
}
