/* 임계치 설정 바디 */
.sysServerBody {
  border: 1px solid #e3e3e3;
  background: white;
  height: 87.5vh;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}
/* 임계치 설정 상단 그리드 */
.sysServerHighGrid {
  /* border: 1px dotted blue; */
  height: 47.5%;
  width: 97.5%;
}
/* 임계치 설정 하단 그리드 */
.sysServerLowGrid {
  /* border: 1px dotted green; */
  height: 47.5%;
  width: 97.5%;
}
/* 수정, 저장, 취소 버튼 div */
.sysServerCellBtn {
  display: flex;
  justify-content: center;
}
/* 수정, 저장, 취소 버튼 */
.sysServerCellBtn button {
  background: white;
  margin: 5.5px 0 0;
}