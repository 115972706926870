.IconBtn{
    border: 1px solid #dddddd;
    border-radius: 4px;
    padding: 5px 10px;
    text-align: center;
    text-shadow: none;
    text-transform: uppercase;
    -webkit-transition: all 280ms ease;
    transition: all 280ms ease;
    color: #333;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    cursor: pointer;
    -webkit-appearance: none;
    display: inline-block;
    background-color: #fff;
    margin-right: 7px;
    vertical-align: -2px;
}

.IconBtn:hover {
    color: #0d47a1;
    text-decoration: none;
}
