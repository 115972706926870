.areaEnergyGrid {
  border-spacing: 0px;
  width: 100%;
  border: 1px solid #dddddd;
  height: calc(100vh - 470px);
}
.areaEnergyGrid .k-grid-header .k-header > .k-link {
  font-size: 16px;
}
.areaEnergyGrid .k-grid-header .k-header {
  vertical-align: middle;
}

.areaEnergyGrid thead tr th {
  font-weight: 600;
  text-align: center;
  padding: 2px 0 2px 0;
  color: #3c9ac9;
  background-color: #fafafa;
  border-right: 1px solid #dddddd;
}

.areaEnergyGrid table tbody tr td {
  line-height: 23px;
  border-bottom: 1px solid gainsboro !important;
  cursor: pointer;
}

.areaEnergyGrid thead tr:nth-child(2) th {
  font-size: 13px;
  font-weight: 600;
  text-align: center;
  padding: 2px 0 2px 0;
  color: #333;
  background-color: #fafafa;
  border-top: 1px solid #dddddd;
  border-bottom: 1px solid #dddddd;
}
.areaEnergyGrid thead tr:nth-child(1) th:nth-child(2) > .k-link,
.areaEnergyGrid thead tr:nth-child(1) th:nth-child(5) > .k-link,
.areaEnergyGrid thead tr:nth-child(1) th:nth-child(6) > .k-link {
  color: #A17145;
}
.areaEnergyGrid thead tr:nth-child(1) th:nth-child(3) > .k-link,
.areaEnergyGrid thead tr:nth-child(1) th:nth-child(4) > .k-link{
  color: #4B8EA6;
}
.areaEnergyGrid thead tr:nth-child(1) th:nth-child(7) > .k-link{
  color:#9C5AA1;
}
.areaEnergyGrid thead tr:nth-child(2) th > .k-link {
  font-size: 13px !important;
  color: #333 !important;
}

.areaEnergyGrid tbody td {
  font-size: 13px;
  font-family: "Noto Sans KR", sans-serif;
  font-weight: 400;
  color: #333;
  padding: 5px 0;
  text-align: center;
}
.areaEnergyGrid tbody td {
  border-right: 1px solid #dddddd;
  border-bottom: 1px solid #dddddd;
}
.areaEnergyGrid thead tr th:nth-of-type(1) {
  border-right: 1px solid #dddddd;
  border-left: 1px solid #dddddd;
  border-bottom: 1px solid #dddddd;
}
.areaEnergyGrid thead tr td:nth-of-type(3) {
  border-right: 1px solid #dddddd;
}
.areaEnergyGrid thead tr td:nth-of-type(6) {
  border-right: 1px solid #dddddd;
}
.areaEnergyGrid thead tr td:nth-of-type(9) {
  border-right: 1px solid #dddddd;
}
.areaEnergyGrid thead tr td:nth-of-type(12) {
  border-right: 1px solid #dddddd;
}
.areaEnergyGrid thead tr td:nth-of-type(15) {
  border-right: 1px solid #dddddd;
}
.areaEnergyGrid thead tr td:nth-of-type(18) {
  border-right: 1px solid #dddddd;
}
.areaEnergyGrid thead tr td:nth-of-type(22) {
  border-right: 1px solid #dddddd;
}
/* .areaEnergyGrid tbody tr td:nth-of-type(1) {
  border-left: 1px solid #dddddd;
} */

.areaEnergyGrid tfoot tr td:nth-of-type(1) {
  border-right: 1px solid #dddddd;
  /* border-left: 1px solid #dddddd;
  border-bottom: 1px solid #dddddd; */
}

.k-widget .k-grid .areaEnergyGrid{height: calc(100vh - 470px);}
.areaEnergyGrid table thead tr {
  line-height: 24px;
}

/* .k-grid tbody, .k-grid tfoot, .weathCmp .k-grid thead{line-height: 23px;}
 */

 
/* PC버전 css */
@media (min-width: 768px) {
  .areaEnergyGrid tfoot tr td:nth-of-type(1) {
    border-right: 1px solid #dddddd;
    /* border-left: 1px solid #dddddd;
  border-bottom: 1px solid #dddddd; */
  }

  .areaEnergyGrid thead tr:nth-child(1) th:nth-child(n + 2),
  .areaEnergyGrid thead tr:nth-child(2) th:nth-child(n + 1):nth-child(-n + 3),
  .areaEnergyGrid thead tr:nth-child(1) th:nth-child(n + 5):nth-child(-n + 6),
  .areaEnergyGrid thead tr:nth-child(2) th:nth-child(n + 10):nth-child(-n + 15) {
    background-color: #d9ecf1;
  }

  .areaEnergyGrid thead tr:nth-child(1) th:nth-child(n + 3):nth-child(-n + 4),
  .areaEnergyGrid thead tr:nth-child(2) th:nth-child(n + 4):nth-child(-n + 9) {
    background-color: #f2e8dc;
  }

  .areaEnergyGrid thead tr:nth-child(1) th:nth-child(n + 7),
  .areaEnergyGrid thead tr:nth-child(2) th:nth-child(n + 16):nth-child(-n + 19) {
    background-color: #e3ecd5;
  }

  .areaEnergyGrid tbody tr:nth-child(2n) td:nth-child(n + 2):nth-child(-n + 4),
  .areaEnergyGrid tbody tr:nth-child(2n) td:nth-child(n + 11):nth-child(-n + 16) {
    background-color: #f6fbfd;
  }
  
  .areaEnergyGrid thead tr:nth-child(2n) td:nth-child(n + 12):nth-child(-n + 14),
  .areaEnergyGrid tbody tr:nth-child(2n) td:nth-child(n + 5):nth-child(-n + 10) {
    background-color: #fdfaf7;
  }

  .areaEnergyGrid thead tr:nth-child(2n) td:nth-child(n + 15):nth-child(-n + 20),
  .areaEnergyGrid tbody tr:nth-child(2n) td:nth-child(n + 17):nth-child(-n + 20) {
    background-color: #f9fbf5;
  }

  /* areaEnergyGrid의 행 hover 시 색상 진하게 */
  .areaEnergyGrid tbody tr:nth-child(2n):hover td:nth-child(n + 2):nth-child(-n + 4),
  .areaEnergyGrid tbody tr:nth-child(2n):hover td:nth-child(n + 11):nth-child(-n + 16){
    background-color: #d5eef7;
  }
  
  .areaEnergyGrid thead tr:nth-child(2n):hover td:nth-child(n + 12):nth-child(-n + 14),
  .areaEnergyGrid tbody tr:nth-child(2n):hover td:nth-child(n + 5):nth-child(-n + 10) {
    background-color: #fff3e7;
  }

  .areaEnergyGrid thead tr:nth-child(2n):hover td:nth-child(n + 15):nth-child(-n + 20),
  .areaEnergyGrid tbody tr:nth-child(2n):hover td:nth-child(n + 17):nth-child(-n + 20) {
    background-color: #f1ffd7;
  }

  /* .areaEnergyGrid tr:nth-child(even):hover td{
    opacity: 1.3;
  } */

}

/* 모바일버전 css */
@media (max-width: 767px) {
  .genarationGrid thead tr th:nth-child(1),
  .genarationGrid thead tr th:nth-child(2),
  .genarationGrid thead tr th:nth-child(3) {
    background-color: #d9ecf1;
  }

  .genarationGrid tbody tr:nth-child(2n) {
    background-color: #f6fbfd;
  }

  
  .productionGrid thead tr th:nth-child(1),
  .productionGrid thead tr th:nth-child(2),
  .productionGrid thead tr th:nth-child(3) {
    background-color: #f2e8dc;
  }

  .productionGrid tbody tr:nth-child(2n) {
    background-color: #fdfaf7;
  }

  
  .essGrid thead tr th:nth-child(1),
  .essGrid thead tr th:nth-child(2),
  .essGrid thead tr th:nth-child(3),
  .essGrid thead tr th:nth-child(4) {
    background-color: #e3ecd5;
  }

  .essGrid tbody tr:nth-child(2n) {
    background-color: #f9fbf5;
  }
}


.cnt-header-15001,
.cnt-header-15004,
.cnt-header-15006 {
  background-color: #d9ecf1;
  color: #A17145;
}

.cnt-header-15002,
.cnt-header-15003 {
  background-color: #f2e8dc;
  color: #4B8EA6;
}
.cnt-header-15007 {
  background-color: #e3ecd5;
  color: #9C5AA1;
}
