/* container */
.content {
  height: 100%;
  width: 100%;
  display: flex;
  flex-basis: auto;
  justify-content: space-between;
}

.conLeft {
  width: 19%;
  padding: 15px;
}

.conCenter {
  width: 56%;
  margin: 30px;
  text-align: center;
}

.conCenter svg {
  width: 100%;
}

.conRight {
  /* background-color: white; */
  background: linear-gradient(90deg, #e4e4e4, hsla(0, 0%, 100%, .521));
  border-radius: 10px;
  width: 25%;
  padding: 10px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.renewHeader {
  text-shadow: 1px 2px 1px rgba(0, 0, 0, 0.3);
  position: absolute;
  left: 120px;
  /* top : 350px; */
  font-size: 30px;
  font-weight: 700;
  letter-spacing: 1px;
  line-height: 40px;
  height: 100px;
}

.renewHeader:before {
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  background-color: #01579b;
  content: '';
  display: block;
  height: 3px;
  width: 110px;
  margin-bottom: 5px;
}

.renewHeader:after {
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  background-color: #01579b;
  content: '';
  display: block;
  position: absolute;
  right: 0;
  bottom: 0;
  height: 3px;
  width: 120px;
}




/* renewMap */
.renewMap {}

.renewTable table:hover {
  -moz-transition: all 0.2s ease-in;
  -o-transition: all 0.2s ease-in;
  -webkit-transition: all 0.2s ease-in;
  transition: all 0.2s ease-in;
  cursor: pointer;
  transform: scale(1.1);
}

.renewTable table:active {
  cursor: pointer;
  transform: scale(1);
}

.renewTable {
  position: absolute;
}

.renewTable table {
  border-collapse: collapse;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
}

.renewTable th {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  padding: 5px;
  border-top: none;
  background: #428bca;
  color: #fff;
}

.renewTable td {
  text-align: center;
  font-weight: bold;
  padding: 1px 5px;
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  background-color: #f5f9fc;
  font-size: 11px;
}

.renewTable td:nth-child(1) {
  border-right: 1px solid #ddd;
}

.renewTable td svg {
  font-size: 13px;
}




#percentInfoDiv {
  position: absolute;
  top: 900px;
  left: 150px;
  border: 1px solid gainsboro;
  padding: 10px 40px;
  border-radius: 5px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}



/* detail */

.h1 {
  text-shadow: 1px 2px 1px rgb(0 0 0 / 30%);
  position: relative;
  padding: 5px;
  margin: 0;
  font-family: "Raleway", sans-serif;
  font-weight: bold;
  font-size: 20px;
  color: #080808;
  -webkit-transition: all 0.4s ease 0s;
  -o-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
}

.h1 span {
  display: block;
  font-size: 0.5em;
  line-height: 1.3;
}

.h1 em {
  font-style: normal;
  font-weight: 600;
}

.h1Div .h1 {
  text-align: center;
  text-transform: uppercase;
  /* padding-bottom: 5px; */
}

.h1Div .h1:before {
  width: 28px;
  height: 5px;
  display: block;
  content: "";
  position: absolute;
  bottom: 3px;
  left: 50%;
  margin-left: -14px;
  background-color: #01579b;
}

.h1Div .h1:after {
  width: 100px;
  height: 1px;
  display: block;
  content: "";
  position: relative;
  margin-top: 10px;
  left: 50%;
  margin-left: -50px;
  background-color: #01579b;
}

.renewDivHead {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  /* color: gray; */
  background: linear-gradient(90deg, #ffffff, #f6f6f6);
  text-align: center;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
  border-bottom: 1px solid gainsboro;
  margin-top: 10px;
  padding: 5px;
  font-size: 15px;
  font-weight: bold;
  background-color: white;
}

.renewDiv {
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
}

.tgDiv {
  position: absolute;
  width: 350px;
  top: 470px;
  left: 130px;
}

.tg {
  cursor: pointer;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
  margin-left: auto;
  margin-right: auto;
  border-collapse: collapse;
  height: 24%;
  width: 90%;
  margin-bottom: 1%;
}

.tg svg {
  font-size: 36px;
  fill: white;
}

.secTrfirTd {
  border-top-left-radius: 5px;
}

.tg tr:nth-child(1) td:nth-child(1) {
  text-align: center;
  width: 30%;
}

.tg tr:nth-child(1) td:nth-child(2) {
  border-top-right-radius: 5px;
  text-align: center;
  font-size: 30px;
  font-weight: bold;
  padding: 30px 3px;
  background-color: white;
  width: 70%;
}

.tg tr:nth-child(2) td {
  border-bottom-right-radius: 5px;
  text-align: center;
  font-size: 15px;
  padding: 10px;
  width: 70%;
  background-color: white;
}

.firTd {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  background-color: #FFAD01;
  color: white;
  font-weight: bold;
}

.secTd {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  background-color: #7AB700;
  color: white;
  font-weight: bold;
}

.thrTd {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  background-color: #FE4A59;
  color: white;
  font-weight: bold;
}

.fourTd {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  background-color: #0B92CC;
  color: white;
  font-weight: bold;
}


.sun {
  left: 220px;
  top: 145px;
  position: absolute;
  width: 50px;
  height: 50px;
  background-color: #FFDE00;
  border-radius: 50%;
  box-shadow:
    0 0 0 20px #FFDE0080,
    0 0 0 40px #FFDE0040,
    0 0 0 60px #FFDE0020,
    0 0 0 80px #FFDE0010,
    0 0 0 100px #FFDE0000,
    0 0 40px 100px #FFDE0010;
  animation:
    sunrise 1s infinite linear forwards,
    rays 1s 1s infinite linear;
}

@keyframes sunrise {
  0% {
    box-shadow: none;
  }
}

@keyframes rays {
  0% {
    box-shadow:
      0 0 0 0 #FFDE0080,
      0 0 0 20px #FFDE0080,
      0 0 0 40px #FFDE0040,
      0 0 0 60px #FFDE0020,
      0 0 0 80px #FFDE0010,
      0 0 40px 100px #FFDE0010;
  }

  100% {
    box-shadow:
      0 0 0 20px #FFDE0080,
      0 0 0 40px #FFDE0040,
      0 0 0 60px #FFDE0020,
      0 0 0 80px #FFDE0010,
      0 0 0 100px #FFDE0000,
      0 0 40px 100px #FFDE0010;
  }
}

.renewStatTbl {
  display: none;
  width: 300px;
  border-spacing: 0;
  position: absolute;
  text-align: center;
  font-weight: bold;
  border: 1px solid gainsboro;
  background-color: white;
  padding: 25px 15px;
  border-radius: 11px;
  box-shadow: rgb(0 0 0 / 15%) 1.95px 1.95px 2.6px;
}

.municDepthTbl {
  display: none;
  animation-name: bounce-6;
  animation-timing-function: ease;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-delay: 1s;

  border-spacing: 0;
  position: absolute;
  text-align: center;
  width: 200px;
  font-weight: bold;
  border: 1px solid gainsboro;
  background-color: white;
  padding: 25px 15px;
  border-left: 8px solid #428bca;
  border-radius: 11px;
  box-shadow: rgb(0 0 0 / 15%) 1.95px 1.95px 2.6px;
}

@keyframes bounce-6 {
  0% {
    transform: scale(1, 1) translateY(0);
  }

  10% {
    transform: scale(1.1, .9) translateY(0);
  }

  30% {
    transform: scale(.9, 1.1) translateY(-50px);
  }

  50% {
    transform: scale(1.05, .95) translateY(0);
  }

  57% {
    transform: scale(1, 1) translateY(-3px);
  }

  64% {
    transform: scale(1, 1) translateY(0);
  }

  100% {
    transform: scale(1, 1) translateY(0);
  }
}

.renewMapPath {
  fill: #96C1E2;
  -moz-transition: all 0.1s ease-in;
  -o-transition: all 0.1s ease-in;
  -webkit-transition: all 0.1s ease-in;
  transition: all 0.1s ease-in;
  -webkit-filter: drop-shadow(0px 1px 1px rgba(0, 0, 0, .7));
  filter: drop-shadow(0px 1px 1px rgba(0, 0, 0, .7));
  cursor: pointer;
}

.renewMapPath:hover {
  -webkit-filter: drop-shadow(2px 1px 2px rgba(0, 0, 0, .7));
  filter: drop-shadow(2px 1px 2px rgba(0, 0, 0, .7));
}

.renewMapPath:active {
  -webkit-filter: drop-shadow(1px 1px 1px rgba(0, 0, 0, .7));
  filter: drop-shadow(1px 1px 1px rgba(0, 0, 0, .7));
}

.conCenter tspan {
  fill: #000;
  text-shadow: -0.5px 0 #fff, 0 1px #fff, 1px 0 #fff, 0 -1px #fff;
  font-weight: 500;
  pointer-events: none;
}


.operChtTitle {
  position: absolute;
  z-index: 99;
  padding: 10px;
  font-weight: 500;
  font-size: 12px;
}

.renewBox {
  background-color: white;
  padding: 15px;
  border-collapse: collapse;
}

.detailEnsoTitleBox {
  background: linear-gradient(180deg, #ffffff, #f6f6f6);
  border-radius: 5px;
  border-spacing: 0px 10px;
  border: 1px solid #e4e4e4;
  text-align: center;
  vertical-align: middle;
  height: 180px;
  padding: 0px 20px;
  /* height: 160px; */
  width: 125px;
  font-size: 20px;
}

.detailEnsoTitleBox tr td{
  vertical-align: middle;
}

.detailMeainCntBox {
  background: #7DB724;
  color: #ffffff;
}

.detailMeainCapaBox {
  background: #51B8E9;
  color: #ffffff;
}

.detailElecEnergyBox {
  background: #FCAB2A;
  color: #ffffff;
}

.detailHeatEnergyBox {
  background: #F86A7D;
  color: #ffffff;
}

.detailEnsoBox {
  border-spacing: 0 5px;
  width: 250px;
}

.detailEnsoBox tr th {
  font-size: 17px;
  border-right: #ffffff 1px solid;
  width: 100px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.detailEnsoBox tr td:nth-of-type(1) {
  font-size: 22px;
  width: 100px;
  font-weight: bold;
  text-align: center;
  padding: 18px 0px;
}

.detailEnsoBox tr td:last-child {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  padding-right: 10px;
  text-align: center;
}

/* 엑셀다운로드 로딩 스피너 */
.loading {
  position: relative;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  /* height: 80%; */
  bottom: 350px;
  pointer-events: none;
}

.loadingArea {
  pointer-events: none;
  opacity: 0.5;
}

.control {
  display: flex;
  justify-content: space-between;
}

.control button {
  border: 1px solid #c1c1c1;
  background-color: #fff;
  border-radius: 5px;
}

.control button:not(:disabled) {
  cursor: pointer;
}

.control button:not(:disabled):hover {
  background-color: #f1f1f1;
}

.control button:not(:disabled):active {
  background-color: #e6e6e6;
}

.control button>img {
  width: 20px;
  height: 20px;
}