/* 상단 카테고리 날짜검색 드롭다운 */
.consmCateDateDropDown {
  width: 100px;
}
/* 상단 카테고리 검색 버튼 */
.consmCateSubmitBtn {
  margin-left: 10px;
  background: white;
  border-radius: 5px;
  padding: 7.5px 12.5px;
}
/* 하단 엑셀 다운로드 버튼 */
.consmExcelBtn {
  border: 1px solid #dddddd;
  border-radius: 5px;
  background: #ffffff;
  color: #333333;
  margin: 3px 7px 0 0;
  padding: 5px 7px;
  height: 32px;
  weight: 90px;
  font-size: 13px;
}

.consmExcelBtn img {
  padding-right: 4px;
  width: 16px;
}

.searchUserArea{
  padding: 5px;
}

.searchUserTermInput{
  width: 50%;
  height: 26px;
  margin-bottom: 5px;
}

.searchBtnUser{
  position: absolute;
  width: 40px;
  height: 30px;
  border: solid 1px black;
  border-left: none;
  background: #fff;
  text-align: center;
  cursor: pointer;
  font-size: 14px;
  margin-left: -1%;
}

.searchImg{
  font-weight: normal;
  font-style: normal;
  font-size: 18px;
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  margin-top: 16%;
}

.searchUserTermInput{
  width: 25%;
  margin-left: 5px;
}

.userInputTermArea{
  margin-left: 15px;
  width: 50px;
}

.searchBtn{
  /* margin-left: 185%; */
  position: absolute;
  width: 40px;
  height: 28px;
  border: solid 1px #e3e3e3;
  border-left: none;
  background: #fff;
  text-align: center;
  cursor: pointer;
  font-size: 14px;
  margin-top: -15px;
  /* margin-left: 62px; */
}

.SetSearchTerm {
  border: solid 1px #e3e3e3;
  border-right: none;
  /* height: 21px; */
  padding: 4px;
  outline: none;
  width: 200px;
}

.SetSearch {
  display: inline-block;
  vertical-align: middle;
  /* width: 50px; */
  text-align: right;
}

.weatherSearchA {
  padding-left: 3%;
  padding-right: 0.5%;
}
.weatherSearchB {
  padding-left: 1%;
  padding-right: 0.5%;
}

.searchConsm {
  margin-left: 3px;
  margin-right: 3px;
  width: 130px;
}

.consmExcelBtnClass {
  margin-top: -7px;
  margin-left: 5px;
  margin-right: 5px;
  border: 1px solid #d4cece;
  color: #333;
  background-color: white;
}

.consmExcelBtnClass:hover {
  border: 1px solid #1e88e5;
  /* color: #0082ff; */
  /* background-color: #0c0c0c; */
}
