.regiLorasGrid div table thead tr th input {
  /* border: 1px dotted red; */
  border-radius: 5px;
}

.regiLoraGrid table thead tr th{
  text-align: center;
}

.regiLoraLoading {
  position: relative;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 60vh;
  /* bottom: 350px; */
  pointer-events: none; 
  /* z-index: -1; */
}

.regiLoraG {
  pointer-events: none; 
  padding-top: 24px;
  width: 100%;
  position: absolute;
  opacity: 0.7;
}

/* 엑셀다운로드 로딩 스피너 */
.saveLoading{
  position: relative;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 25%;
  pointer-events: none;
}

.uploadResult {
  margin-bottom: 20px;
}