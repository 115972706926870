.chMap{
  margin-top:85px; 
  padding-left: 40px; 
  box-sizing: border-box;
}

.chMapTbB {
  /* margin-left: 1015px;
  margin-top: 140px; */
  width: 180px;
  position: absolute;
  z-index: 10;
  background-color: #fafafa;
  border: 1px solid #dddddd;
  padding: 10px;
  margin-top: 180px; 
  margin-left: 975px;
}