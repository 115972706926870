.errorPageConfirmBtn{
  background: #cecece;
  border: 1px solid #dddddd;
  border-radius: 5px;
  padding: 4px 15px;
  font-weight: bold;
  text-align: center;
  text-shadow: none;
  text-transform: uppercase;
  transition: all 280ms ease;
  box-sizing: border-box;
  cursor: pointer;
  -webkit-appearance: none;
  display: inline-block;
  width: 200px;
  height: 70px;
  font-size: 20px;
  margin-top: 80px;
}