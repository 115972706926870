
/* 모바일 */
@media (max-width: 767px) {
  .gridContents {
    width: 100vw;
    /* border: ridge gray; */
    border-width: 0.01em;
    text-align: left;
    padding-left: 1%;
    padding-top: 2%;
    /* margin-right: 5%; */
    background-color: white;
    overflow: auto;
    white-space: normal;
    text-overflow: clip;
    word-break: break-all;
  }
  
  .gridContents .title {
    font-size: 1.1rem;
    font-weight: bold;
    text-align: center;
  }
  .gridContents .dtm {
    text-align: right;
    font-weight: bold;
    padding-right: 10px;
    color: #1785fb;
  }
  .gridContents .ctnt {
    color: black;
    padding: 5%;
  }
  .fileList tbody tr td:nth-child(4),
  .fileList tbody tr td:nth-child(5) {
    display: block !important;
  }
}

/* PC */
@media (min-width: 767px) {
  .gridContents {
    border: ridge gray;
    border-width: 0.01em;
    text-align: left;
    padding-left: 1%;
    padding-top: 2%;
    /* margin-right: 5%; */
    background-color: white;
    overflow: auto;
    white-space: normal;
    text-overflow: clip;
    word-break: break-all;
  }
  
  .gridContents .title {
    font-size: 1.5rem;
    font-weight: bold;
    text-align: center;
  }
  .gridContents .dtm {
    font-size: 1.2rem;
    text-align: right;
    font-weight: bold;
    padding: 10px;
    color: #1785fb;
  }
  .gridContents .ctnt {
    font-size: 1.1rem;
    color: black;
    padding-bottom: 5%;
  }
  .gridContents .fileList {
    background-color: whitesmoke;
    border: 0.0625rem solid #d8d8d8;
    color: #5d5d5d;
    font-size: 0.8125rem;
    width: 50%;
    margin-left: 0.625rem;
  }
  .fileListDiv {
    text-align: center;
  }
}

.gridContents .downBtn {
  color: black;
  border-color: #ababab;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 2px;
  padding: 4px 8px;
  box-sizing: border-box;
  border-width: 1px;
  border-style: solid;
  font-size: 14px;
  line-height: 1.42857;
  font-family: inherit;
  text-align: center;
  text-decoration: none;
  white-space: nowrap;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  vertical-align: middle;
  -webkit-user-select: none;
  user-select: none;
  cursor: pointer;
  outline: none;
  -webkit-appearance: none;
  position: relative;
}

.gridContents .downBtn:hover {
  border: 1px solid #1e88e5;
}