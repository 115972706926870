/* 이벤트 이력 그리드 */
.eventLogGrid {
  /* border: 1px dotted red; */
  height: 100%;
  border: 1px solid #ddd; 
}
/* 그리드 상단 컬럼 */
.eventLogGrid table thead tr th {
  /* border: 1px dotted red; */
  /* border-top: 1px solid #e3e3e3; */
  text-align: center;
  font-size: 14px;
  font-weight: bold;
  color: #333;
}
/* 그리드 필터 */
.eventLogGrid table thead tr:nth-child(2) {
  /* border: 1px dotted red; */
  /* background: white; */
}
.eventLogGrid table thead tr input {
  /* border: 1px dotted red; */
  border-radius: 5px;
}
/* 그리드 필터 연산자버튼 */
.eventLogGrid table thead tr th div div div {
  /* border: 1px dotted red; */
  display: none;
}
/* 그리드 - '데이터가 존재하지 않습니다' */
.eventLogGrid table tbody tr {
  /* border: 1px dotted red; */
  display: table-row;
}

/* 모바일 */
/* 이벤트 이력 그리드 */
.mobileEventLogGrid {
  border: 0.5px solid gainsboro;
  height: 100%;
}
/* 그리드 상단 컬럼 */
.mobileEventLogGrid table thead tr th {
  /* border: 1px dotted red; */
  border-top: 1px solid #e3e3e3;
  text-align: center;
  font-size: 14px;
  padding: 5px 0px 5px 0px;
  font-weight: bold;
  color: #444444;
}

.mobileEventLogGrid table tbody tr td {
  font-size: 13px;
  padding: 5px 0px 5px 0px;
}
/* 그리드 필터 */
.mobileEventLogGrid table thead tr:nth-child(2) {
  /* border: 1px dotted red; */
  background: white;
}
.mobileEventLogGrid table thead tr input {
  /* border: 1px dotted red; */
  border-radius: 5px;
}
/* 그리드 필터 연산자버튼 */
.mobileEventLogGrid table thead tr th div div div {
  /* border: 1px dotted red; */
  display: none;
}
/* 그리드 - '데이터가 존재하지 않습니다' */
.mobileEventLogGrid table tbody tr {
  /* border: 1px dotted red; */
  display: table-row;
}
/* 카테고리 etc */
.eventLogCateFacility {
  /* border: 1px dotted red; */
  width: 77.5%;
}
/* 이벤트 상세 내역 */
.eventStatDialog div:nth-child(2) {
  /* border: 1px solid black; */
  padding: 0;
}

.eventLoading {
  position: absolute;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 90%;
  bottom: 35%;
  pointer-events: none;
}

.eventG {
  pointer-events: none;
  opacity: 0.7;
}
