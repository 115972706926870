.userBetweenUserRankGrid table thead tr th {
  text-align: center;
  vertical-align: middle !important;
  font-size: 12px;
  border: 1px solid rgba(0, 0, 0, 0.08);
}

.userBetweenUserRankGrid table tbody tr :nth-of-type(3) {
  text-align: right;
}
.userBetweenUserRankGrid table tbody tr :nth-of-type(4) {
  text-align: right;
}
.userBetweenUserRankGrid table tbody tr :nth-of-type(5) {
  text-align: right;
}
.userBetweenUserRankGrid table tbody tr :nth-of-type(6) {
  text-align: right;
}
.userBetweenUserRankGrid table tbody tr :nth-of-type(7) {
  text-align: right;
}

.userBetweenUserMakerGrid table thead tr th {
  text-align: center;
  vertical-align: middle !important;
  font-size: 12px;
  border: 1px solid rgba(0, 0, 0, 0.08);
}

.userBetweenUserMakerGrid table tbody tr :nth-of-type(3) {
  text-align: right;
}
.userBetweenUserMakerGrid table tbody tr :nth-of-type(4) {
  text-align: right;
}
.userBetweenUserMakerGrid table tbody tr :nth-of-type(5) {
  text-align: right;
}
.userBetweenUserMakerGrid table tbody tr :nth-of-type(6) {
  text-align: right;
}
.userBetweenUserMakerGrid table tbody tr :nth-of-type(7) {
  text-align: right;
}

.userBetweenUserExcelBtnClass {
  border-color: #e4e4e4;
  color: #333;
  background-color: #fff;
}
