.gathStatusExcelBtn{
  background-color: #fff;
  margin-left: 5px;
}

.dataGathGrid thead tr th{
  text-align: center;
  vertical-align: middle !important;
  border:1px solid #dddddd;
}

.dataGathGrid tbody tr :nth-of-type(2){
  text-align: right;
}

.dataGathGrid tbody tr :nth-of-type(3){
  text-align: right;
}

.dataGathGrid tbody tr :nth-of-type(4){
  text-align: right;
}

.dataGathGrid tbody tr :nth-of-type(5){
  text-align: right;
}