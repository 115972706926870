div.reportDayBackground {
  padding: 10px 0px 0px 0px;
  font-size: 15px;
  font-weight: bold;
}

.reportEnergyTable tbody tr th,
.reportEnergyTable tbody tr td{
  width:50%;
}

table.reportEnergyTable {
  padding: 10px;
  border-collapse: collapse;
  text-align: right;
  line-height: 1.5;
}
table.reportEnergyTable thead th {
  text-align: center;
  padding: 10px;
  font-size: 18px;
  font-weight: bold;
  vertical-align: top;
  color: #369;
  border-bottom: 2px solid #036;
}

table.reportEnergyTable tbody th {
  width: 2000px;
  padding: 5px;
  font-size: 14px;
  text-align: left;
  border-bottom: 0px solid rgb(255, 255, 255);
}

table.reportEnergyTable tbody td {
  width: 2000px;
  height: 30px;
  font-weight: bold;
  padding: 1px;
  vertical-align: middle;
  background: #e6e9eb;
  /* background: #f0f2f3; */
  background-clip: content-box;
}
table.reportEnergyTable tbody {
  border-bottom: 2px solid #036;
}
table.compareEnergyTable {
  border-collapse: collapse;
  margin-bottom: 20px;
}
table.compareEnergyTable thead th {
  padding: 8px 12px;
  width: 2000px;
  overflow: hidden;
  text-overflow: ellipsis;
  background: #f0f2f3;
  border-top: 1px solid #dddddd;
  border-bottom: 1px solid #dddddd;
}

table.compareEnergyTable tbody td {
  width: 2000px;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: right;
  border-bottom: 1px solid #dddddd;
  background: white;
  padding: 7px;
}

/* 프린트 테이블  */

table.printTable {
  margin-left: auto;
  width: 30%;
  padding: 10px;
  border-collapse: collapse;
  text-align: right;
  line-height: 1.5;
}
table.printTable thead th {
  padding: 10px;
  font-size: 18px;
  font-weight: bold;
  vertical-align: top;
  color: #369;
  border-bottom: 2px solid #036;
}

table.printTable tbody th {
  text-align: center;
  padding: 5px;
  font-size: 14px;
  color: rgb(0, 0, 0);
  text-align: left;
  border-bottom: 0px solid rgb(255, 255, 255);
}

table.printTable tbody td {
  text-align: center;
  height: 30px;
  font-weight: bold;
  padding: 1px;
  vertical-align: middle;
  background: #f0f2f3;
  background-clip: content-box;
}
table.printTable tbody {
  border-bottom: 2px solid #036;
}

.reportGrid{
  border:1px solid gainsboro;
}
.reportGrid table tbody tr {
  display: table-row;
}

/* 그리드 헤더 컬럼 부분 */
.reportGrid table {
  padding: 0px;
}

/* 그리드 헤더 컬럼 부분 */
.reportGrid thead tr:nth-child(1) {
  background-color: #f0f2f3;
  color: black;
  text-align: center;
  font-weight: bold;
}
/* 그리드 body */
.reportGrid tbody {
  text-align: right;
}

/* 그리드 body 첫번째 열*/
.reportGrid tbody td:nth-child(1) {
  text-align: center;
  font-weight: bold;
}

/* 그리드 body 첫번째 열*/
.reportGrid tbody td {
  padding: 6px;
}
/* 그리드 body 첫번째 열*/
.reportGrid thead th {
  padding: 15px;
}

.reportGrid table tbody tr td {
  text-align: right;
}

.reportDayBtn {
  border-radius: 2px;
  margin-left: 3px;
  padding-right: 20px;
  padding-left: 20px;
  background-color: white;
}
.reportPrintGrid table tbody tr{
  display: table-row;
}
/* 여기부터 SREMS 추가사항 */
.reportPrintGrid {
  border: 1px solid #434343;
}
.reportPrintGrid .k-grid-header .k-grid-header-wrap table thead tr th {
  border-bottom: 1px solid #434343;
  text-align: center;
  font-size: 13px;
  padding: 1px;
  /* margin: 1px; */
}
.reportPrintGrid div div div table tbody tr{
  line-height: 14px;
}
.reportPrintGrid div div div table tbody tr td {
  padding: 1px;
  font-size: 10px;
  border-bottom: 1px solid gainsboro;
}

.reportPrintGrid .k-grid-header .k-grid-header-wrap table thead tr th:nth-child(1),
.reportPrintGrid div div div table tbody tr td:nth-child(1){
  width:18%;
}
.reportPrintGrid .k-grid-header .k-grid-header-wrap table thead tr th:nth-child(4),
.reportPrintGrid div div div table tbody tr td:nth-child(4){
  width:20%;
}
.printCompareEnergyTable {
  margin-bottom:10px;
  width: 100%;
  margin-left: auto;
  border-collapse: collapse;
  text-align: right;
}
.printCompareEnergyTable thead tr th{
  padding: 3px;
  width: 24%;
  text-align: center;
  font-size: 13px;
  overflow: hidden;
  text-overflow: ellipsis;
  background: #ececec;
  border: 1px solid #424242;
}
.printCompareEnergyTable tbody tr td {
  padding: 3px;
  width: 24%;
  border: 1px solid #434343;
  text-align: center;
  font-size: 10px;
}
.printCompareEnergyTable thead tr th:nth-child(1),
.printCompareEnergyTable tbody tr td:nth-child(1){
  width: 12%
}
.printCompareEnergyTable thead tr th:nth-child(4),
.printCompareEnergyTable tbody tr td:nth-child(4){
  width: 12%
}

.reportPrintSecondGrid table tbody tr{
  display: table-row;
}
.reportPrintSecondGrid {
  border: 1px solid #434343;
}
.reportPrintSecondGrid .k-grid-header .k-grid-header-wrap table thead tr th {
  border-bottom: 1px solid #434343;
  text-align: center;
  font-size: 13px;
  padding: 3px;
  margin: 3px;
}
.reportPrintSecondGrid div div div table tbody tr td {
  border-bottom: 1px solid gainsboro;
  padding: 1px;
  font-size: 12px;
}

.reportPrintSecondGrid .k-grid-header .k-grid-header-wrap table thead tr th:nth-child(1),
.reportPrintSecondGrid div div div table tbody tr td:nth-child(1){
  width:18%;
}
.reportPrintSecondGrid .k-grid-header .k-grid-header-wrap table thead tr th:nth-child(4),
.reportPrintSecondGrid div div div table tbody tr td:nth-child(4){
  width:20%;
}


.reportDayPrintTableDiv {
  width: 98%;
}

.reportDayPrintTableDiv table {
  border: 1px solid #333;
  float: left;
  width: 33%;
}

.reportDayPrintTableDiv thead th {
  border-bottom: 1px solid #333;
}
.reportDayPrintTableDiv table tbody tr th {
  font-size: 13px;
}
.reportDayPrintTableDiv table tbody tr td {
  background-color: #ececec;
  text-align: right;
  font-size: 12px;
}

.reportDayPrintHeading{
  padding: 10px 0px 0px 0px;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
}

.reportDayPrintSubheading{
  padding: 10px 0px 0px 0px;
  font-size: 14px;
  font-weight: bold;
  text-align: center;
}

.reportDayHighChartDiv{
  border: 1px solid gainsboro;
}


/**/
.printChart .highcharts-background {
  fill: white;
}
.printChart {
  border: 1px solid #333;
}
.reportConsmPrintTableDiv {
  width: 100%;
}

.reportConsmPrintTableDiv table {
  border: 1px solid #333;
  float: left;
  width: 33%;
}

.reportConsmPrintTableDiv thead th {
  border-bottom: 1px solid #333;
}
.reportConsmPrintTableDiv table tbody tr th {
  font-size: 13px;
}
.reportConsmPrintTableDiv table tbody tr td {
  background-color: #555555;
  text-align: right;
  font-size: 12px;
}

.reportConsmPrintSecondTableDiv table {
  border: 1px solid #333;
  float: left;
  width: 99%;
  margin-top: 10px;
}

.reportConsmPrintSecondTableDiv tbody th {
  border-bottom: 1px solid #333;
  border-right: 1px solid #333;
}
.reportConsmPrintSecondTableDiv table tbody tr td {
  border-right: 1px solid #333;
  background-color: #555555;
  text-align: right;
}
.reportConsmPrintSecondTableDiv tbody th:nth-child(7),
.reportConsmPrintSecondTableDiv table tbody tr td:nth-child(7){
  border-right: none;
}

.page {
  width: 210mm; /* A4의 너비와 높이 지정 */
  height: 297mm;
  margin: 10mm auto;
  margin-top: 0mm;
}
@page {
  size: A4;
  margin: 10;
  margin-top: 0;
}

.reportConsmPrintGrid {
  border: 1px solid #434343;
}
.reportConsmPrintGrid .k-grid-header{
  border-bottom: 1px solid #434343;
}
.reportConsmPrintGrid .k-grid-header .k-grid-header-wrap table thead tr th {
  /* border-bottom: 2px solid #434343; */
  text-align: center;
  font-size: 15px;
  padding: 1px;
  /* margin: 1px; */
}
.reportConsmPrintGrid div div div table tbody tr{
  line-height: 16px;
}
.reportConsmPrintGrid div div div table tbody tr td {
  padding: 1px;
  font-size: 12px;
}

.reportConsmPrintHeading{
  text-align: center;
  padding-top: 70px;
  padding-bottom: 10px;
  font-size: 25px;
  font-weight: bold;
}

.reportConsmPrintSubheading{
  padding-top: 90px;
  padding-bottom: 40px;
  font-size: 20px;
  font-weight: bold;
}
