.CustomFilterInput{
    width : 100%;
}

.CustomFilterInputStatus{
    width : 100%;
}

.CustomFilterDropDown{
    /* justify-content: center; */
}
.CustomFilterDropDown > span{
    width: 90%;
}