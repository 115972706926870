
.Ch-TableN{
    width: 100%;
}

.Ch-TableN tr th {
    font-size: 10px;
    text-align: right;
    padding: 5px 8px 7px 0;
    width: 12%;
    font-weight: 500;
}

.Ch-TableN tr td {
    text-align: left;
    padding: 5px 15px 7px 0;
}

/* 수용가 리스트 그리드 스타일 */
.installerList table tbody tr td{
  text-align: center;
}

.installerList table tbody tr th{
  text-align: center;
}

.installerList table thead tr th{
  text-align: center;
}

.installerList table thead tr th{
  text-align: center;
}

.installerList .k-grid-norecords{
  width: 200px;
}

.installerList ul {
  display: none !important;
}

.meainExcelUpload{
  /* top: 50% !important;
  left: 50% !important; */
  /* width: 600px;
  height: 350px; */
}

.meainListGrid table thead tr th{
  text-align: center;
}

.meainListGrid table thead tr th .CustomFilterInputStatus{
  width: 120px;
}

.excelControlBtn{
  margin: 5px;
}

/* 드롭다운 포커스 마우스 오버 CSS */

.meainInfoDropDown:hover{
  border: #000000 0.25px solid;
  border-radius: 5px;
}

.meainInfoDropDown .k-state-focused
{
  border: #000000 0.25px solid !important;  
}

/* 데이트 픽커 포커스 마우스 오버 CSS */

.meainDatePicker:hover{
  border: #000000 0.25px solid;
  border-radius: 5px;
}

.meainDatePicker .k-state-focused{
  border: #000000 0.25px solid !important;
}

.meainDatePicker input{
  color:black !important;
}
.meainBtn {
  border-radius: 4px;
  padding: 3px 10px;
  text-align: center;
  text-shadow: none;
  text-transform: uppercase;
  -webkit-transition: all 280ms ease;
  transition: all 280ms ease;
  color: #333;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  cursor: pointer;
  -webkit-appearance: none;
  display: inline-block;
  background-color: #fff;
  margin-top: -5px;
  margin-left: 5px;
}
.meainBtn img {
  padding-right: 4px;
  width: 16px;
}