.text-label{
    font-weight: bold;
}
/* iotkt 부모 그리드 */
 .loraDiv {
  border: 1px solid #dddddd;
  /* display: flex; */
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-around;
  align-items: center;
  height: 86vh;
  width: 93.5vw;
  background-color: white;
}

.regiLoraGrid {
  margin-top: 40px;
  margin-left: 20px;
}

.regiLoraColumnGrid {
  width: 110%;
}

.regiLoraButton {
  margin-right: 7px;
}
/* iotkt 좌측 그리드 */
/* .loraLeft {
  border: 1px solid #dddddd;
  width: 97%;
  height: 97.5%;
  background-color: white;
}

/* iotkt 우측 그리드 */
/* .loraRight {
  border: 1px solid #dddddd;
  width: 99%;
  height: 97.5%;
  background-color: white;
} */