.userInfoArea{
  margin-right: 3%;
  padding: 3px 10px 0 10px
}

.searchBtn{
  /* margin-left: 185%; */
  position: absolute;
  width: 40px;
  height: 28px;
  border: solid 1px #e3e3e3;
  border-left: none;
  background-color: #e3e3e3;
  text-align: center;
  cursor: pointer;
  font-size: 14px;
  margin-top: -15px;
  /* margin-left: 62px; */
}

.searchBtn:hover{
  border: solid 1px #e3e3e3;
  background-color: white;
  color: #ffffff;
}

.searchImg{
  font-weight: normal;
  font-style: normal;
  font-size: 18px;
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  margin-top: 16%;
  color: #ffffff;
}

.SetSearchTerm {
  border: solid 1px #e3e3e3;
  border-right: none;
  /* height: 21px; */
  padding: 4px;
  outline: none;
  width: 200px;
}

.SetSearch {
  display: inline-block;
  vertical-align: middle;
  /* width: 50px; */
  text-align: right;
}

.searchBtnUser{
  position: absolute;
  width: 40px;
  height: 30px;
  border: solid 1px black;
  border-left: none;
  background: #fff;
  text-align: center;
  cursor: pointer;
  font-size: 14px;
  margin-left: -1%;
}

.searchUserArea{
  padding: 5px;
}

.searchUserTermInput{
  width: 50%;
  height: 26px;
  margin-bottom: 5px;
}


/* 데이터가 없을 시  */
.noDataCommnet{
  margin-top: 10px;
}

/* 라디오 버튼 */
.radioForm{
  margin-left: 3%;
}

/* 타이틀 이름 */
.titleName{
  margin: 1.5625rem 0 0.625rem 1.3125rem;
  color: black;
  font-size: 0.9375rem;
  font-weight: bold;
}

  /* 발전량 분포 (지역별) */
  .graphBox{
    height: calc(50% - 3.1875rem);
  }
  
  .searchUserTermInput{
    width: 25%;
    margin-left: 5px;
  }
  
  .userInputTermArea{
    margin-left: 15px;
  }

/* 발전량 분포 (지역별) */
.graphBox{
  height: calc(50% - 3.1875rem);
}

.searchUserTermInput{
  width: 25%;
  margin-left: 5px;
}

.userInputTermArea{
  margin-left: 15px;
}

.inqBtn{
  margin-left: 5px;
  background-color: #0082ff;
  color: #ffffff;
}

.inqBtn:hover{
  background-color: #0056aa;
  color: #ffffff;
}

.searchConsm{
  margin-left: 3px;
  margin-right: 3px;
  width: 130px;
}
