.writeBtn {
  color: black;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  background: #fff;
  border: 1px solid #dddddd;
  border-radius: 5px;
  padding: 4px 15px;
  font-weight: bold;
  text-align: center;
  text-shadow: none;
  text-transform: uppercase;
  -webkit-transition: all 280ms ease;
  transition: all 280ms ease;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  cursor: pointer;
  -webkit-appearance: none;
  display: inline-block;
}

.searchPadding {
  padding: 15px 10px !important;
}

.nonOperHistExcelBtn {
  border: 1px solid #dddddd;
  border-radius: 4px;
  padding: 3px 10px;
  text-align: center;
  text-shadow: none;
  text-transform: uppercase;
  -webkit-transition: all 280ms ease;
  transition: all 280ms ease;
  color: #333;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  cursor: pointer;
  -webkit-appearance: none;
  display: inline-block;
  background-color: #fff;
  margin-top: -4px;
  
  
}

.nonOperHistExcelBtn img {
  padding-right: 4px;
  width: 16px;
}


.nonOperHistSearchBtn {
  width: 60px;
  background: #fff;
  border: 1px solid #dddddd;
  border-radius: 5px;
  padding: 4px 15px;
  font-weight: bold;
  text-align: center;
  text-shadow: none;
  text-transform: uppercase;
  -webkit-transition: all 280ms ease;
  transition: all 280ms ease;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  cursor: pointer;
  -webkit-appearance: none;
  display: inline-block;
}
.nonOperHistSearchBtn:hover,
.nonOperHistSearchBtn:focus,
.nonOperHistSearchBtn:active {
  background: #fff;
  outline: none;
  text-decoration: none;
  color: #212121;
  -webkit-box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16),
    0 3px 6px rgba(0, 0, 0, 0.23);
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.etcBtn {
  margin: 0px 5px;
  background: #fff;
  top: 10px;
  left: 330px;
  border-radius: 2px;
  padding: 4px 8px;
  box-sizing: border-box;
  border-width: 1px;
  border-style: solid;
  font-size: 14px;
  line-height: 1.42857;
  font-family: inherit;
  text-align: center;
  text-decoration: none;
  white-space: nowrap;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  vertical-align: middle;
  user-select: none;
  cursor: pointer;
  outline: none;
  -webkit-appearance: none;
  position: relative;
}