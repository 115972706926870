.uk-gridExpctBuildBsmanSeach {
  margin-top: 10px;
  margin-bottom: 10px;
  height: 50px;
  padding-left: 10px;
  padding-top: 20px;
  background-color: #F0F2F3;
}
.expctBuildGrid{
  height: 100%;
  border: 1px solid gainsboro;
}
.expctBuildGrid table thead tr th{
  text-align: center;
}

.expctBuildGrid table tbody tr :nth-of-type(2){
  text-align: right;
}

.expctBuildGrid table tbody tr :nth-of-type(3){
  text-align: right;
}

.expctBuildGrid table tbody tr :nth-of-type(4){
  text-align: right;
}

.expctBuildGrid table tbody tr :nth-of-type(5){
  text-align: right;
}

.expctBuildGrid table tbody tr :nth-of-type(6){
  text-align: right;
}

.expctBuildGrid table tbody tr :nth-of-type(7){
  text-align: right;
}

.expctBuildGrid table tbody tr :nth-of-type(8){
  text-align: right;
}

.expctBuildContent{
  height: 40%;
}