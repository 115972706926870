.operationLoading {
  position: relative;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 94%;
  height: 60vh;
  pointer-events: none; 
  /* z-index: -1; */
}

.operationG {
  pointer-events: none; 
  padding-top: 24px;
  width: 94%;
  position: absolute;
  opacity: 0.7;
}

.operationStatTable{
  width: 90%;
}

.chTableE{
  padding-top: 1px;
  border-spacing: 0px;
  width: 100%;
}       
/* .chTableE tr th{
  padding: 19px 0 20px 15px;
} */