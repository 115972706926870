/* 데스크탑 일반 */
@media (min-width: 768px) {
  .MobilePaddingDiv {
    display: none;
  }
  .installerGathDdlDiv {
    float: right;
    padding-right: 10px;
  }

  .latestEventList {
    border-spacing: 0px;
    width: 100%;
  }
  .latestEventList thead tr th {
    color: #333;
    text-align: center;
    padding: 10px;
    font-size: 15px;
    /* background-color: #5a5a5a;
    border-bottom: 1px solid #4d4d4d;
    border-right: 1px solid #4d4d4d; */
  }

  .latestEventList tbody tr th {
    text-align: right;
    font-weight: normal;
    font-size: 14px;
    padding: 10px;
    /* background-color: #333;
    border-bottom: 1px solid #4d4d4d;
    border-right: 1px solid #4d4d4d; */
  }
  .latestEventList tbody tr td {
    text-align: center;
    font-size: 14px;
    padding: 6px;
    /* background-color: #333;
    border-bottom: 1px solid #4d4d4d;
    border-right: 1px solid #4d4d4d; */
  }
  .latestEventList .k-virtual-content {
    /* background-color: #333; */
  }

  /* .latestEventList tbody tr td:nth-of-type(5) {
      text-align: left;
    } */
  .latestEventList tbody tr:nth-of-type(even) {
    background: #fafafa;
  }

  .latestEventList .k-grid-header {
    /* border-bottom: #333; */
  }

  .latestEventList .k-grid-norecords {
    display: contents;
  }
  .mobilePaddingDiv {
    padding-top: 30px;
  }

  .installerGath-infoTable {
    color: white;
    border-spacing: 0px;
    width: 100%;
    margin-top: 2px;
  }
  .installerGath-infoTable tr th {
    width: 30%;
    text-align: left;
    color: #333;
    padding: 10px 0 10px 20px;
    border-left: 1px solid #dddddd;
    border-bottom: 1px solid #dddddd;
  }
  .installerGath-infoTable tr:nth-child(2) th {
    padding: 15px 0 20px 20px;
    border-left: 1px solid #dddddd;
    border-bottom: 1px solid #dddddd;
  }

  .installerGath-infoTable tr th small {
    padding: 0px 10px 0px 0px;
  }
  .installerGath-infoTable tr th b {
    font-size: 40px;
  }
  .installerGath-infoTable tr th span {
    color: #c5c5c5;
    padding-left: 10px;
    font-size: 25px;
  }
  .installerGath-infoTable tr td {
    width: 20%;
    text-align: center;
  }

  .installerGath-infoTable small {
    font-size: 93%;
  }
}

/* 모바일 */
@media (max-width: 767px) {
  .MobilePaddingDiv {
    padding-top: 30px;
  }
  .installerGathDdlDiv {
    margin: 10px 0px 0px 0px;
  }
  .installerGathDdl {
    width: 45%;
  }
  .latestEventList {
    border-spacing: 0px;
    width: 100%;
  }
  .latestEventList thead tr th {
    color: #333;
    text-align: center;
    padding: 10px 0px 10px 0px;
    font-size: 10px;
    /* background-color: #5a5a5a;
    border-bottom: 1px solid #4d4d4d;
    border-right: 1px solid #4d4d4d; */
    overflow: visible !important;
  }
  .latestEventList tbody tr td:nth-child(3) {
    overflow: visible !important;
    padding: 0px;
  }
  .latestEventList tbody tr td {
    text-align: center;
    font-size: 12px;
    padding: 3px;
    /* background-color: #333;
    border-bottom: 1px solid #4d4d4d;
    border-right: 1px solid #4d4d4d; */
  }
  .latestEventList .k-virtual-content {
    /* background-color: #333; */
  }

  /* .latestEventList tbody tr td:nth-of-type(5) {
    text-align: left;
  } */
  .latestEventList tbody tr:nth-of-type(even) {
    background: #fafafa;
  }

  .latestEventList .k-grid-header {
    /* border-bottom: #333; */
  }

  .latestEventList .k-grid-norecords {
    display: contents;
  }
  .latestEventList thead tr th:nth-child(3),
  .latestEventList tbody tr td:nth-child(3) {
    width: 70px;
  }
  .mobilePaddingDiv {
    padding: 40px 0px 10px 0px;
  }
  .installerGath-infoTable {
    color: white;
    border-spacing: 0px;
    width: 100%;
    margin-top: 2px;
  }
  .installerGath-infoTable tr th {
    width: 30%;
    text-align: left;
    color: #333;
    padding: 14px 0 13px 15px;
  }
  .installerGath-infoTable tr th small {
    padding: 0px 10px 0px 0px;
  }
  .installerGath-infoTable tr th b {
    font-size: 25px;
  }
  .installerGath-infoTable tr th span {
    color: #c5c5c5;
    padding-left: 10px;
    font-size: 17px;
  }
  .installerGath-infoTable tr td {
    width: 20%;
    text-align: center;
  }
}

.installerGath-gathInfoBox {
  padding: 0px 30px 0px 20px;
  border-left: 1px solid #dddddd;
  background-color: #fafafa;
  margin: auto auto;
  height: 441px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.row {
  border-spacing: 0px;
  display: flex;
  font-size: 17px;
  align-items: center;
  width: 100%;
  padding: 2px 10px;
}
.fr {
  display: flex;
  align-items: center;
  width: 40%;
  height: 25px;
}
.se {
  flex: 1;
  border: 1px solid #dddddd;
  background-color: #fff;
  text-align: right;
  width: 60%;
  padding: 3px 5px 3px 0;
}
.th {
  color: #c5c5c5;
  padding-left: 10px;
}
.installerGath-gathInfoTable tr {
  /* flex: 1; */
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 0;
  margin: auto 0;
  display: flex;
  flex-direction: row;
}
.installerGath-gathInfoTable tr th {
  font-size: 17px;
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 0;
  display: flex;
  align-items: center;
}
.installerGath-gathInfoTable tr td {
  flex: 1;
}
.installerGath-gathInfoTable tr th div {
  height: 16px;
}
.installerGath-gathInfoTable tr th:nth-of-type(1) {
  border-left: 1px solid #dddddd;
  border-top: 1px solid #dddddd;
  border-bottom: 1px solid #dddddd;
  background-color: #fff;
  text-align: right;
  font-size: 17px;
  width: 40%;
  padding: 5px 5px 5px 0;
  flex: 1;
  width: 100px;
}
.installerGath-gathInfoTable tr td:nth-of-type(2) {
  border-right: 1px solid #dddddd;
  border-top: 1px solid #dddddd;
  border-bottom: 1px solid #dddddd;
  background-color: #fff;
  text-align: left;
  padding: 5px;
}
.installerGathTBgA {
  background-color: #ececec;
}

.weekEnergyChartNoneData {
  height: 115px;
  text-align: center;
  padding-top: 50px;
}

.widthHalfSpan{
  width : 50%;
  float:left;
}