/* 배치프로세스이력 바디 */
.batchProcHistBody {
  /* border: 1px dotted red; */
  height: 87.5vh;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}
/* 배치프로세스이력 상단 카테고리 */
.batchProcHistCate {
  border: 1px solid #e3e3e3;
  height: 7.5%;
  width: 97.5%;
  display: flex;
  align-items: center;
  font-weight: bold;
  color: #444444;
}
/* 배치프로세스이력 상단 카테고리 각종 div */
.batchProcHistCate div {
  /* border: 1px dotted red; */
  margin: 0 10px 0 0;
}
/* 배치프로세스이력 상단 카테고리 첫번째 div */
.batchProcHistCate div:first-child {
  /* border: 1px dotted red; */
  margin: 0 10px;
}
/* 배치프로세스이력 상단 카테고리 버튼 */
.batchProcHistCate div button {
  /* border: 1px dotted red; */
  margin: 0 5px 0 0;
  background: white;
}
/* 배치프로세스이력 배치 재수행 모달 창 */
.batchProcHistModalDiv {
  display: flex;
  align-items: center;
  font-weight: bold;
  height: 50px;
  width: 600px;
}
/* 배치프로세스이력 배치 재수행 모달 창 내부 div들 */
.batchProcHistModalDiv div {
  margin: 0 10px 0 0;
}
/* 배치프로세스이력 배치 재수행 모달 창 재수행 버튼 */
.batchProcHistModalDiv div button:first-child {
  margin: 0 10px 0 0;
  background: #39f;
  color: white;
}
/* 배치프로세스이력 배치 재수행 모달 창 재수행 외 버튼 */
.batchProcHistModalDiv div button {
  background: white;
}
/* 배치프로세스이력 하단 그리드 */
.batchProcHistGrid {
  /* border: 1px dotted red; */
  height: 85%;
  width: 97.5%;
}