.searchPadding {
  padding: 15px 10px !important;
}

.bpmRpsLoading {
  position: absolute;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 90%;
  pointer-events: none;
  bottom: 45%;
  /* z-index: -1; */
}

.bpmRpsStatTbl{
  display:none;
  width: 300px;
  border-spacing: 0;
  position: absolute;
  text-align: center;
  font-weight: bold;
  border: 1px solid gainsboro;
  background-color: white;
  padding: 25px 15px;
  border-radius: 11px;
  box-shadow: rgb(0 0 0 / 15%) 1.95px 1.95px 2.6px;
}