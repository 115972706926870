
.pageArea{
  margin-top: -48px;
  padding: 15px 15px 15px 15px;
}

.titleArea{
  background-color: #FBFBFB;
  height: 250px;
}

.titleTable{
  width: 100%;
  height: 100%;
  text-align: center;
}


.titleTable tr :nth-of-type(2){
  text-align: left;
}

.titleText{
  font-size: 50px;
}

.contentsArea{
  background-color: #ffffff;
  height: 550px;
  text-align: center;
  margin-top: 100px;
}

.contentsTextArea{
  display: inline-block;
  text-align: left;
}

.contentsText{
  font-size: 24px;
  margin-top: 15px;
  margin-bottom: 15px;
}