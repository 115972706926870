.searchUserByUserBtn {
  margin-left: 5px;
}
.cmpBtnGrp{
  background-color: white;
}
.cmpBtnGrp .k-button-group{
  display: contents !important;
}
.meainItemBtn {
  margin: 3px !important;
}
.userByUserMeainListGrid {
  border: 0.5px solid gainsboro;
}
.userByUserMeainListGrid table thead tr th {
  text-align: center;
  font-weight: bold;
}

.userByUserMeainListGrid table tbody tr td {
  text-align: center;
}

.userByUserMeainListGrid table tbody tr :nth-of-type(7) {
  text-align: right;
}

.userByUserMeainListGrid table tbody tr :nth-of-type(8) {
  text-align: right;
}

.userByUserMeainListGrid table tbody tr :nth-of-type(9) {
  text-align: right;
}

.userByUserMeainListGrid table tbody tr {
  display: table-row;
}

.userInfoWindowGrid ul {
  display: none !important;
}
.userByUserTimeDataListGrid {
  border: 0.5px solid gainsboro;
}
.userByUserTimeDataListGrid table thead tr th {
  text-align: center;
}

.userByUserTimeDataListGrid table tbody tr :nth-of-type(2) {
  text-align: right;
}

.userByUserTimeDataListGrid table tbody tr :nth-of-type(3) {
  text-align: right;
}

.userByUserTimeDataListGrid table tbody tr :nth-of-type(4) {
  text-align: right;
}

.userByUserTimeDataListGrid table tbody tr :nth-of-type(5) {
  text-align: right;
}

.userByUserTimeDataListGrid table tbody tr {
  display: table-row;
}
/* 모바일 그리드 css */
.mobileUserByUserTimeDataListGrid {
  border: 0.5px solid gainsboro;
}
.mobileUserByUserTimeDataListGrid table thead tr th {
  font-size: 13px;
  font-weight: bold;
  padding: 5px 0px 5px 0px;
  text-align: center;
}
.mobileUserByUserTimeDataListGrid table tbody tr td {
  padding: 5px;
}
.mobileUserByUserTimeDataListGrid table tbody tr :nth-of-type(2) {
  text-align: right;
}

.mobileUserByUserTimeDataListGrid table tbody tr :nth-of-type(3) {
  text-align: right;
}

.mobileUserByUserTimeDataListGrid table tbody tr :nth-of-type(4) {
  text-align: right;
}

.mobileUserByUserTimeDataListGrid table tbody tr :nth-of-type(5) {
  text-align: right;
}

.mobileUserByUserTimeDataListGrid table tbody tr {
  display: table-row;
}

.cmpUserByUser {
  pointer-events: none;
  /* padding-top: 1px; */
  width: 94.5%;
  position: absolute;
  opacity: 0.5;
}

.cmpUserByUserLoading {
  position: relative;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 60vh;
  pointer-events: none;
  /* z-index: -1; */
}

/* // 그리드 페이징 CSS */
.userInfoWindowGrid .k-pager-numbers-wrap > select {
  display: block;
  background-color: white;
}

.userInfoWindowGrid .k-pager-numbers-wrap > ul {
  display: none;
}

.userInfoWindowGrid table tbody tr {
  display: table-row;
}
.cmpUserByUserExcelDownBtnDiv {
  float: right;
}
.cmpUserByUserExcelDownBtn {
  margin-right: 5px;
  background-color: white;
}

.cmpUserByUserExcelDownBtn:hover {
  border: 1px solid #1e88e5;
}

.searchConsm {
  margin-left: 3px;
  margin-right: 3px;
  width: 130px;
}
/* 데스크탑 일반 */
@media (min-width: 768px) {
  .MobileUserByUserDiv {
    display: none;
  }
  .mobileCmpUserSpan {
    display: none;
  }
}
/* 모바일 */
@media (max-width: 767px) {
  .mobileCmpUserSpan {
    padding-left: 5%;
    font-size: 0.7rem;
    color: #1e88e5;
  }
  .pcUserByUserDiv {
    display: none;
  }
  .userByUserDivPadding {
    text-align: center;
    padding: 10px 0px 10px 10px;
  }
  .userByUserdatePicker {
    width: 45%;
    padding-left: 0px;
  }
  .userByUserDdl {
    width: 100px;
  }
  .userByUserTable {
    padding: 10px 0px 10px 0px;
  }
  .userByUserTable tr td {
    overflow: visible;
    width: 25%;
    padding: 0px;
  }
  .mobileFullBtnDiv {
    padding-top: 10px;
    text-align: center;
  }
  .mobileFullBtn {
    text-align: center;
    width: 80%;
  }
  .userByUserInput {
    width: 50%;
  }
  .userByUserSearchBtn {
    color:white;
    background-color: royalblue;
  }
  .userByUserTimeDataListGrid {
    width: 100%;
  }
}
