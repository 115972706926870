.groupInstallerGrid {
  font-size: 13px;
}
.groupInstallerGrid. k-grid-header .k-header > .k-link {
  font-size: 13px;
  color: #3c9ac9;
}
.groupInstallerGrid .k-grid-header .k-header {
  vertical-align: middle;
  text-align: center;
}
.groupInstallerGrid .k-grid-container .k-grid-table td {
  font-size: 12px;
}
.groupInstallerGrid .k-grid-container .k-grid-table td:nth-child(5) {
  text-align: left;
  padding-left: 10px;
}
