.qnaGrid {
  border-spacing: 0px;
  width: 100%;
  border: 1px solid #dddddd;
}

.qnaGrid .k-grid-norecords {
  width: 250px;
}
.qnaGrid thead tr td {
  color: #fff;
  font-size: 15px;
  border-bottom: 1px solid #dddddd;
}
.qnaGrid thead tr th {
  border-right: 1px solid #dddddd;
  /* cursor: pointer; */
}

.qnaGrid thead tr th:nth-of-type(3) {
  border-right: none;
}
.qnaGrid tbody tr th {
  color: #333;
  background-color: #eeeeee;
  font-size: 15px;
  border-top: 1px solid #dddddd;
}

.qnaGrid tbody tr:hover {
  background-color: rgb(245, 148, 148);
}
.qnaGrid tbody tr td {
  text-align: center;
  color: black;
  font-size: 0.9em;
  border-top: 1px solid #dddddd;
  text-overflow: ellipsis;
}

.qnaGrid th.k-header.gridHeader {
  text-align: center;
  font-size: 15px;
  font-weight: bold;
  color: black;
}

.qna-info iframe.k-iframe {
  width: 100%;
}

.qna-info .k-editor-content {
  border: #3b3838;
  border-width: 1px;
  border-style: solid;
}

.qna-info .k-toolbar {
  height: 50px;
}

.qna-info {
  width: 100%;
  height: 390px;
  /* border: none; */
}

.qna-info .k-window-titlebar {
  padding: 5px 8px;
}

.qna-info table {
  width: 100%;
}

.qna-info table th {
  font-size: 15px;
  width: 2%;
  text-align: left;
}

.qna-info table td {
  width: 8%;
}

.qna-info .k-button {
  float: right;
}

.qna-info .k-form input {
  width: 100%;
}

.qnaCheckBox .k-checkbox-label,
.k-radio-label {
  margin: 3px;
  min-height: 16px;
  line-height: 17px;
  vertical-align: text-top;
  display: inline-flex;
  align-items: flex-start;
  position: relative;
  cursor: pointer;
}

.qnaCheckBox .k-checkbox-label::before,
.k-radio-label::before {
  content: "";
  width: 16px;
  height: 16px;
  border: #c5c5c5;
  border-width: 1px;
  border-style: solid;
}

.k-animation-container {
  z-index: 10003;
}

.qna-grid {
  pointer-events: none; 
  /* padding-top: 1px; */
  width: 94.5%;
  position: absolute;
  opacity: 0.5;
}