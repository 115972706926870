/* 이벤트이력 전체 폼 */
.eventLogForm {
  /* border: 1px dotted red; */
  height : calc(100vh - 135px); 
  /* height: 88.5vh;
  padding: 5px 0 5px; */
}
/* 이벤트이력 상단 카테고리 */
.eventLogCate {
  /* border: 1px dotted red; */
  /* height: 15%; */
  padding: 20px 20px 10px 20px;
  color:333;
  font-size: 14px;
  border: 1px solid #ddd;
}
/* 카테고리 블록 */
.eventLogCate div {
  /* border: 1px dotted blue; */
  margin: 0 0 10px;
}
/* 카테고리별 드롭다운 */
.eventLogCate div span:nth-child(n + 2) {
  /* border: 1px dotted red; */
  margin: 0 2.5px 0 10px;
}
/* 카테고리 인풋 */
.eventLogCate div input {
  border-radius: 5px;
  width: 210px;
}
/* 수용가명 인풋 */
.eventLogCateInputOne {
  /* border: 1px dotted red; */
  margin: 0 10px 0 10px;
}
/* CID 인풋 */
.eventLogCateInputTwo {
  margin: 0 20px 0 0;
}
/* 조회 엑셀다운 버튼 */
.eventLogCate div button {
  /* border: 1px dotted red; */
  /* margin: 0 5px 0;
  background: white;
  color: #444444; */
  margin: 0 0px 0px 5px;
  background: #efefef;
  border-radius: 5px;
}
.eventLogCate div button:hover {
  border: 1px solid #0082ff;
}
@media (min-width: 767px) {
  /* 이벤트이력 그리드 */
  .eventLogBody {
    /* border: 1px dotted blue; */
    /* height: 83.5%; */
    height: calc(100vh - 280px);
    margin-top: 25px;
  }
}
/* 이벤트 상세 내역 모달 폼 */
.eventModalForm {
  /* border: 1px dotted red; */
  display: flex;
  height: 40vh;
  width: 20vw;
}
/* 이벤트 상세 내역 모달 왼쪽 폼 */
.leftModalItem {
  /* border: 1px dotted blue; */
  width: 40%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
/* 이벤트 상세 내역 모달 왼쪽 폼 간격 조정 */
.leftModalItem div {
  /* border: 1px dotted green; */
  padding: 0 1px 1px 0;
  font-weight: bold;
  font-size: 16px;
  text-align: right;
  margin: 0 0 34.5px;
  color: #444444;
}
/* 이벤트 상세 내역 모달 오른쪽 폼 */
.rightModalItem {
  /* border: 1px dotted blue; */
  width: 60%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
/* 이벤트 상세 내역 모달 오른쪽 폼 간격 조정 */
.rightModalItem form div {
  /* border: 1px dotted red; */
  margin: 27.5px 0;
}
/* 이벤트 상세 내역 모달 input 태그 */
.rightModalItem form div input {
  /* border: 1px dotted red; */
  width: 185px;
}
/* 이벤트 상세 내역 모달 dropdown 태그 */
.rightModalItem form div span {
  /* border: 1px dotted blue; */
  width: 185px;
}
/* 이벤트 상세 내역 모달 오른쪽 아래 저장, 취소 버튼 */
.rightModalItem form div button {
  /* float: right; */
  margin-left: 10px;
  background: white;
}




/* 모바일 CSS */
.mobileEventLogCate {
  border: 0.5px solid gainsboro;
  padding: 10px;
}
.mobileEventLogCateTable {
  width: 100%;
}
.mobileEventLogCateTable tr th {
  overflow: visible;
  text-overflow: clip;
  width: 20%;
}
.mobileEventLogCateTable tr td .k-dropdown,
.mobileEventLogCateTable tr td button,
.mobileEventLogCateTable tr td input {
  width: 100%;
}
.mobileEventLogDatePicker {
  width: 45%;
}

@media (max-width: 767px) {
  /* // 그리드 페이징 CSS */
  .eventLogBody .k-pager-numbers-wrap > select {
    display: block;
    color: black;
    background-color: white;
  }

  .eventLogBody .k-pager-numbers-wrap > ul {
    display: none;
  }
}

.mobileEventStatDialogDiv {
  padding: 10px;
}
.mobileEventStatDialogBtnDiv {
  margin-top: 30px;
  text-align: right;
}
.mobileEventStatDialogDiv table tr th {
  font-size: 1rem;
  padding: 30px 5px 10px 5px;
}
.mobileEventStatDialogDiv table tr td {
  font-size: 0.9rem;
  padding: 30px 5px 10px 5px;
}

.mobileEventStatDialogDiv table tr td input,
.mobileEventStatDialogDiv table tr td span {
  width: 100%;
}

.mobileEventLogbtnDiv {
  text-align: right;
}
.mobileEventLogbtnDiv button {
  margin: 5px;
}
