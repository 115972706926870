/* // 통신방식 부분 */
.tokenClass{
  display: none;
}
.lteTcpIdClass{
  display: none;
 
}
.loraDevidClass{
  display: none;

}
.nbDevidClass{
  display: none;
}

.searchTermTBC {
  font-size: 14px;
  border: 1px solid #e4e4e4;
  padding: 5px 4px;
  color: #333;
  width: 94.5%;
}

.searchBtn{
  /* margin-left: 185%; */
  position: absolute;
  width: 40px;
  height: 28px;
  border: solid 1px #e3e3e3;
  border-left: none;
  background: #fff;
  text-align: center;
  cursor: pointer;
  font-size: 14px;
  margin-top: -15px;
  /* margin-left: 62px; */
}

.searchImg{
  font-weight: normal;
  font-style: normal;
  font-size: 18px;
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  margin-top: 16%;
}

.SetSearchTerm {
  border: solid 1px #e3e3e3;
  border-right: none;
  /* height: 21px; */
  padding: 4px;
  outline: none;
  width: 107px;
}

.SetSearch {
  display: inline-block;
  vertical-align: middle;
  /* width: 50px; */
  text-align: right;
}

.searchBtnUser{
  position: absolute;
  width: 40px;
  height: 30px;
  border: solid 1px #e3e3e3;
  border-left: none;
  background: #fff;
  text-align: center;
  cursor: pointer;
  font-size: 14px;
  margin-left: -7%;
}

.searchUserArea{
  padding: 5px;
  margin-left: -20px;
}

.searchUserTermInput{
  width: 33%;
  height: 28px;
  margin-bottom: 5px;
  margin-left: 5px;
  border: solid 1px #e3e3e3;
}

.meainInfoArea{
  overflow-y: scroll;
  height: 77vh;	
}

.meainUserListGirdArea{
  pointer-events: none; 
  /* padding-top: 24px; */
  width: 90%;
  position: absolute;
  opacity: 0.5;
}

/* 그리드 로딩 스피터 */
.meainListGridAreaLoading{
  position: relative;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  /* height: 80%; */
  bottom: 350px;
  pointer-events: none;
}

.meainListGridArea{
  pointer-events: none; 
  /* padding-top: 24px; */
  width: 100%;
  position: absolute;
  opacity: 0.5;
}

/* 엑셀다운로드 로딩 스피너 */
.saveLoading{
  position: relative;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  /* height: 80%; */
  bottom: 350px;
  pointer-events: none;
}

.saveLoadingArea{
  pointer-events: none; 
  opacity: 0.5;
}

.excelControlBtnArea{
  margin-top: 5px;
}

/* INPUT BOX 포커스 마우스 오버 CSS */

.searchTermTBC:hover {
  border : 1px solid #000000;
}

.searchTermTBC:focus {
  border : 1px solid #000000;
}


/* 드롭다운 아이템 효과 */
.dropDownItem{
  font-size: 10px;
}

.dropDownItem:hover{
  color: black;
}

.dropDownItem:focus{
  color: black;
}