.userListWindow > .k-dialog-titlebar .k-window-title{
  text-align: left;
}

.meainUserListGird table thead tr th {
  text-align: center;
}

.meainUserListGird table tbody tr td {
  text-align: center;
}

.userListWindow > .k-dialog-titlebar .k-window-title{
  text-align: left;
}
