/* 시스템 모니터링 틀 */
.sysBodyDiv {
  padding: 0;
  margin: 0;
  /* border: 1px dotted red; */
  display: flex;
  flex-wrap: nowrap;
  height: 90vh;
}
/* 시스템 모니터링 틀 - 좌측 그리드 */
.sysBodyDiv .leftSysDiv {
  border: 1px solid #DDDDDD;
  width: 18.5%;
}
/* 시스템 모니터링 틀 - 좌측 그리드 - 헤더 */
.sysBodyDiv .leftSysDiv .leftSysDivHeader {
  /* border: 1px dotted pink; */
  background: #EEEEEE;
  padding: 5px 0 0 8px;
  height: 4%;
  font-weight: bold;
  font-size: 1.4rem;
  color: black;
  line-height: 25px;
}
/* 시스템 모니터링 틀 - 좌측 그리드 - 헤더 드롭다운 */
.sysBodyDiv .leftSysDiv .leftSysDivHeader>span {
  /* border: 1px dotted red; */
  width: 0px;
  margin: 0 70px 0 0;
}
/* 시스템 모니터링 틀 - 좌측 그리드 - 헤더 드롭다운 */
.sysBodyDiv .leftSysDiv .leftSysDivHeader span>span:first-child {
  /* border: 1px dotted red; */
  width: 135px;
}
/* 시스템 모니터링 틀 - 좌측 그리드 - 바디 */
.sysBodyDiv .leftSysDiv .leftSysDivBody {
  /* border: 1px solid #DDDDDD; */
  height: 95%;
}
/* 시스템 모니터링 틀 - 좌측 그리드 - 바디 내부 DIV */
.sysBodyDiv .leftSysDiv .leftSysDivBody .leftSysDivBodyChild {
  /* border: 1px dotted red; */
  display:flex;
  flex-direction: column;
  justify-content: space-between;
  height: 98.5%;
  margin: 5px;
}
/* 시스템 모니터링 틀 - 우측 그리드 */
.sysBodyDiv .rightSysDiv {
  border: 1px solid #DDDDDD;
  width: 80%;
  margin: 0 0 0 35px;
}
/* 시스템 모니터링 - 우측 그리드 - 상단 툴바 - 시스템 이벤트 이력 */
.sysBodyDiv .sysSpanTxt1 {
  margin: 0;
  padding: 4px 0 0 8px;
  font-weight: bold;
  font-size: 1.4rem;
}
/* 시스템 모니터링 - 우측 그리드 - 상단 툴바 - 날짜 검색 */
.sysBodyDiv .sysSpanTxt2 {
  flex: none;
  float: right;
  line-height: 35px;
  padding: 0 10px 0;
}
/* 좌 하단 DISK 하이차트 */
.sysTabBottom {
  border: 1px solid #DDDDDD;
}