.edLogContent{
    height: 75vh;
}
.edLogGrid{
    border: 1px solid gainsboro;
    height: 100%;
}
.edLogTabStrip,
.edLogTabStrip >div >div,
.edLogTabStrip >div >div >div,
.edLogTabStrip >div >div >div >div{
    height:100%;
}
.edLogGrid tr th{
    text-align: center;
}
.edLogGrid tr td{
    border-bottom: 1px solid gainsboro !important;
}
.edsmRetryBtn{
    background-color: #0082FF;
    color:white;
    font-weight: 500;
    margin-left:5px;
    margin-top: -5px;
  }
  .edsmRetryBtn:hover{
      font-weight: bold;
    background-color: #0082FF;
    color:white;
  }
  .edsmRetryBtn:active{
    background-color: #0082FF;
    color:white;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset;
  }

  .devEdsmBtn{
      position: fixed;
      right: 35px;
      z-index: 99;
      background-color: #0082FF;
      color:white;
      font-weight: 500;
  }

  .devEdsmBtn:hover{
    font-weight: bold;
  background-color: #0082FF;
  color:white;
}
.devEdsmBtn:active{
  background-color: #0082FF;
  color:white;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset;
}