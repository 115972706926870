

.thCmpDiv{
    width: 100%;
    height: 83vh;
}
.thCmpSrchDiv{
    margin-bottom: 1%;
    width: 100%;
    height: 65px;
    background-color: white;
    border: 1px solid gainsboro;
    border-radius: 5px;
    vertical-align: middle;
    display: table;
}
.thCmpContent{
    width: 100%;
    height: 80vh;
}

.thCmpGrid table .k-master-row{
    cursor: pointer;
}

.thCmpSrchLeft{
    padding-left: 20px;
    position: relative;
    float: left;
    top: 50%;
    transform: translateY(-50%);
}
.thCmpSrchRight{
    padding-right: 20px;
    position: relative;
    float: right;
    top: 50%;
    transform: translateY(-50%);
}

.thCmpSrchDdl,
.thCmpDtStrt{
    margin-left: 10px;
    margin-right: 5px;
}
.thCmpSrchInp,
.thCmpDtEnd{
    margin-left: 5px;
    margin-right: 10px;
}

.thCmpGrid tr th {
    text-align: center;
}
.thCmpGrid tr td{
    border-bottom: 1px solid gainsboro !important;
}

.thCmptitleName{
  margin: 10px;
  color: black;
  font-size: 0.9375rem;
  font-weight: bold;
}

.thCmpChartContent{
    border: 1px solid gainsboro;
}
.thCmpChartContent,
.thCmpChartContent > div,
.thCmpChartContent > div >div,
.thCmpChartContent > div >div > div{
    height: 100% !important;
}
.thCmpRadGrp{
    margin-right: 10px;
}
.thCmpRadGrp .k-radio-label{
    margin-left: 10px;
    margin-right: 10px;
}
.thCmpGrid thead th{    
    vertical-align: middle !important;
}
.thCmpGrid thead tr th span{
    font-weight: 500;
    cursor: text;
}

.thCmpGrid{
    height: 100%;
    border: 1px solid gainsboro;
}

.thCmpTable{
    height: 100%;
    width: 100%;
}
.thCmpTable tr th{
    padding: 10px;
}
.thCmpTable tr td{
    width: 50%;
    padding-right: 5px;
    padding-left: 5px;
    height: 100%;
}

.thCmpCtntHdr{
    width: calc(48% - 2px);
    border: 1px solid gainsboro;
    border-bottom: 0px;
    background-color: white;
    padding-top:15px;
    padding-bottom:15px;
    margin-left:1%;
    margin-right:1%;
    font-weight: bold;
    font-size: 16px;
    text-align: center;
    display: inline-block;
}
.thCmpCtntBody{
    width: calc(50% - 2px);
    padding-left:1%;
    padding-right: 1%;
    float: left;
    height: 90%;
}

.thCmpHdInfo {
    position: fixed;
    top: 200px;
    width: 150px;
    height: 40px;
    background: #484848;
    color: white;
    border-radius: 5px;
    padding: 12px 12.8px;
}

.thCmpHdInfo:after {
    border-top: 10px solid #484848;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 0px solid transparent;
    content: "";
    position: absolute;
    top: 60px;
    left: 108px;
}
.thCmpHdInfoImg{
    position: absolute;
    top:0;
    right:0;
    cursor: pointer;
}