/* IotktLog 틀 */
.iotktLogBody {
  /* border: 1px dotted red; */
  height: 86vh;
}
/* 그리드 상단 카테고리 */
.iotktLogCate {
  margin: 0 0 5px 0;
}
/* 그리드 상단 내부 span */
.iotktLogCate span {
  margin: 0 5px 0 0;
}
/* 그리드 상단 엑셀 다운로드 버튼 */
.iotktLogCate button {
  /* border: 1px dotted red; */
  border-radius: 5px;
  background: white;
}