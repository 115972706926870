/* 전체 페이지 틀 */
.consmBody {
  /* border: 1px dotted red; */
  height: 100vh;
  display: flex;
  flex-direction: column;
}
/* 상단 카테고리 틀 */
.consmCate {
  /* border: 1px dotted blue; */
  margin: 0 0 20px;
  border: 1px solid #dddddd;
  background: #fafafa;
  border-radius: 5px;
  padding: 20px 10px;
  margin: 0 0 25px;
}
/* 상단 카테고리 틀 내부 엘리먼트 */
.consmCate > span {
  /* border: 1px dotted blue; */
  margin-left: 10px;
}
/* 상단 카테고리 틀 내부 3번째 엘리먼트 */
.consmCate > span:nth-child(3) {
  /* border: 1px dotted blue; */
  margin-right: 10px;
}
/* 상단 신호 보드 */
.consmBoard {
  margin: 0 0 25px;
  /* background: #abcdef; */
  height: 15%;
  width: 100%;
  display: flex;
  justify-content: space-between;
}
/* 상단 신호 보드 내부 DIV */
.consmBoardSub {
  border: 1px solid #dddddd;
  border-radius: 5px;
  background: #fafafa;
  width: 23.5%;
  height: 70%;
  padding: 20px 0;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
/* 상단 신호 보드 내부 제목 */
.consmBoardSub div span:nth-child(1) {
  /* border: 1px dotted red; */
  font-size: 19px;
}
/* 상단 신호 보드 내부 내용 - 0 건 */
.consmBoardContent {
  /* border: 1px dotted blue; */
  display: table;
  margin-left: auto;
  margin-right: auto;
  margin-top: 5px;
}
/* 상단 신호 보드 내부 내용 - 숫자 */
.consmBoardContent b {
  /* border: 1px dotted red; */
  color: #82b440;
  font-weight: 500;
  font-size: 30px;
}
/* 상단 신호 보드 내부 내용 - 글씨(건) */
.consmBoardContent span {
  /* border: 1px dotted red; */
  color: #9090a7;
  font-size: 17px;
  padding-left: 10px;
}

.consmGrid table thead tr th {
  text-align: center;
}

.eventConsmGrid table thead tr th {
  text-align: center;
}

.eventConsmGrid table tbody tr :nth-child(7) {
  text-align: left;
}