.k-animation-container-relative{
    display: block;
}

/* 라디오 */

.radioForm{
    margin-left: 10px;
}

.radioForm > label{
    margin-top: 3px;
}

.k-radio:checked + .k-radio-label::before{
    border-color: #cecece;
    background-color: #ffffff; 
}

.k-radio:checked + .k-radio-label::after{
    background-color: #0082FF; 
}

.ensoDropDown{
    margin-left: 5px;
    margin-right: 5px;
}

.bizSctCodeNoDropDown{
    width: 230px;
}

.yearDropDown{
    width: 100px;
}

.inqBtn{
    margin-left: 5px;
    border-radius: 3px;
    width: calc(25px + 1.42857em);
    background-color: white;
}

.cmpEnsoSrchRight{
  margin-top: 6px;
  margin-right: 10px;
  float: right;
}

.ensoCmpExcelDownBtn{
  border: 1px solid #dddddd;
  border-radius: 4px;
  padding: 5px 10px;
  text-align: center;
  text-shadow: none;
  text-transform: uppercase;
  -webkit-transition: all 280ms ease;
  transition: all 280ms ease;
  color: #333;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  cursor: pointer;
  -webkit-appearance: none;
  display: inline-block;
  background-color: #fff;
  margin-right: 7px;
  margin-top : -4px;
  float: right;
}


/* 그리드 */

.ensoTimeGrid table thead tr th {
  text-align: center;
}

.ensoTimeGrid table tbody tr :nth-of-type(2){
  text-align: right;
}
.ensoTimeGrid table tbody tr :nth-of-type(3){
  text-align: right;
}
.ensoTimeGrid table tbody tr :nth-of-type(4){
  text-align: right;
}
.ensoTimeGrid table tbody tr :nth-of-type(5){
  text-align: right;
}
.ensoTimeGrid .k-grid-norecords{
  display: contents;
}


.ensoAreaGrid table thead tr th {
  text-align: center;
}

.ensoAreaGrid table tbody tr :nth-of-type(3){
  text-align: right;
}
.ensoAreaGrid table tbody tr :nth-of-type(4){
  text-align: right;
}
.ensoAreaGrid table tbody tr :nth-of-type(5){
  text-align: right;
}
.ensoAreaGrid table tbody tr :nth-of-type(6){
  text-align: right;
}
.ensoAreaGrid table tbody tr :nth-of-type(7){
  text-align: right;
}
.ensoAreaGrid table tbody tr :nth-of-type(8){
  text-align: right;
}
.ensoAreaGrid table tbody tr :nth-of-type(9){
  text-align: right;
}
.ensoAreaGrid .k-grid-norecords{
  display: contents;
}


.ensoInverterGrid table thead tr th {
  text-align: center;
}

.ensoInverterGrid table tbody tr :nth-of-type(3){
  text-align: right;
}
.ensoInverterGrid table tbody tr :nth-of-type(4){
  text-align: right;
}
.ensoInverterGrid table tbody tr :nth-of-type(5){
  text-align: right;
}
.ensoInverterGrid table tbody tr :nth-of-type(6){
  text-align: right;
}
.ensoInverterGrid table tbody tr :nth-of-type(7){
  text-align: right;
}
.ensoInverterGrid table tbody tr :nth-of-type(8){
  text-align: right;
}
.ensoInverterGrid table tbody tr :nth-of-type(9){
  text-align: right;
}
.ensoInverterGrid .k-grid-norecords{
  display: contents;
}


.ensoModuleGrid table thead tr th {
  text-align: center;
}

.ensoModuleGrid table tbody tr :nth-of-type(3){
  text-align: right;
}
.ensoModuleGrid table tbody tr :nth-of-type(4){
  text-align: right;
}
.ensoModuleGrid table tbody tr :nth-of-type(5){
  text-align: right;
}
.ensoModuleGrid table tbody tr :nth-of-type(6){
  text-align: right;
}
.ensoModuleGrid table tbody tr :nth-of-type(7){
  text-align: right;
}
.ensoModuleGrid table tbody tr :nth-of-type(8){
  text-align: right;
}
.ensoModuleGrid table tbody tr :nth-of-type(9){
  text-align: right;
}
.ensoModuleGrid .k-grid-norecords{
  display: contents;
}

.ensoSocoGrid table thead tr th {
  text-align: center;
}

.ensoSocoGrid table tbody tr :nth-of-type(3){
  text-align: right;
}
.ensoSocoGrid table tbody tr :nth-of-type(4){
  text-align: right;
}
.ensoSocoGrid table tbody tr :nth-of-type(5){
  text-align: right;
}
.ensoSocoGrid table tbody tr :nth-of-type(6){
  text-align: right;
}
.ensoSocoGrid table tbody tr :nth-of-type(7){
  text-align: right;
}
.ensoSocoGrid table tbody tr :nth-of-type(8){
  text-align: right;
}
.ensoSocoGrid table tbody tr :nth-of-type(9){
  text-align: right;
}
.ensoSocoGrid .k-grid-norecords{
  display: contents;
}

.cmpEnso {
  pointer-events: none; 
  /* padding-top: 1px; */
  width: 94.5%;
  position: absolute;
  opacity: 0.5;
}

.cmpEnsoLoading {
  position: relative;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 60vh;
  pointer-events: none; 
  /* z-index: -1; */
}