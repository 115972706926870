.ready {
  background-color: #0e65ec;
}
.normal {
  background-color: #05c270;
}
.unOper {
  background-color: #9090a7;
}
.warn {
  background-color: #f9cc13;
}
.error {
  background-color: #ff3b3b;
}
/* 데스크탑 일반 */
@media (min-width: 768px) {
  .infoClick {
    cursor: pointer;
  }
  .infoClick:hover {
    background-color: #ededed;
    color: #000000;
  }

  .infoClick:hover svg {
    color: #000000;
  }
}
/* 모바일 */
@media (max-width: 767px) {
  /* .infoClick {
    border: 1px solid red;
    color: red;
  } */
}

/* 깜빡임 효과 CSS */
@keyframes blinkEffect {
  50% {
    opacity: 0.5;
  }
}

/* 깜빡임 효과 이벤트 설정 */
.blankImg {
  animation: blinkEffect 1s step-end infinite;
  /* 
  1. animation css name;
  2. animation duration 
  3. timing
  4. iteration count  
  */
}
