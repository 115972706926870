/* 데스크탑 일반 */
@media (min-width: 768px) {
  .mobileMainTitle{
    display:none;
  }
  .header-left {
    position: fixed;
  }
  .headerMenuBtn {
    display: none;
  }
  .mobileMainHeader{
    display: none;
  }
}

/* 모바일 */
@media (max-width: 767px) {
  .pcMainHeader{
    display: none !important; 
  }
  /* .mobileMainHeader{
    height: 70px;
    position: fixed;
    display: table;
    justify-content: center;
    float:none;
  } */ 
  .mobileMainTitle{
    width: 100%;
    height: 65px !important;
    text-align: center;
    vertical-align: middle;
    color:white;
    font-size: 18px;
  }
  .mobileMainTitle tbody tr td{
    width: 70%;
  }
  .mobileMainTitle tbody tr th{
    width: 15%;
  }
  .headerGobackBtn {
    display: none;
  }
  .menuDropDown {
    display: none;
  }
  .headerFullLi {
    display: none;
  }
  .loginIconLi {
    padding-right: 15px;
  }
  .mainTilteSpan {
    padding-left: 3%;
    color: white;
    font-size: 30px;
  }
  /* .mainTilteSpanSec {
    display: none;
    padding-left: 5%;
    color: white;
    font-size: 18px;
  } */
  .headerBtn {
    width: 70px;
    height: 65px;
    cursor: pointer;
    background-color: inherit;
    border: none;
    z-index: 999999;
  }
  .headerBtn:hover,
  .headerBtn:checked,
  .headerBtn:focus,
  .headerBtn:active {
    border-color: none;
    background-color: inherit;
  }
}

.header-center {
  display: flex;
  justify-content: center;
}
.menuDropDown {
  /* background-color: #01579b; */
  color: #fff;
  padding: 3px 4px;
  cursor: pointer;
  font-size: 16px;
  margin-top: -12px;
  width: 165px;
}

.menuDropDown .k-list .k-item {
  background-color: #01579b;
}

.menuDropDown .k-list .k-item:hover {
  background-color: #01579b;
}

.menuDropDown .k-list .k-item.k-state-selected {
  background-color: #01579b;
}

.menuDropDown .k-dropdown-wrap .k-input {
  background-color: #01579b;
  color: #fff;
}
.menuDropDown .k-dropdown .k-dropdown-wrap {
  background-color: #01579b;
}

.menuDropDown .k-dropdown .k-dropdown-wrap .k-select {
  background-color: #01579b;
}
.menuDropDown .k-icon {
  color: #fff;
}

.menuDropDown .k-select {
  background-color: #01579b !important;
}

.menuDropDown .k-input {
  border-radius: 5px 0px 0px 5px !important;
}

.headerGobackBtn {
  width: 70px;
  height: 70px;
  cursor: pointer;
  background-color: inherit;
  border: none;
}

@media screen and (max-width: 1500px) {
  .mainTitle {
    display: none;
  }
}

@media screen and (max-width: 1100px) {
  .headTempData {
    display: none;
  }
}

@media screen and (max-width: 1000px) {
  .headRgnData {
    display: none;
  }
}

@media screen and (max-width: 800px) {
  .headata {
    display: none;
  }
}
