.browserKindGrid{
  border: 1px solid #cecece;
}

.browserKindGrid table thead tr th {
  text-align: center;
}

.browserKindGrid table tbody tr :nth-of-type(2){
  text-align: right;
}

