/* 수용가 모달 폼 */
.installerModalForm {
  /* border: 1px dotted red; */
  display: flex;
  height: 62.5vh;
  width: 20vw;
}
/* 수용가 모달 왼쪽 폼 */
.leftModalItem {
  /* border: 1px dotted blue; */
  width: 40%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
/* 수용가 모달 왼쪽 폼 간격 조정 */
.leftModalItem div {
  /* border: 1px dotted green; */
  padding: 0 1px 1px 0;
  font-weight: bold;
  font-size: 16px;
  text-align: right;
  margin: 0 0 34.5px;
  color: #444444;
}
/* 수용가 모달 오른쪽 폼 */
.rightModalItem {
  /* border: 1px dotted blue; */
  width: 60%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
/* 수용가 모달 오른쪽 폼 간격 조정 */
.rightModalItem form div {
  /* border: 1px dotted red; */
  margin: 27.5px 0;
}
/* 수용가 모달 input 태그 */
.rightModalItem form div input {
  /* border: 1px dotted red; */
  width: 185px;
}
/* 수용가 모달 dropdown 태그 */
.rightModalItem form div span {
  /* border: 1px dotted blue; */
  width: 185px;
}
/* 수용가 모달 오른쪽 아래 저장, 취소 버튼 */
.rightModalItem form div button {
  float: right;
  margin-left: 10px;
  background: white;
}
.custNoTable{
  width: 100%;
}
.custNoTable tr th{
  width: 80%;
}
.custNoTable tr th input{
  width: 100%;
}
.custNoTable tr td{
  width: 20%;
}


.installerModalTable{
  width: 100%;
  table-layout: fixed;
}
.installerModalTable tbody tr th{
  vertical-align: top;
  font-size: 15px;
  width: 35%;
  padding: 10px;
  padding-left: 2%;
  text-align: left;
}
.installerModalTable tbody tr td{
  width: 65%;
}
.installerModalTable tbody tr td input{
  width: 100%;
}
.installerModalTable > tbody > tr > td > span{
  width: 100% !important;
}

.kepcoChkBtn{
  background-color: #0082FF;
  color:white;
  font-weight: 500;
  margin-left:5px;
  margin-top: -5px;
}
.kepcoChkBtn:hover{
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  background-color: #0082FF;
  color:white;
}
.kepcoChkBtn:active{
  background-color: #0082FF;
  color:white;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset;
}
.installerModalSubmitBtnArea{
  margin-top: 15px;
  float: right;
}
.installerModalSubmitBtnArea button{
  margin: 5px;
  background-color: white;
  font-weight: 500;
}
.kepcoInfoWin {
  position: fixed;
  font-weight: 500;
  color:white;
  background-color: #484848;
  margin-top:10px;
	border-radius: .4em;
  padding:10px;
  z-index: 1;
}

.kepcoInfoWin:after {
	content: '';
	position: absolute;
	top: 0;
	left: 50%;
	width: 0;
	height: 0;
	border: 20px solid transparent;
	border-bottom-color: #484848;
	border-top: 0;
	border-left: 0;
	margin-left: -10px;
	margin-top: -20px;
}