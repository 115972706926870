.k-group-footer {
  display: none;
}

.k-grouping-row {
  /* display: inline; */
}

.nonOperHistGrid th {
    font-size: 12px;
    text-align: center;
}

.nonOperHistGrid td {
    font-size: 12px;
    text-align: center;
}
