/* 그리드 */
.managerGrid {
  height: 100%;
}
/* 그리드 상단 컬럼 */
.managerGrid table thead tr th {
  /* border: 1px dotted red; */
  text-align: center;
  font-size: 15px;
  font-weight: bold;
  color: #444444;
}
/* 그리드 필터 */
.managerGrid table thead tr:nth-child(3) {
  /* border: 1px dotted red; */
  background: white;
}
/* 그리드 필터 input 태그 */
.managerGrid table thead tr input {
  /* border: 1px dotted red; */
  border-radius: 5px;
}
/* 그리드 필터 연산자버튼 */
.managerGrid table thead tr th div div div {
  /* border: 1px dotted red; */
  display: none;
}
/* 그리드 - '데이터가 존재하지 않습니다' */
.managerGrid table tbody tr {
  /* border: 1px dotted red; */
  display: table-row;
}
/* 엑셀파일 가져오기 - 영역 */
.managerUploadDiv {
  width: 600px;
  height: 150px;
  display: flex;
  flex-direction: column;
}
/* 엑셀파일 가져오기 - 업로드 버튼 */
.managerUploadBtn {
  flex: none;
  margin-top: 15px;
  margin-left: auto;
}

.managerLoading {
  position: relative;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 60vh;
  pointer-events: none; 
  /* z-index: -1; */
}

.managerG {
  pointer-events: none; 
  padding-top: 24px;
  width: 100%;
  position: absolute;
  opacity: 0.7;
}