.installerEnsoListTable{
  border-spacing: 0px;
  width: 100%;
}

.installerEnsoListTable thead tr th{
  background-color: #FAFAFA;
  border-bottom: 1px solid #dddddd;
  text-align: left;
  font-size: 16px;
  font-weight: 500;
  padding: 8px 0 11px 8px;
}

.installerEnsoListTable thead tr td{
  background-color: #FAFAFA;
  text-align: right;
  padding-right: 10px;
  border-bottom: 1px solid #dddddd;
}

.installerEnsoListTable tbody{
  width: 100%;
}

.movePosTerm{
  color :#000000;
}

.listVeiwText {
  text-align: center;
}