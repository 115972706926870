
.alLogDiv{
    width: 100%;
    height: 77vh;
}
.alLogSrchDiv{
    margin-bottom: 1%;
    width: 100%;
    height: 8%;
    background-color: white;
    border: 1px solid gainsboro;
    border-radius: 5px;
    vertical-align: middle;
    display: table;
}

.alLogGrid{
    border: 1px solid gainsboro;
    height: 100%;
}
.alLogGrid thead{
    text-align: center;
}
.alLogGrid table .k-master-row{
    cursor: pointer;
}
.alLogGrid tr td{
    border-bottom: 1px solid gainsboro !important;
}

.alLogSrchLeft{
    padding-left: 20px;
    position: relative;
    float:left;
    top: 50%;
    transform: translateY(-50%);
}
.alLogSrchRight{
    padding-right: 20px;
    position: relative;
    float: right;
    top: 50%;
    transform: translateY(-50%);
}

.alLogSrchDdl,
.alLogDtStrt{
    margin-left: 10px;
    margin-right: 5px;
}
.alLogSrchInp,
.alLogDtEnd{
    margin-left: 5px;
    margin-right: 10px;
}

/* alLogDetail */
.alLogDetailHeader {
    border: 1.5px solid gainsboro;
    border-bottom: none;
    text-align: left !important;
    padding: 10px;
    background-color: white;
    font-weight: bold;
  }
  .alLogDetailContent {
    border: 1.5px solid gainsboro;
    text-align: left !important;
    padding: 10px;
    padding-left: 30px;
    background-color: white;
  }
  