.chBtnAA {
  background: #f8f6f6;
  border: 1px solid #ccc9c9;
  border-radius: 5px;
  padding: 5px 15px;
  text-align: center;
  text-shadow: none;
  text-transform: uppercase;
  -webkit-transition: all 280ms ease;
  transition: all 280ms ease;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  cursor: pointer;
  -webkit-appearance: none;
  display: inline-block;
  color: rgb(39, 38, 38);
  font-weight: normal;
}

.chBtnAA:hover {
  color: #333; 
  background-color:#fff;
}

.inqBtn {
  margin-left: 5px;
}
.writeBtn {
  /* color: black; */
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  /* background: #fff; */
  border: 1px solid #dddddd;
  border-radius: 5px;
  /* padding: 4px 15px; */
  /* font-weight: bold; */
  text-align: center;
  text-shadow: none;
  text-transform: uppercase;
  /* -webkit-transition: all 280ms ease; */
  /* transition: all 280ms ease; */
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  cursor: pointer;
  -webkit-appearance: none;
  display: inline-block;
  padding: 3px 8px; 
  background: #efefef;
  color: #333;
}

.searchSubTitleNoti {
  top: 5px;
  margin-left: 7px;
  float: right;
}

.searchSubTitleNoti >.searchSubTitleC {
  margin-left: 0;
}

.noticeTitle {
  width: 99%;
  border: ridge gray;
  border-width: 0.1em;
}

.notiEditor {
  width: 100%;
}

.notiLoading {
  position: relative;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 60vh;
  pointer-events: none;
  /* z-index: -1; */
}

.searchTerm {
  background-color: #fff;
  padding: 5px 4px;
  box-sizing: border-box;
  border-radius: 5px;
  vertical-align: middle;
  border: 1px solid #e4e4e4;
}

/* 데스크탑 일반 */
@media (min-width: 768px) {
  .chBtnAA {
    background: #f8f6f6;
    border: 1px solid #ccc9c9;
    border-radius: 5px;
    padding: 5px 15px;
    text-align: center;
    text-shadow: none;
    text-transform: uppercase;
    -webkit-transition: all 280ms ease;
    transition: all 280ms ease;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    cursor: pointer;
    -webkit-appearance: none;
    display: inline-block;
    color: rgb(39, 38, 38);
    font-weight: normal;
  }
  .searchSubTitleD {
    float: right;
    padding-right: 5px;
    color: black;
    white-space: nowrap;
  }
  .searchTerm {
    width: 300px;
    font-size: 14px;
    border-radius: 4px;
    border: 1px solid #dddddd;
    padding: 5px 4px;
    color: rgb(8, 8, 8);
    vertical-align: -2px;
    background-color: white;
  }
  .notiBtn {
    padding: 15px;
    margin-left: 5px;
    background: #e4e4e4;
    border-radius: 5px;
  }
  .notiBtn:hover,
  .notiBtn:focus {
    background-color: white;
    border: 1px solid #b9b0b0;
  }
}
/* 모바일 */
@media (max-width: 767px) {
  .searchSubTitleD {
    text-align: center;
    color: black;
  }
  .searchTerm {
    width: 80%;
    font-size: 14px;
    border-radius: 4px;
    border: 1px solid #616161;
    padding: 5px 4px;
    color: black;
    background-color: white;
    vertical-align: -2px;
  }
  .notiBtn {
    margin-left: 5px;
    width: 10%;
    padding: 15px;
    background: #e4e4e4;
    border-radius: 5px;
  }
  .notiBtn:hover,
  .notiBtn:focus {
    background-color: white;
    border: 1px solid #b9b0b0;
  }
  .mobileSearchSubTitleD {
    vertical-align: middle;
    text-align: center;
  }
  .writeBtnDiv {
    margin-top: 5px;
    text-align: center;
  }
  .chBtnAA {
    background: #f8f6f6;
    border: 1px solid #ccc9c9;
    border-radius: 5px;
    padding: 5px;
    text-align: center;
    text-shadow: none;
    text-transform: uppercase;
    -webkit-transition: all 280ms ease;
    transition: all 280ms ease;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    cursor: pointer;
    -webkit-appearance: none;
    display: inline-block;
    color: rgb(39, 38, 38);
    font-weight: normal;
  }
}
