/* 모바일 */
@media (max-width: 767px) {
    .sideBar {
      height: 100% !important;
      display: flex;
      flex-direction: column;
      /* border-right: 0.5px solid; */
      border-radius: 0;
      /* border-color: rgba(64, 194, 133, 0.693); */
      background-color: white;
      transition: 0.8s ease;
    }
    .toggle-menu {
      height: 50px;
      border-top-right-radius: 10rem;
      border-bottom-right-radius: 9rem;
      width: 10px;
      position: absolute;
      outline: none;
      z-index: 1;
      background-color: rgba(64, 194, 133, 0.693);
      border-color: rgba(64, 194, 133, 0.693);
      border-left: 0;
    }
    .sideBarContent {
      font-size: 15px;
      padding: 15px;
      border-bottom: 0.5px solid gray;
    }
    .sideBarClickedContent {
      font-size: 15px;
      padding: 15px;
      background-color: royalblue;
      color: white;
      border-bottom: 0.5px solid gray;
    }
    .sideBarSecContent {
      font-size: 15px;
      padding: 15px 15px 15px 20px;
      border-bottom: 0.5px solid gray;
    }
    .sideBarSecClickedContent {
      font-size: 15px;
      padding: 15px 15px 15px 20px;
      background-color: royalblue;
      color: white;
      border-bottom: 0.5px solid gray;
    }
    .sideBarThirdContent {
      font-size: 15px;
      padding: 15px 15px 15px 30px;
      border-bottom: 0.5px solid gray;
    }
    .sideBarSpan {
      float: right;
      padding: 0px;
      font-size: 15px;
    }
    .subdirectorySpan {
      padding-right: 5px;
    }
    .sideBarFooter {
      background-color: #01579b;
      text-align: center;
      position: fixed;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 15vh;
    }
  }
  /* 데스크탑 일반 */
  @media (min-width: 768px) {
    .sideBar {
      display: none;
    }
    .sideBarContentDiv {
      display: none;
    }
  }
  