.parent{
  /* padding: 0.25rem; */
  /* background-color: #1d1d1d; */
  border: 0.125rem solid rgba(34, 10, 10, 0.08);
  /* position: absolute; */
  /* height: 100%; */
  /* width: 1850px; */
  /* height: calc(100% - 6rem); */
  /* min-height: 40.5rem; */
  /* width: 90%; */
  padding: 1.25rem 0.9375rem 0.9375rem 4.375rem;
}

.wrapChild{
  padding: 1.25rem 1.25rem;
  height: 100%;
  /* border: 0.125rem solid rgba(0, 136, 134, 0.5); */
  overflow-x: hidden;
  overflow-y: auto;
}

.colXs1{
  padding: 0;
  width: 4.375rem;
  height: 100%;
  vertical-align: middle;
  float: left;
}

.colXs1Left{
  padding: 0;
  width: 4.375rem;
  height: 49%;
  vertical-align: middle;
  float: left;
  position: absolute;
  margin-left: -2%;
  vertical-align: middle;
  float: left;
}

.colXs1Right{
  padding: 0;
  width: 4.375rem;
  height: 100%;
  vertical-align: middle;
  float: right;
}

.colXs10Child{
  width: calc(100% - 8.75rem);
  height: 100%;
  padding-top: 0.625rem;
}


.helper{
  display: inline-block;
  height: 100%;
  vertical-align: middle;
}

.arrow{
  /* color: white;  임시주석 */
  vertical-align: middle;
  display: inline-block;
  cursor: pointer;
}

.dashboardSection{
  padding: 0.9375rem;
  text-align: center;
  /* overflow: hidden; */
  background-color: white;
  margin-bottom: 0.3125rem;
  border-radius: 0.3125rem;
  text-align: center;
}

.cntSection{
  float: left;
  font-size: 14px;
  margin-left: 60px;
  margin-right: 60px;
}

.cntCon{
  font-weight: bold;
  font-size: 1.125rem;
  margin-left: 0.625rem;
}

.cntSub{
  font-weight: bold;
  font-size: 1.125rem;
  margin-left: 0.625rem;
}

.ActiveUsers{
  background: white;
  border: 1px solid #d4d2d0;
  border-radius: 4px;
  font-weight: 300;
  padding: .5em 1.5em;
  white-space: nowrap;
}

.ActiveUsersValue{
  display: inline-block;
  font-weight: 600;
  margin-right: -.25em;
}

.subject {
  /* color: white; */
  font-weight: bold;
  font-size: 14px;
  margin: 5px 5px 5px 5px;
}

.chartContainer {
  width: 98%;
  height: calc(100% - 3.25rem);
  padding: 1.25rem 1.25rem 0 0.625rem;
  background: white;
}

@media (min-width: 1200px){
  .fixHeghit_1{
    /* height: 19.125rem; */
    height: 400px;
    width: 50%;
    position: relative;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
    float: left;
    box-sizing: border-box;
    margin-top: 10px;
    margin-bottom: 10px;
  }
}


.Wspace01 {
  padding-top: 2.875rem;
  padding-bottom: 0.375rem;
}

.Wspace02 {
  margin-top: 1.875rem;
}

.Wspace03 {
  margin-top: 2.5rem;
}

.hideLeft {
  display: none;
}

.showLeft {
  display: block;
}

.hideRight {
  display: none;
}

.showRight {
  display: block;
}

.hideArrow {
  display: none;
}

.showArrow {
  display: block;
}

.dateContainer{
  margin-top: 5px;
}