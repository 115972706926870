/* buttons */
/* PC css */
@media (min-width: 767px) {
  #login .md-btn-large {
    border: none;
    border-radius: 4px;
    -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    min-height: 31px;
    min-width: 100%;
    padding: 10px 16px;
    text-align: center;
    text-shadow: none;
    text-transform: uppercase;
    box-sizing: border-box;
    cursor: pointer;
    display: inline-block;
    vertical-align: middle;
    font-family: "Noto Sans KR", sans-serif;
    font-weight: 400;
    text-decoration: none;
  }
  #login .md-btn-large:hover,
  #login .md-btn-large:focus,
  #login .md-btn-large:active {
    outline: none;
    text-decoration: none;
    -webkit-box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  }

  /* forms */
  #login select,
  textarea,
  input:not([type]),
  input[type="text"],
  input[type="password"],
  input[type="datetime"],
  input[type="datetime-local"],
  input[type="date"],
  input[type="month"],
  input[type="time"],
  input[type="week"],
  input[type="number"],
  input[type="email"],
  input[type="url"],
  input[type="search"],
  input[type="tel"],
  input[type="color"] {
    outline: none;
  }
  #login select.md-input,
  textarea.md-input,
  input:not([type]).md-input,
  input[type="text"].md-input,
  input[type="password"].md-input,
  input[type="datetime"].md-input,
  input[type="datetime-local"].md-input,
  input[type="date"].md-input,
  input[type="month"].md-input,
  input[type="time"].md-input,
  input[type="week"].md-input,
  input[type="number"].md-input,
  input[type="email"].md-input,
  input[type="url"].md-input,
  input[type="search"].md-input,
  input[type="tel"].md-input,
  input[type="color"].md-input {
    border-radius: 4px;
    border: 1px solid #e3e3e3;
    font-family: "Noto Sans KR", sans-serif;
    font-weight: 200;
    -webkit-box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0);
    -webkit-box-sizing: border-box;
    box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0);
    box-sizing: border-box;
    padding: 10px 4px 10px 50px;
    background: transparent;
    width: 100%;
    display: block;
  }
  #login input::placeholder {
    color: #c4c4c4;
    font-size: 12px;
    font-family: "Noto Sans KR", sans-serif;
    font-weight: 400;
  }
  #login .md-input-icon {
    color: #c5c5c5;
    position: absolute;
    padding: 8px 10px 5px 12px;
    height: 27px;
    border-right: 1px solid #e3e3e3;
  }

  /* cards */
  #login .md-card {
    background: #fff;
    position: relative;
    border: none;
    border-radius: 4px;
  }
  #login .md-card + .md-card,
  .md-card + .uk-grid,
  .uk-grid + .md-card {
    margin-top: 25px;
  }
  #login .md-card .md-card-content {
    padding: 20px;
  }

  #login {
    width: 440px;
    max-width: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -220px;
    margin-left: -230px;
  }
  #login .login_heading {
    text-align: center;
    height: 90px;
  }
  #login .login_title {
    font-size: 22px;
    font-weight: bold;
    line-height: 70px;
  }
  #login .login_title small {
    font-size: 22px;
    font-weight: lighter;
    line-height: 70px;
  }
  #login .uk-form-row {
    margin: 0 30px 20px 30px;
  }
  #login .uk-form-row-btn {
    margin: 30px 30px 15px 30px;
    margin: 30px 30px 15px 30px;
  }
  #login .login_logo {
    width: 100%;
    font-size: 12px;
    color: #727272;
    text-align: center;
    vertical-align: text-bottom;
  }
  #login .login_logo table {
    max-width: 100%;
    margin: 40px 30px 20px 30px;
  }
  #login .login_logo table th {
    text-align: right;
  }
  #login .login_logo table td {
    font-size: 10px;
    text-align: left;
    padding-left: 20px;
    color: rgb(180, 180, 180);
  }
  #login .login_logo img {
    width: 90px;
  }

  /* 컬러 화이트 */
  #login .BtnBg {
    background: #1e88e5;
  }
  #login .BtnText {
    color: #fff;
  }

  /*/  컬러 화이트 */

  #login .material-icons {
    /* font-family: "Material Icons";
  font-weight: normal;
  font-style: normal; */
    font-size: 24px !important;
    /* Preferred icon size */
    display: inline-block;
    width: 1em;
    height: 1em;
    line-height: 1;
    text-transform: none;
    letter-spacing: normal;
    /* Support for all WebKit browsers. */
    -webkit-font-smoothing: antialiased;
    /* Support for Safari and Chrome. */
    text-rendering: optimizeLegibility;
    /* Support for Firefox. */
    -moz-osx-font-smoothing: grayscale;
    /* Support for IE. */
    -webkit-font-feature-settings: "liga";
    font-feature-settings: "liga";
    vertical-align: -4px;
    color: rgba(0, 0, 0, 0.54);
  }
  #login .material-icons.md-inactive {
    color: rgba(0, 0, 0, 0.26);
  }
  #login .material-icons.md-18 {
    font-size: 18px !important;
    vertical-align: -4px;
  }
  #login .material-icons.md-24 {
    font-size: 25px !important;
    vertical-align: -6px;
  }
  #login .material-icons.md-36 {
    font-size: 36px !important;
    vertical-align: -16px;
  }
  #login .material-icons.md-48 {
    font-size: 48px !important;
    vertical-align: -24px;
  }
  #login .material-icons.md-light {
    color: #ffffff;
  }
  #login .material-icons.md-light.md-inactive {
    color: rgba(255, 255, 255, 0.3);
  }

  /* 로그인 실패 시 오류 메세지 스타일 */
  .errorTxt {
    display: none;
    color: red;
  }
}

/* 모바일 css */
@media (max-width: 767px) {
  #login .md-btn-large {
    border: none;
    border-radius: 4px;
    -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    min-height: 31px;
    min-width: 100%;
    padding: 10px 16px;
    text-align: center;
    text-shadow: none;
    text-transform: uppercase;
    box-sizing: border-box;
    cursor: pointer;
    display: inline-block;
    vertical-align: middle;
    font-family: "Noto Sans KR", sans-serif;
    font-weight: 400;
    text-decoration: none;
  }
  #login .md-btn-large:hover,
  #login .md-btn-large:focus,
  #login .md-btn-large:active {
    outline: none;
    text-decoration: none;
    -webkit-box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  }

  /* forms */
  #login select,
  textarea,
  input:not([type]),
  input[type="text"],
  input[type="password"],
  input[type="datetime"],
  input[type="datetime-local"],
  input[type="date"],
  input[type="month"],
  input[type="time"],
  input[type="week"],
  input[type="number"],
  input[type="email"],
  input[type="url"],
  input[type="search"],
  input[type="tel"],
  input[type="color"] {
    outline: none;
  }
  #login select.md-input,
  textarea.md-input,
  input:not([type]).md-input,
  input[type="text"].md-input,
  input[type="password"].md-input,
  input[type="datetime"].md-input,
  input[type="datetime-local"].md-input,
  input[type="date"].md-input,
  input[type="month"].md-input,
  input[type="time"].md-input,
  input[type="week"].md-input,
  input[type="number"].md-input,
  input[type="email"].md-input,
  input[type="url"].md-input,
  input[type="search"].md-input,
  input[type="tel"].md-input,
  input[type="color"].md-input {
    border-radius: 4px;
    border: 1px solid #e3e3e3;
    font-family: "Noto Sans KR", sans-serif;
    font-weight: 200;
    -webkit-box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0);
    -webkit-box-sizing: border-box;
    box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0);
    box-sizing: border-box;
    padding: 10px 4px 10px 50px;
    width: 100%;
    display: block;
  }

  #login input::placeholder {
    color: #c4c4c4;
    font-size: 12px;
    font-family: "Noto Sans KR", sans-serif;
    font-weight: 400;
  }
  #login .md-input-icon {
    color: #c5c5c5;
    position: absolute;
    padding: 8px 10px 5px 12px;
    height: 27px;
    border-right: 1px solid #e3e3e3;
  }

  /* cards */
  #login .md-card {
    background: #fff;
    position: relative;
    border: none;
    border-radius: 4px;
  }
  #login .md-card + .md-card,
  .md-card + .uk-grid,
  .uk-grid + .md-card {
    margin-top: 25px;
  }
  #login .md-card .md-card-content {
    padding: 20px;
  }

  #login {
    top: 22vh;
    margin-left: 5%;
    width: 90%;
    max-width: 100%;
    position: absolute;
  }
  #login .login_heading {
    text-align: center;
    height: 90px;
  }
  #login .login_title {
    width: 100%;
    font-size: 18px;
    font-weight: bold;
    line-height: 70px;
  }
  #login .login_title small {
    font-size: 18px;
    font-weight: lighter;
    line-height: 70px;
  }
  #login .uk-form-row {
    margin: 0 30px 20px 30px;
  }
  #login .uk-form-row-btn {
    margin: 30px 30px 15px 30px;
  }
  #login .login_logo {
    width: 100%;
    font-size: 12px;
    color: #727272;
    text-align: center;
    vertical-align: text-bottom;
  }
  #login .login_logo table {
    max-width: 100%;
    margin: 40px 30px 20px 30px;
  }
  #login .login_logo table th {
    text-align: right;
  }
  #login .login_logo table td {
    font-size: 10px;
    text-align: left;
    padding-left: 20px;
    color: rgb(180, 180, 180);
  }
  #login .login_logo img {
    width: 200px;
  }

  /* 컬러 화이트 */
  #login .BtnBg {
    background: #1e88e5;
  }
  #login .BtnText {
    color: #fff;
  }

  /*/  컬러 화이트 */

  #login .material-icons {
    font-family: "Material Icons";
    font-weight: normal;
    font-style: normal;
    font-size: 18px;
    /* Preferred icon size */
    display: inline-block;
    width: 1em;
    height: 1em;
    line-height: 1;
    text-transform: none;
    letter-spacing: normal;
    /* Support for all WebKit browsers. */
    -webkit-font-smoothing: antialiased;
    /* Support for Safari and Chrome. */
    text-rendering: optimizeLegibility;
    /* Support for Firefox. */
    -moz-osx-font-smoothing: grayscale;
    /* Support for IE. */
    -webkit-font-feature-settings: "liga";
    font-feature-settings: "liga";
    vertical-align: -4px;
    color: rgba(0, 0, 0, 0.54);
  }
  #login .material-icons.md-inactive {
    color: rgba(0, 0, 0, 0.26);
  }
  #login .material-icons.md-18 {
    font-size: 18px !important;
    vertical-align: -4px;
  }
  #login .material-icons.md-24 {
    font-size: 25px !important;
    vertical-align: -6px;
  }
  #login .material-icons.md-36 {
    font-size: 36px !important;
    vertical-align: -16px;
  }
  #login .material-icons.md-48 {
    font-size: 48px !important;
    vertical-align: -24px;
  }
  #login .material-icons.md-light {
    color: #ffffff;
  }
  #login .material-icons.md-light.md-inactive {
    color: rgba(255, 255, 255, 0.3);
  }
  /* 로그인 실패 시 오류 메세지 스타일 */
  .errorTxt {
    display: none;
    color: red;
  }

  .newUserInputPasswordArea {
    margin-top: 10px;
  }

  .newPasswordInput {
    margin-top: 5px;
    margin-bottom: 5px;
  }

  #newloginPassword .md-input-icon {
    color: #c5c5c5;
    position: absolute;
    padding: 8px 10px 5px 12px;
    height: 27px;
    border-right: 1px solid #4d4d4d;
  }
  .MobileTitleLogo {
    padding-top: 5vh;
    text-align: center;
    vertical-align: middle;
  }
  .MobileTitleLogo img {
    width: 70vw;
  }
}
