.meainStatCount {
  margin-right: 20px;
}

.installerMeainList {
  width: 100%;
  min-height: 67vh;
  max-height: 750px;
  padding-left: 0px;
  /* margin-top: -24px; */
  background-color: #f5f5f5;
}
