/* 상, 하단 - 그리드 */
.sysServerHighGrid, .sysServerLowGrid {
  height: 100%;
}
/* 그리드 상,하단 컬럼 */
.sysServerHighGrid table thead tr th,
.sysServerLowGrid table thead tr th {
  /* border: 1px dotted red; */
  border-top: 1px solid #e3e3e3;
  text-align: center;
  font-size: 15px;
  font-weight: bold;
  color: #444444;
}

.sysServerHighGrid table tbody tr :nth-of-type(2),
.sysServerHighGrid table tbody tr :nth-of-type(3) , .sysServerLowGrid table tbody tr :nth-of-type(3),
.sysServerHighGrid table tbody tr :nth-of-type(4) , .sysServerLowGrid table tbody tr :nth-of-type(4),
.sysServerHighGrid table tbody tr :nth-of-type(5)
{
  text-align: right;
}
