.meainUserListGirdArea{
  pointer-events: none; 
  /* padding-top: 24px; */
  width: 100%;
  position: absolute;
  opacity: 0.5;
}

/* 유저리스트 로딩 스피너 */
.meainUserListGirdLoading{
  position: relative;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 80%;
  pointer-events: none;
}