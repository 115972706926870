.meainStatCount {
  margin-right: 20px;
}

.grid {
  width: 100%;
  height: 720px;
  padding-left: 0px;
  margin-top: -24px;
}

.installerMeain {
  opacity: 0.5;
  pointer-events: none;
}

.installerMeainLoading {
  position: absolute;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 90%;
  pointer-events: none;
  bottom: 45%;
}

.disableCompo {
  display: none;
}

.searchSubTitleC {
  position: relative;
  top: 3px;
  margin-left: 7px;
}
.mobileSearchBtn {
  border: 1px solid gainsboro;
  width: 90%;
}

.mobileSearchBtn:hover {
  background-color: white;
}

.statusViewHelp {
  font-weight: bold;
  height: 25px;
  color: #bfbfbf;
  float: right;
}

.statusHelpImg {
  position: absolute;
  top: 18%;
  left: 66%;
  z-index: 9999;
  width: 550px;
  visibility: hidden;
  background-color: #fff;
  color: #231f20;
  font-size: 14px;
  display: block;
  padding: 15px 15px;
  box-shadow: 2px 2px 5px #999;
}

.statusViewHelp:hover .statusHelpImg {
  visibility: visible;
}