.installerMeainDropDown {
    margin-left: 5px;
    margin-right: 5px;
  }
  /* 데스크탑 일반 */
  @media (min-width: 768px) {
    .installerMeainTBgA {
      padding: 20px 10px;
      background-color: white;
      border-radius: 5px;
    }
  }
  /* 모바일 */
  @media (max-width: 767px) {
    .installerMeain {
      padding-right: 5px;
    }
    .installerMeainTBgA {
      /* display: none; */
      padding: 20px 10px;
      background-color: white;
      border-radius: 10px;
    }
    .checkboxBox {
      margin: 0px;
    }
    .small-padding {
      padding: 0px;
    }
    .installerMeainListPager .k-pager-numbers-wrap > select {
      display: block;
      color: black;
      background-color: white;
    }
    .installerMeainListPager .k-pager-numbers-wrap > ul {
      display: none;
    }
  }
  
  .installerMeainSearchTable {
    width: 100%;
  }
  .installerMeainSearchTable tr th {
    width: 25%;
  }
  .installerMeainSearchTable tr td {
    width: 25%;
    text-align: center;
  }
  .installerMeainSearchTable tr td .installerMeainDropDown {
    width: 100%;
  }
  .installerMeainMobileCal {
    width: 47%;
  }
  .installerMeainRgnDropDown{
      margin-left: 1%;
      width: 49%;
  }
  
  .installerMeainSearchTerm {
    width: 60%;
    font-size: 14px;
    border-radius: 4px;
    border: 1px solid #e4e4e4;
    padding: 5px 4px;
    color: #333;
    vertical-align: -2px;
  }
  