/* 수용가 관리 - 화면 전체 */
.installerBody {
  /* border: 1px dotted red; */
  /* display: flex; */
  /* flex-direction: column; */
  height: 85vh;
  padding: 5px;
}
/* 수용가 관리 - 화면 상단 카테고리 */
.installerCate {
  /* border: 1px dotted blue; */
  height: 5%;
  margin: 0 0 10px;
}
/* 수용가 관리 - 화면 상단 카테고리 - 버튼*/
.installerCate button {
  /* border: 1px dotted red; */
  border-radius: 5px;
  background: white;
  margin-right: 10px;
}
/* 수용가 관리 - 화면 하단 그리드 */
.installerGrid {
  /* border: 1px dotted green; */
  height: 94%;
}
/* 엑셀저장 버튼 */
.exelSDown {
  width: 15px;
  height: 15px;
}
/* 엑셀샘플버튼 글씨색깔 조절 */
.installerExcelTxt {
  color: #444444;
}
/* 엑셀샘플버튼 글씨색깔 조절 */
.installerExcelTxt:hover {
  color: #444444;
}