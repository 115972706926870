@import url("https://fonts.googleapis.com/css?family=Noto+Sans+KR:100,300,400,500,700,900&display=swap&subset=korean");
* {
  font-family: "Noto Sans KR", sans-serif;
}
[class*="uk-animation-"] {
  -webkit-animation-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  animation-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  -webkit-animation-duration: 560ms;
  animation-duration: 560ms;
}
@media (max-width: 1920px){
  html {
      overflow-x: hidden;
  }
}
@media (max-width: 1919px){
  html {
      overflow-x: auto;
  }
}
html {
  height: 100%;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

body {
  min-width: 1920px;
  min-height: 100%;
  padding-top: 48px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

a,
button {
  outline: none !important;
}
a {
  color: #1e88e5;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
a:hover,
a:active {
  color: #0d47a1;
  text-decoration: none;
}

/* headings */
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Noto Sans KR", sans-serif;
  font-weight: 500;
}

/* layout */
#page_content:before {
  content: "";
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  display: block;
  -webkit-transition: opacity 400ms;
  transition: opacity 400ms;
  z-index: -1;
}
/* 데스크탑 일반 */
@media (min-width: 768px) {
  #page_content_inner {
    padding: 10px 24px 24px 24px;
    margin: 0px 0px 0px 70px;
  }
}
/* 모바일 */
@media (max-width: 767px) {
  #page_content_inner {
    /* height: 100%; */
    padding: 0px 0px 50px 0px;
    margin: 0px 0px 0px 0px;
  }
}
#page_content {
  margin: 40px 0 40px 0;
}

/* forms */

select,
textarea,
input:not([type]),
input[type="text"],
input[type="password"],
input[type="datetime"],
input[type="datetime-local"],
input[type="date"],
input[type="month"],
input[type="time"],
input[type="week"],
input[type="number"],
input[type="email"],
input[type="url"],
input[type="search"],
input[type="tel"],
input[type="color"] {
  outline: none;
}
select.search-input,
textarea.search-input,
input:not([type]).search-input,
input[type="text"].search-input,
input[type="password"].search-input,
input[type="datetime"].search-input,
input[type="datetime-local"].search-input,
input[type="date"].search-input,
input[type="month"].search-input,
input[type="time"].search-input,
input[type="week"].search-input,
input[type="number"].search-input,
input[type="email"].search-input,
input[type="url"].search-input,
input[type="search"].search-input,
input[type="tel"].search-input,
input[type="color"].search-input {
  border-radius: 4px;
  border: 1px solid #e3e3e3;
  font-family: "Noto Sans KR", sans-serif;
  font-weight: 200;
  -webkit-box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0);
  -webkit-box-sizing: border-box;
  box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0);
  box-sizing: border-box;
  padding: 0px 10px;
  background: #fff;
  height: 31px;
}
input::placeholder {
  color: #999;
  font-size: 12px;
  font-family: "Noto Sans KR", sans-serif;
  font-weight: 400;
}

/* grid */

.uk-grid + .uk-grid,
.uk-grid-margin,
.uk-grid > * > .uk-panel + .uk-panel {
  margin-top: 24px;
}

/* cards */
.md-card {
  position: relative;
  /*
  -webkit-box-shadow: 0 1px 7px rgba(0, 0, 0, 0.15), 0 1px 7px rgba(0, 0, 0, 0.15);
  box-shadow: 0 1px 7px rgba(0, 0, 0, 0.15), 0 1px 7px rgba(0, 0, 0, 0.15);
  */
  border: 1px solid #dddddd;
}
.md-card + .md-card,
.md-card + .uk-grid,
.uk-grid + .md-card {
  margin-top: 25px;
}
.md-card .md-card-toolbar {
  height: 40px;
  padding: 0;
}

.md-card .md-card-toolbar:before,
.md-card .md-card-toolbar:after {
  content: " ";
  display: table;
}
.md-card .md-card-toolbar:after {
  clear: both;
}
.md-card .md-card-toolbar:before,
.md-card .md-card-toolbar:after {
  content: " ";
  display: table;
}
.md-card .md-card-toolbar:after {
  clear: both;
}
.md-card .md-card-toolbar-heading-text {
  font-family: "Noto Sans KR", sans-serif;
  font-weight: 600;
  font-size: 19px;
  color: #212121;
  margin: 0;
  text-align: left;
  overflow: hidden;
  background-color: #eeeeee;
  padding: 8px 0 8px 10px;
  border-bottom: 1px solid #dddddd;
}
.md-card .md-card-toolbar-heading-text.small {
  font-size: 14px;
}
.md-card .md-card-toolbar-heading-text.large {
  font-size: 18px;
  font-weight: 400;
}
.md-card .md-card-toolbar .md-toggle-group {
  float: left;
  margin: 8px 0 0 16px;
}
.md-card .md-card-toolbar .md-toggle-group.md-toggle-group-small {
  margin-top: 10px;
}
.md-card .md-card-toolbar .md-card-toolbar-actions {
  padding-right: 10px;
  margin-top: -30px;
  z-index: 1;
  text-align: right;
}
.md-card .md-card-toolbar .md-card-toolbar-actionsDr {
  float: right;
  padding-right: 10px;
  margin-top: -37px;
}
.md-card .md-card-toolbar .md-card-toolbar-actions i {
  font-size: 25px;
  margin-top: -3px;
}
.md-card .md-card-toolbar .md-card-toolbar-actionsLeft {
  position: absolute;
  float: left;
  padding: 0;
  margin-top: -35px;
  margin-left: 125px;
}
.md-card
  .md-card-toolbar
  .md-card-toolbar-actions
  .uk-open
  .md-card-toolbar-icon {
  background: rgba(0, 0, 0, 0.085);
  color: #212121;
}
.md-card .md-card-toolbar .md-card-toolbar-actions .md-card-dropdown {
  display: inline-block;
  position: relative;
}
.md-card .md-card-toolbar .md-card-toolbar-actions .selectize-control {
  min-width: 220px;
  margin-top: -3px;
}
.md-card
  .md-card-toolbar
  .md-card-toolbar-actions
  .selectize-control
  .selectize-input {
  min-height: 30px;
  padding: 4px 8px;
}
.md-card
  .md-card-toolbar
  .md-card-toolbar-actions
  .selectize-control
  .selectize-dropdown {
  margin-top: -34px;
}
.md-card .md-card-toolbar .md-icon + .md-card-dropdown {
  margin-left: 4px;
}
.md-card .md-card-toolbar .md-card-fullscreen-deactivate {
  margin: 9px 8px 0 0;
}
.md-card .md-card-toolbar-input {
  border: none;
  font: 400 18px / 24px "Roboto", sans-serif;
  height: auto;
  background: none !important;
  padding: 12px 0;
  width: 50%;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.md-card .md-card-toolbar .uk-tab {
  margin-top: -2px;
  border-bottom: none;
}
.md-card .md-card-toolbar .uk-tab li > a {
  padding: 10px 8px !important;
}
.md-card .md-card-head {
  height: 160px;
  position: relative;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
.md-card .md-card-head-menu {
  position: absolute;
  right: 8px;
  top: 8px;
}
.md-card .md-card-head-avatar {
  width: 82px;
  height: 82px;
  border-radius: 50%;
  margin-top: 16px;
  border: 2px solid #fff;
  display: inline-block;
}
.md-card .md-card-head-text {
  padding: 8px 16px 16px;
  font: 500 16px / 22px "Roboto", sans-serif;
  color: #212121;
  margin: 0;
}
.md-card .md-card-head-text span:not(.listNavSelector) {
  display: block;
  font: 400 12px / 18px "Roboto", sans-serif;
  margin-top: -2px;
}
.md-card .md-card-head-text.text_dark {
  color: #212121 !important;
}
.md-card .md-card-head-text-over {
  background-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(rgba(0, 0, 0, 0.65)),
    to(rgba(0, 0, 0, 0))
  );
  background-image: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0.65) 0%,
    rgba(0, 0, 0, 0) 100%
  );
}
.md-card .md-card-head-subtext {
  position: absolute;
  bottom: 10px;
  left: 0;
  right: 0;
  padding: 0 16px;
  text-align: right;
  color: #fff;
}
.md-card .md-card-head-subtext span {
  font-size: 26px;
}
.md-card .md-card-head-icon {
  font-size: 48px;
  color: #fff;
  vertical-align: middle;
}
.md-card .md-card-head.head_background {
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  border-bottom-color: transparent;
}
.md-card .md-card-head.head_background .md-card-head-text {
  color: #fff;
}
.md-card .md-card-head.head_background_top {
  background-repeat: no-repeat;
  background-position: center top;
}
.md-card .md-card-head.head_background_bottom {
  background-repeat: no-repeat;
  background-position: center bottom;
}
.md-card .md-card-head .head_chart {
  height: 100px;
  width: 100%;
  position: absolute !important;
  left: 0;
  top: 40px;
}
.md-card .md-card-head .fitVid_player {
  width: 100%;
  height: 160px;
  overflow: hidden;
}
.md-card .md-card-head-img {
  height: 100%;
  width: auto;
}
.md-card .md-card-head iframe {
  height: 160px;
}
.md-card .md-card-content {
  padding: 0px;
}
.md-card .md-card-content.padding-mainTop {
  padding: 20px 0 0 0;
}
.md-card .md-card-content.small-padding {
  padding: 20px 10px;
}
.md-card.md-card-fullscreen-activate {
  cursor: pointer;
}
.md-card.md-card-fullscreen {
  position: fixed;
  z-index: 9998;
  overflow-x: hidden;
  cursor: default;
}
.md-card.md-card-fullscreen .md-card-fullscreen-activate {
  display: none;
}
.md-card.md-card-fullscreen .md-card-fullscreen-hide {
  display: none;
}
.md-card .md-card-fullscreen-content {
  display: none;
  padding: 16px;
}
.md-card-fullscreen-content-hidden .md-card .md-card-fullscreen-content {
  display: block;
  visibility: hidden;
  padding: 0;
}
.md-card.mdToolbar_fixed {
  overflow-y: hidden;
}
.md-card.mdToolbar_fixed > .md-card-toolbar {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  z-index: 9999;
  -webkit-box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16),
    0 3px 6px rgba(0, 0, 0, 0.23);
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}
.md-card.mdToolbar_fixed > .md-card-content {
  overflow-y: scroll;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  position: absolute;
  width: 100%;
  top: 48px;
  bottom: 0;
}
.md-card.mdToolbar_fixed > .md-card-content .md-card-fullscreen-content {
  padding: 16px 0;
}
.md-card.md-card-overlay {
  overflow: hidden;
  padding-bottom: 54px;
}
.md-card.md-card-overlay .md-card-content {
  height: 142px;
  overflow: hidden;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.md-card.md-card-overlay .md-card-content.no_truncate {
  position: relative;
}
.md-card.md-card-overlay .md-card-content.no_truncate:after {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 8px;
  background-image: -webkit-gradient(
    linear,
    left bottom,
    left top,
    color-stop(50%, #ffffff),
    to(rgba(255, 255, 255, 0))
  );
  background-image: linear-gradient(
    to top,
    #ffffff 50%,
    rgba(255, 255, 255, 0) 100%
  );
  display: block;
  content: "";
  z-index: 10;
}
.md-card.md-card-overlay .md-card-content > pre {
  margin-top: 0;
  max-height: 110px;
}
.md-card.md-card-overlay .md-card-content > pre > code {
  overflow: hidden;
}
.md-card.md-card-overlay .md-card-overlay-content {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  padding: 0 16px;
  margin-top: -54px;
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  text-align: left;
  bottom: 0;
  background: #fff;
  z-index: 10;
  -webkit-transition: all 280ms cubic-bezier(0.4, 0, 0.2, 1);
  transition: all 280ms cubic-bezier(0.4, 0, 0.2, 1);
}
.md-card.md-card-overlay .md-card-overlay-content p {
  margin: 0;
}
.md-card.md-card-overlay .md-card-overlay-content p + * {
  margin-top: 16px;
}
.md-card.md-card-overlay .md-card-overlay-content p + p {
  margin-top: 4px;
}
.md-card.md-card-overlay .md-card-overlay-header {
  padding: 12px 0;
}
.md-card.md-card-overlay .md-card-overlay-header:before,
.md-card.md-card-overlay .md-card-overlay-header:after {
  content: " ";
  display: table;
}
.md-card.md-card-overlay .md-card-overlay-header:after {
  clear: both;
}
.md-card.md-card-overlay .md-card-overlay-header:before,
.md-card.md-card-overlay .md-card-overlay-header:after {
  content: " ";
  display: table;
}
.md-card.md-card-overlay .md-card-overlay-header:after {
  clear: both;
}
.md-card.md-card-overlay .md-card-overlay-header h3,
.md-card.md-card-overlay .md-card-overlay-header h4 {
  margin: 0;
  text-overflow: ellipsis;
  display: inline-block;
  vertical-align: top;
  white-space: nowrap;
  overflow: hidden;
  width: 100%;
  padding-right: 32px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.md-card.md-card-overlay .md-card-overlay-header h3 {
  font: 400 16px / 30px "Roboto", sans-serif;
}
.md-card.md-card-overlay .md-card-overlay-header h4 {
  font: 500 14px / 30px "Roboto", sans-serif;
}
.md-card.md-card-overlay .md-card-overlay-header .md-icon {
  position: absolute;
  right: 12px;
  top: 11px;
}
.md-card.md-card-overlay-active .md-card-overlay-content {
  top: -1px;
  margin-top: 0;
}
.md-card.md-card-hover {
  -webkit-transition: all 280ms cubic-bezier(0.4, 0, 0.2, 1);
  transition: all 280ms cubic-bezier(0.4, 0, 0.2, 1);
  will-change: box-shadow;
}
.md-card.md-card-hover:hover {
  -webkit-box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19),
    0 6px 6px rgba(0, 0, 0, 0.23);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
}
.uk-sortable-dragged .md-card {
  -webkit-box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19),
    0 6px 6px rgba(0, 0, 0, 0.23);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
}
.uk-sortable-dragged .md-card canvas {
  margin: 0 auto;
  display: block;
}
.md-card .heading_list {
  padding: 0;
}

/*  material-icons  */

.material-icons {
  font-family: "Material Icons";
  font-weight: normal;
  font-style: normal;
  font-size: 18px;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  -webkit-font-feature-settings: "liga";
  font-feature-settings: "liga";
  vertical-align: -4px;
  color: rgba(0, 0, 0, 0.54);
}
.material-icons.md-inactive {
  color: rgba(0, 0, 0, 0.26);
}
.material-icons.md-18 {
  font-size: 18px !important;
  vertical-align: -4px;
}
.material-icons.md-24 {
  font-size: 25px !important;
  vertical-align: -6px;
}
.material-icons.md-36 {
  font-size: 36px !important;
  vertical-align: -16px;
}
.material-icons.md-48 {
  font-size: 48px !important;
  vertical-align: -24px;
}
.material-icons.md-light {
  color: #ffffff;
}
.material-icons.md-light.md-inactive {
  color: rgba(255, 255, 255, 0.3);
}

/* main header */
#header_main {
  background: #01579b;
  -webkit-box-shadow: 0 3px 6px rgba(0, 0, 0, 0.075),
    0 3px 6px rgba(0, 0, 0, 0.075);
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.075), 0 3px 6px rgba(0, 0, 0, 0.075);
  padding: 0px;
  height: 70px;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 1104;
}

#header_main .uk-navbar {
  border: none;
  background: none;
}
#header_main .uk-navbar .uk-navbar-brand {
  text-shadow: none;
  line-height: 42px;
  height: 38px;
  font-size: 15px;
  padding: 0;
  text-transform: uppercase;
}
#header_main .uk-navbar .uk-navbar-brand + * {
  margin-left: 25px;
}
#header_main .uk-navbar .uk-navbar-nav {
  position: relative;
}
#header_main .uk-navbar .uk-navbar-nav > li {
  position: static;
}
#header_main .uk-navbar .uk-navbar-nav > li > a {
  color: #333;
  text-shadow: none;
  border: none;
  line-height: 60px;
  font-family: "Noto Sans KR", sans-serif;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  height: 70px;
  z-index: auto;
  cursor: pointer;
}
#header_main .uk-navbar .uk-navbar-nav > li > a:focus,
#header_main .uk-navbar .uk-navbar-nav > li > a:active {
  background: none;
}
#header_main .uk-navbar .uk-navbar-nav > li > a:after {
  position: absolute;
  bottom: -2px;
  left: 50%;
  content: "";
  display: block;
  height: 0;
  width: 0;
  border: 7px solid transparent;
  margin-left: -6px;
  z-index: -1;
  -webkit-transition: all 100ms ease-in 0ms;
  transition: all 100ms ease-in 0ms;
}
#header_main .uk-navbar .uk-navbar-nav > li > a.navbar_link {
  line-height: 48px;
}
#header_main .uk-navbar .uk-navbar-nav > li:hover > a,
#header_main .uk-navbar .uk-navbar-nav > li:focus > a,
#header_main .uk-navbar .uk-navbar-nav > li.uk-open > a,
#header_main .uk-navbar .uk-navbar-nav > li.uk-active > a {
  background: none;
}
#header_main .uk-navbar .uk-navbar-nav > li.uk-open > a {
  position: relative;
}
#header_main .uk-navbar .uk-navbar-nav > li.uk-open > a:after {
  border-bottom-color: #fff;
  -webkit-transition: all 280ms cubic-bezier(0.4, 0, 0.2, 1);
  transition: all 280ms cubic-bezier(0.4, 0, 0.2, 1);
  z-index: 1104;
}

#header_main .user_actions a.user_action_image {
  position: relative;
  line-height: 48px !important;
}
#header_main .user_actions .user_action_icon {
  display: block;
  position: relative;
  text-align: center;
  padding-top: 6px;
}
#header_main .user_actions .user_action_icon .headata {
  font-size: 18px;
  color: #fff;
}
#header_main .user_actions .user_action_icon .headimg img {
  margin-top: -10px;
  padding: 0px 5px 0 0;
}
#header_main .user_actions .user_action_icon .headimgB img {
  margin-top: 0px;
}
#header_main .user_actions .user_action_icon:hover,
#header_main .user_actions .user_action_icon:focus {
  text-decoration: none;
}
#header_main .user_actions .user_action_icon > .uk-badge {
  background: #ff3b3b;
  color: #fff;
  font-size: 12px;
  font-weight: normal;
  position: absolute;
  left: 25px;
  top: 16px;
  min-width: 15px;
  padding: 2px;
  line-height: 14px;
  border-radius: 10px;
}
.header_full #sidebar_main {
  top: 70px;
  height: auto;
}
.header_full #header_main {
  margin-left: 0 !important;
  z-index: 1214;
}
.header_full.sidebar_mini #sidebar_main .menu_section > ul > li > ul {
  top: 100px;
}

.mainTitle {
  font-size: 36px;
  color: #fff;
  font-family: "Noto Sans KR", sans-serif;
  line-height: 70px;
  /* width: 76%; */
  letter-spacing: 2px;
}
.mainTitle small {
  font-size: 25px;
  font-family: "Noto Sans KR", sans-serif;
  font-weight: 500;
}

.headerHome {
  margin: 24px 0 0 20px;
}
.headerHome .menu_icon svg {
  font-size: 31px;
  color: #fff;
  /* width: 50px; */
  margin-top: -4px;
  margin-right: 8px;
  cursor: pointer;
}
.HeaderHistory {
  padding-top: 1px;
  font-weight: 400;
  color: #fff;
  font-size: 20px;
  padding-left: 15px;
}

/* main sidebar */

/* 데스크탑 일반 */
@media (min-width: 768px) {
  
#sidebar_main {
  position: fixed;
  height: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 1204;
  background: #485987;
  -webkit-box-shadow: 0 3px 6px rgba(0, 0, 0, 0.075),
    0 3px 6px rgba(0, 0, 0, 0.075);
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.075), 0 3px 6px rgba(0, 0, 0, 0.075);
}
}
/* 모바일 */
@media (max-width: 767px) {
  #sidebar_main {
    display: none;
  }
}
#sidebar_main,
#sidebar_main:before,
#sidebar_main:after,
#sidebar_main *,
#sidebar_main *:before,
#sidebar_main *:after {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
#sidebar_main .menu_section ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
#sidebar_main .menu_section ul > li {
  padding: 10px 0;
  margin: 0;
  list-style: none;
}
#sidebar_main .menu_section > ul > li > a {
  font-family: "Noto Sans KR", sans-serif;
  font-weight: 400;
  color: #c5c5c5;
  padding: 8px 20px;
  display: block;
  overflow: hidden;
  position: relative;
  font-size: 15px;
}

#sidebar_main .menu_section > ul > li > a:hover {
  text-decoration: none;
}
#sidebar_main .menu_section > ul > li > a > .menu_icon {
  width: 48px;
  display: inline-block;
  color: #c5c5c5;
}
#sidebar_main .menu_section > ul > li > a > .menu_icon .material-icons {
  font-size: 35px;
  color: #c5c5c5;
}
#sidebar_main
  .menu_section
  > ul
  > li.current_section
  > a
  > .menu_icon
  .material-icons {
  color: #1e88e5;
}
#sidebar_main .menu_section > ul > li.current_section > a {
  color: #1e88e5;
}

/* mini sidebar */

.sidebar_mini #sidebar_main {
  width: 70px;
  background: #f8f8f8;
  margin: 0;
  padding: 0;
  -webkit-transform: none;
  transform: none;
  left: 0;
}
.sidebar_mini #sidebar_main .menu_section > ul > li {
  margin: 0;
  padding: 0;
}
.sidebar_mini #sidebar_main .menu_section > ul > li > a {
  padding: 0;
  margin: 0;
  display: block;
  text-align: center;
  height: 80px;
}
.sidebar_mini #sidebar_main .menu_section > ul > li > a .menu_icon {
  display: inline-block;
  text-align: center;
  width: 45px;
}
.sidebar_mini
  #sidebar_main
  .menu_section
  > ul
  > li
  > a
  .menu_icon
  .material-icons {
  line-height: 80px;
  margin-top: 22px;
}

.sidebar_mini #sidebar_main .menu_section > ul li a:hover {
  background: #fff;
}
.FooterText {
  line-height: 16px;
  position: absolute;
  bottom: 130px;
  left: -83px;
  font-size: 9px;
  color: rgb(185, 185, 185);
  width: 250px;
  height: 50px;
  -ms-transform: rotate(-90deg);
  -webkit-transform: rotate(-90deg);
  transform: rotate(-90deg);
}
.copyrightDiv{
  z-index: -1;
  position: fixed;
  right: 25px;
  bottom: 5px;
  font-size: 9px;
  color: rgb(185, 185, 185);
}
/* / main sidebar */

/* Maindropdown */
.Maindropdown {
  position: relative;
  display: inline-block;
  z-index: 999999;
}
.Maindropbtn {
  background-color: #1e88e5;
  color: #fff;
  padding: 6px 8px;
  border: none;
  cursor: pointer;
  font-size: 14px;
  border-radius: 3px;
  margin-top: -5px;
  margin-left: 10px;
}
.Maindropbtn svg {
  color: #fff;
  padding-left: 25px;
  box-sizing: content-box;
}
.Maindropdown-content {
  position: absolute;
  left: 10px;
  top: 29px;
  z-index: 88;
  visibility: hidden;
  opacity: 0;
  filter: alpha(opacity=0);
  -webkit-transition: 200ms ease;
  -moz-transition: 200ms ease;
  -o-transition: 200ms ease;
  transition: 200ms ease;
  background-color: #fff;
  border: 1px solid #e3e3e3;
  border-top: none;
}
.Maindropdown-content a {
  color: black;
  padding: 8px 16px;
  text-decoration: none;
  display: block;
  border-bottom: 1px solid rgb(197, 197, 197);
}
.Maindropdown-content a:hover {
  background-color: #1e88e5;
  color: #fff;
}
.Maindropdown:hover .Maindropdown-content {
  visibility: visible;
  opacity: 1;
  filter: alpha(opacity=100);
}
.Maindropdown:hover .Maindropbtn {
  background-color: #ffffff;
  color: #333;
  border: 1px solid #e3e3e3;
}

.TitleHistory {
  width: 100%;
  margin-bottom: 18px;
}
.TitleHistory span {
  font-weight: bold;
  padding-right: 5px;
}
.TitleHistory b {
  color: #3c9ac9;
  padding-left: 5px;
}

/* table */

.Table-scr {
  margin: 0;
  overflow-y: auto;
}
.Table-scr thead tr td {
  position: sticky;
  top: 0;
  border: 5px;
  z-index: 10;
}
.Table-scr thead tr td:after,
.Table-scr thead tr td:before {
  content: "";
  position: absolute;
  left: 0;
  width: 100%;
  height: 1px;
}
.Table-scr thead tr td:after {
  top: -1px;
}
.Table-scr thead tr td:before {
  bottom: -1px;
}
.Table-scr thead tr th {
  position: sticky;
  top: 0;
  border: 5px;
  z-index: 888;
}
.Table-scr thead tr th:after,
.Table-scr thead tr th:before {
  content: "";
  position: absolute;
  left: 0;
  width: 100%;
  height: 1px;
}
.Table-scr thead tr th:after {
  top: -1px;
}
.Table-scr thead tr th:before {
  bottom: -1px;
}

.Ch-TableA {
  border-spacing: 0px;
  width: 100%;
  border-top: 1px solid #dddddd;
}
.Ch-TableA thead tr th {
  font-size: 15px;
  font-family: "Noto Sans KR", sans-serif;
  font-weight: 600;
  text-align: center;
  padding: 2px 0 2px 0;
  color: #3c9ac9;
  background-color: #fafafa;
  border-right: 1px solid #dddddd;
  letter-spacing: 10px;
}
.Ch-TableA thead tr td {
  font-size: 13px;
  font-family: "Noto Sans KR", sans-serif;
  font-weight: 600;
  text-align: center;
  padding: 2px 0 2px 0;
  color: #333;
  background-color: #fafafa;
  border-bottom: 1px solid #dddddd;
}
.Ch-TableA tbody td {
  font-size: 13px;
  font-family: "Noto Sans KR", sans-serif;
  font-weight: 400;
  color: #333;
  padding: 7px 0;
  text-align: center;
}
.Ch-TableA tbody td {
  border-right: 1px solid #dddddd;
  border-bottom: 1px solid #dddddd;
}
.Ch-TableA thead tr th:nth-of-type(1) {
  border-right: 1px solid #dddddd;
  border-left: 1px solid #dddddd;
  border-bottom: 1px solid #dddddd;
}
.Ch-TableA thead tr td:nth-of-type(3) {
  border-right: 1px solid #dddddd;
}
.Ch-TableA thead tr td:nth-of-type(6) {
  border-right: 1px solid #dddddd;
}
.Ch-TableA thead tr td:nth-of-type(9) {
  border-right: 1px solid #dddddd;
}
.Ch-TableA thead tr td:nth-of-type(12) {
  border-right: 1px solid #dddddd;
}
.Ch-TableA thead tr td:nth-of-type(15) {
  border-right: 1px solid #dddddd;
}
.Ch-TableA thead tr td:nth-of-type(18) {
  border-right: 1px solid #dddddd;
}
.Ch-TableA thead tr td:nth-of-type(22) {
  border-right: 1px solid #dddddd;
}
.Ch-TableA tbody tr td:nth-of-type(1) {
  border-left: 1px solid #dddddd;
}
.Ch-TableA tbody tr:nth-of-type(even) {
  background: #fafafa;
}

.Ch-TableB {
  border-spacing: 0px;
  width: 100%;
}
.Ch-TableB tr th {
  text-align: left;
  padding: 19px 0 19px 15px;
  font-size: 14px;
  border-top: 1px solid #dddddd;
  vertical-align: bottom;
  font-weight: 500;
  background-color: white;
}
.Ch-TableB tr td:nth-of-type(1) {
  border-top: 1px solid #dddddd;
  text-align: right;
  font-weight: bold;
  font-size: 24px;
  padding-right: 10px;
  background-color: white;
}
.Ch-TableB tr td:nth-of-type(2) {
  border-top: 1px solid #dddddd;
  text-align: left;
  color: #c5c5c5;
  font-weight: bold;
  font-size: 15px;
  vertical-align: bottom;
  padding-bottom: 13px;
  background-color: white;
}

.Ch-TableC {
  border-spacing: 0px;
  width: 100%;
}
.Ch-TableC tr th {
  text-align: left;
  padding: 13px 0 13px 15px;
  font-size: 14px;
  border-top: 1px solid #dddddd;
  vertical-align: bottom;
  font-weight: 500;
  background-color: white;
}
.Ch-TableC tr td:nth-of-type(1) {
  border-top: 1px solid #dddddd;
  text-align: right;
  font-weight: bold;
  font-size: 24px;
  padding-right: 10px;
  background-color: white;
}
.Ch-TableC tr td:nth-of-type(2) {
  border-top: 1px solid #dddddd;
  text-align: left;
  color: #c5c5c5;
  font-weight: bold;
  font-size: 15px;
  vertical-align: bottom;
  padding-bottom: 13px;
  background-color: white;
}

/* .Ch-TableB, .Ch-TableC{background-color: #fff;} */

.Ch-TableD {
  margin: 0;
  padding: 0;
  border-spacing: 0px;
  float: left;
  border-left: 1px solid #dddddd;
  width: 100.2%;
  margin-left: -1px;
}
.Ch-TableD thead tr th {
  padding: 9px 0 8px 0;
  border-top: 1px solid #dddddd;
  border-bottom: 1px solid #dddddd;
  border-right: 1px solid #dddddd;
  background-color: #fafafa;
}
.Ch-TableD tbody tr th {
  text-align: center;
  padding: 6px 4px 6px 4px;
  font-size: 15px;
  vertical-align: bottom;
  font-weight: 500;
  border-bottom: 1px solid #dddddd;
  border-right: 1px solid #dddddd;
  background-color: #f5f5f5;
}
.Ch-TableD tbody tr td {
  border-bottom: 1px solid #dddddd;
  border-right: 1px solid #dddddd;
  text-align: right;
  font-weight: bold;
  font-size: 13px;
  padding-right: 10px;
  padding-left: 10px;
}
.Ch-TableD tbody tr td b {
  color: #999;
  padding-left: 5px;
}

.Ch-TableE {
  border-spacing: 0px;
  width: 100%;
}
.Ch-TableE tr th {
  width: 30%;
  text-align: left;
  padding: 19px 0 20px 15px;
}
.Ch-TableE tr th small {
  padding: 0px 10px 0px 0px;
}
.Ch-TableE tr th b {
  font-size: 40px;
}
.Ch-TableE tr th span {
  color: #c5c5c5;
  padding-left: 10px;
  font-size: 25px;
}
.Ch-TableE tr td {
  width: 20%;
  text-align: center;
}

.Ch-TableF {
  border-spacing: 0px;
  width: 100%;
  height: 223px;
}
.Ch-TableF tr td {
  text-align: center;
  width: 12.5%;
  padding-top: 15px;
}
.Ch-TableF tr td span {
  font-size: 15px;
  font-weight: 500;
}
.Ch-TableF tr td .donutCh {
  height: 165px;
  margin-top: 12px;
  padding: 3px;
}

.Ch-TableHBox {
  padding: 32px 15px 32px 0px;
  border-left: 1px solid #dddddd;
  background-color: #fafafa;
  margin-top: 1px;
}
.Ch-TableH {
  border-spacing: 0px;
  width: 100%;
}
.Ch-TableH tr th {
  font-size: 17px;
}
.Ch-TableH tr th div {
  height: 16px;
}
.Ch-TableH tr td:nth-of-type(1) {
  border-left: 1px solid #dddddd;
  border-top: 1px solid #dddddd;
  border-bottom: 1px solid #dddddd;
  background-color: #fff;
  text-align: right;
  font-size: 17px;
  width: 40%;
  padding: 5px 5px 5px 0;
}
.Ch-TableH tr td:nth-of-type(2) {
  border-right: 1px solid #dddddd;
  border-top: 1px solid #dddddd;
  border-bottom: 1px solid #dddddd;
  background-color: #fff;
  text-align: left;
  padding: 5px;
  color: #c5c5c5;
}

.Ch-TableI {
  border-spacing: 0px;
  width: 100%;
}
.Ch-TableI thead tr th {
  padding: 10px;
  font-size: 15px;
  background-color: #fafafa;
  border-bottom: 1px solid #dddddd;
  border-right: 1px solid #dddddd;
}
.Ch-TableI tbody tr td {
  text-align: center;
  font-size: 15px;
  padding: 10px;
  border-bottom: 1px solid #dddddd;
  border-right: 1px solid #dddddd;
}
.Ch-TableI tbody tr th {
  text-align: right;
  font-weight: normal;
  font-size: 15px;
  padding: 10px;
  border-bottom: 1px solid #dddddd;
  border-right: 1px solid #dddddd;
}
.Ch-TableI tbody tr td:nth-of-type(5) {
  text-align: left;
}
.Ch-TableI tbody tr:nth-of-type(even) {
  background: #fafafa;
}

.FourGridT {
  text-align: center;
  line-height: 37px;
  padding: 15px 0;
}
.FourGridT span.FourTT {
  color: #333;
  font-size: 19px;
}
.FourGridT span.FourDa {
  color: #9090a7;
  font-size: 17px;
  padding-left: 10px;
}
.FourGridT b {
  color: #82b440;
  font-size: 30px;
  font-weight: 500;
}

/* / table */

/* toolbar */
.titleTbarWimg img {
  width: 35px;
  padding-bottom: 5px;
  padding-right: 10px;
}
.titleTbar {
  padding-left: 15px;
  font-weight: 600;
  font-size: 15px;
  color: #333;
}
.titleTbar img {
  width: 15px;
}

/* Btn */
.ch-btnA {
  background: #fff;
  border: 1px solid #dddddd;
  border-radius: 5px;
  padding: 4px 15px;
  font-weight: bold;
  text-align: center;
  text-shadow: none;
  text-transform: uppercase;
  -webkit-transition: all 280ms ease;
  transition: all 280ms ease;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  cursor: pointer;
  -webkit-appearance: none;
  display: inline-block;
}
.ch-btnA:hover,
.ch-btnA:focus,
.ch-btnA:active {
  background: #fff;
  outline: none;
  text-decoration: none;
  color: #212121;
  -webkit-box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16),
    0 3px 6px rgba(0, 0, 0, 0.23);
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.ch-btnB {
  border: none;
  text-align: center;
  text-shadow: none;
  color: #999;
  box-sizing: border-box;
  cursor: pointer;
  display: inline-block;
}
.ch-btnB i {
  color: #999;
}
.ch-btnB:hover,
.ch-btnB:focus,
.ch-btnB:active {
  outline: none;
  color: #333;
}

.IconBtn {
  border-radius: 4px;
  padding: 3px 10px;
  text-align: center;
  text-shadow: none;
  text-transform: uppercase;
  -webkit-transition: all 280ms ease;
  transition: all 280ms ease;
  color: #333;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  cursor: pointer;
  -webkit-appearance: none;
  display: inline-block;
  background-color: #fff;
  margin-top: -5px;
}
.IconBtn img {
  padding-right: 4px;
  width: 16px;
}

/* Btn */

/* checkbox / radio */
.checkboxBox {
  margin: 20px 0 0px 10px;
}

input[type="checkbox"] {
  display: none;
}
input[type="checkbox"] + label {
  color: #333;
  font-family: "Noto Sans KR", sans-serif;
  font-weight: 500;
}
input[type="checkbox"] + label span {
  display: inline-block;
  width: 19px;
  height: 19px;
  margin: -2px 5px 0 0;
  vertical-align: middle;
  /* background: url("/images/check_radio_sheet.png") left top no-repeat; */
  cursor: pointer;
}
input[type="checkbox"]:checked + label span {
  /* background: url("/images/check_radio_sheet.png") -19px top no-repeat; */
}
input[type="radio"] {
  display: none;
}
input[type="radio"] + label {
  color: #333;
}
input[type="radio"] + label span {
  display: inline-block;
  width: 19px;
  height: 19px;
  margin: -2px 10px 0 0;
  vertical-align: middle;
  /* background: url("/images/check_radio_sheet.png") -38px top no-repeat; */
  cursor: pointer;
}
input[type="radio"]:checked + label span {
  /* background: url("/images/check_radio_sheet.png") -57px top no-repeat; */
}
/* tabs */
.JinTab {
  overflow: hidden;
  padding: 0;
}
.JinTab li {
  float: left;
  font-size: 15px;
  text-transform: uppercase;
  color: #333;
  border: 1px solid transparent;
  font-weight: 500;
  min-width: 100px;
  max-width: 100%;
  text-align: center;
  -webkit-transition: all 220ms cubic-bezier(0.4, 0, 0.2, 1);
  transition: all 220ms cubic-bezier(0.4, 0, 0.2, 1);
  padding: 14px 16px !important;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  position: relative;
  top: 1px;
  text-shadow: none;
  margin-right: 5px;
}
.JinTab li {
  display: inline-block;
  cursor: pointer;
  color: #6db6ee;
}
.JinTab li.on {
  color: #333;
  border: 1px solid #dddddd;
  background-color: #fff;
}
.JinTab_con {
  clear: both;
  margin-top: -16px;
  border: 1px solid #dddddd;
  text-align: center;
  font-family: "Noto Sans KR", sans-serif;
  font-weight: 400;
}
.JinTab_con dd {
  display: none;
}
.tabBtn {
  text-align: right;
  padding-right: 10px;
}

/* timeline */
.timeline {
  position: relative;
  min-height: 40px;
  padding: 8px 0 8px 62px;
}
.timeline,
.timeline:before,
.timeline:after,
.timeline *,
.timeline *:before,
.timeline *:after {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.timeline:before {
  position: absolute;
  left: 100px;
  top: 0;
  bottom: 0;
  width: 2px;
  background: #dddddd;
  content: "";
  display: block;
}
.timeline_item {
  position: relative;
  min-height: 30px;
  padding: 5px 0;
}
.timeline_icon {
  position: absolute;
  left: 34px;
  top: 17px;
  height: 10px;
  width: 10px;
  border-radius: 50%;
  background: #dddddd;
  text-align: center;
}
.timeline_date {
  position: absolute;
  left: -40px;
  float: left;
  min-width: 60px;
  color: #333;
  text-align: center;
  line-height: 18px;
  font-size: 16px;
  margin-right: 8px;
  padding-top: 7px;
}
.timeline_dateBB {
  position: absolute;
  left: -40px;
  float: left;
  min-width: 60px;
  color: #333;
  text-align: center;
  line-height: 15px;
  font-size: 16px;
  margin-right: 8px;
}
.timeline_content {
  padding: 6px 4px 8px;
  margin-left: 55px;
  overflow: hidden;
  color: #333;
  font-weight: 500;
  font-size: 15px;
}
.timeline_content .TcSpace {
  padding: 0 2px;
}
/* / timeline */

/* search */
.searchSubTitleA {
  padding: 5px 10px 0 10px;
  color: #333;
}
.searchSubTitleB {
  padding: 5px 10px 0 20px;
  color: #333;
}
.searchSubTitleC {
  top: 5px;
  margin-left: 7px;
}
.searchSubTitleC a.ch-btnA {
  padding: 4px 12px;
  vertical-align: -2px;
}
.searchTerm {
  font-size: 14px;
  border-radius: 4px;
  border: 1px solid #e4e4e4;
  padding: 5px 4px;
  color:#333;
  vertical-align: -2px;
}

/* DropDown */
.Searchdropdown {
  position: relative;
  display: inline-block;
  z-index: 999;
  vertical-align: -2px;
}
.Searchdropbtn {
  background-color: #fff;
  color: #333;
  padding: 4px 24px;
  border: none;
  cursor: pointer;
  font-size: 14px;
  border-radius: 4px;
  border: 1px solid #e4e4e4;
  height: 31px;
}
.Searchdropbtn i {
  color: #333;
}
.Searchdropdown-content {
  position: absolute;
  left: 0;
  top: 29px;
  z-index: 999;
  visibility: hidden;
  opacity: 0;
  filter: alpha(opacity=0);
  -webkit-transition: 200ms ease;
  -moz-transition: 200ms ease;
  -o-transition: 200ms ease;
  transition: 200ms ease;
  background-color: #fff;
  background-color: #fff;
  border: 1px solid #e3e3e3;
  border-top: none;
}
.Searchdropdown-content a {
  color: black;
  padding: 6px 12px;
  text-decoration: none;
  display: block;
  border-bottom: 1px solid rgb(197, 197, 197);
}
.Searchdropdown-content a:hover {
  background-color: #4f80ff;
  color: #fff;
}
.Searchdropdown:hover .Searchdropdown-content {
  visibility: visible;
  opacity: 1;
  filter: alpha(opacity=100);
}
.Searchdropdown:hover .Searchdropbtn {
  background-color: #ffffff;
  color: #333;
  border: 1px solid #e3e3e3;
}
/* DropDown */

/*jin*/

.scroolY {
  overflow-y: scroll;
  width: 100%;
}
.chartTitle {
  font-weight: 500;
  font-size: 17px;
  padding: 15px 0 5px 15px;
}
.ch-padding {
  padding: 40px;
  height: 366px;
}

/*jin*/

/* map */
.chmapTBox {
  border: 1px solid #e06c5f;
  height: 790px;
  margin: 40px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
.chmapTT {
  background-color: #e06c5f;
  text-align: center;
  font-weight: 500;
  color: #fff;
  font-size: 18px;
  padding: 8px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}
.chmapTable-A {
  border-spacing: 0px;
  width: 100%;
  padding: 10px 10px 0 10px;
}
.chmapTable-A tr td {
  width: 50%;
  text-align: left;
  padding-bottom: 5px;
}
.MapSearch {
  display: inline-block;
  vertical-align: middle;
  width: 70px;
}
.MapSearchTerm {
  border: solid 1px #e3e3e3;
  /* border-right: none; */
  height: 21px;
  padding: 4px;
  outline: none;
  color: black;
  border-radius: 5px 5px 5px 5px;
  /* padding-right:35px; */
  /* width:162px; */
}
.MapSearchBtn {
  height: 29px;
  border: solid 1px #e3e3e3;;
  background: #fff;
  text-align: center;
  border-radius: 5px 5px 5px 5px;
  cursor: pointer;
  font-size: 14px;
  width: 100%;
  background-color:#eeeeee;
}

.MapSearchBtn i {
  color: #333;
}

/* Mapdropdown */
.Mapdropdown {
  position: relative;
  display: inline-block;
}
.Mapdropbtn {
  background-color: #fff;
  color: #333;
  padding: 5px 8px;
  cursor: pointer;
  font-size: 14px;
  border-radius: 5px;
  border: 1px solid #e3e3e3;
  width: 202px;
  text-align: left;
}
.MapdropbtnIcon {
  position: absolute;
  color: #333;
  margin-left: 175px;
  margin-top: -26px;
}
.Mapdropdown-content {
  position: absolute;
  left: 0px;
  top: 28px;
  z-index: 88;
  visibility: hidden;
  opacity: 0;
  filter: alpha(opacity=0);
  -webkit-transition: 200ms ease;
  -moz-transition: 200ms ease;
  -o-transition: 200ms ease;
  transition: 200ms ease;
  background-color: #fff;
  border: 1px solid #e3e3e3;
  border-top: none;
}
.Mapdropdown-content a {
  color: #333;
  padding: 8px 16px;
  text-decoration: none;
  display: block;
  border-bottom: 1px solid rgb(197, 197, 197);
}
.Mapdropdown-content a:hover {
  background-color: #e06c5f;
  color: #fff;
}
.Mapdropdown:hover .Mapdropdown-content {
  visibility: visible;
  opacity: 1;
  filter: alpha(opacity=100);
}
.Mapdropdown:hover .Mapdropbtn {
  background-color: #ffffff;
  color: #333;
  border: 1px solid #e3e3e3;
}

.chmapTable-BC {
  border-top: 1px solid gainsboro;
  height: 550px;
  width: 420px;
  margin: 10px 0 0 0;
}
.chmapTable-B {
  border-spacing: 0px;
  width: 100%;
  padding: 0px 0px 0 10px;
}
.chmapTable-B thead th {
  background-color: #eeeeee;
  border-bottom: 1px solid #dddddd;
  text-align: left;
  font-size: 16px;
  font-weight: 500;
  padding: 8px 0 11px 8px;
}
.chmapTable-B thead td {
  background-color: #eeeeee;
  border-bottom: 1px solid #dddddd;
  text-align: right;
  padding: 7px 10px 0px 0px;
}
.chmapTable-B tbody th {
  background-color: #eeeeee;
  border-bottom: 1px solid #dddddd;
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  padding: 6px 0 6px 0;
  width: 79px;
  border-right: 1px solid #dddddd;
}
.chmapTable-B tbody td {
  border-bottom: 1px solid #dddddd;
  text-align: center;
  padding: 4px;
}
.chmapTable-B tbody td span {
  color: #c5c5c5;
  padding: 0 7px 7px 0;
}
.chmapTable-B tbody td label {
  font-size: 15px;
  padding-left: 12px;
}

.chmapTable-C {
  border-spacing: 0px;
  width: 100%;
  padding: 0 0px 10px 10px;
}
.chmapTable-C tr th {
  background-color: #eeeeee;
  border-bottom: 1px solid #dddddd;
  text-align: center;
  /* padding: 7px 10px 0px 0px; */
  width: 70px;
}
.chmapTable-C tr td {
  border-bottom: 1px solid #dddddd;
  text-align: right;
  font-size: 14px;
  font-weight: 500;
  padding: 6px 10px 6px 0;
  
}
.chmapTable-C tr th.linetitle {
  text-align: center;
  border-right: 1px solid #dddddd;
  width: 77px;
}

.chmapTable-D {
  border-spacing: 0px;
  width: 100%;
}
.chmapTable-D thead tr th {
  background-color: #fafafa;
  border-bottom: 1px solid #dddddd;
  text-align: left;
  font-size: 16px;
  font-weight: 500;
  padding: 8px 0 11px 8px;
}
.chmapTable-D thead tr td {
  background-color: #fafafa;
  text-align: right;
  padding-right: 10px;
  border-bottom: 1px solid #dddddd;
}
.chmapTable-D tbody th {
  border-bottom: 1px solid #dddddd;
}
.chmapTable-D tbody tr td:nth-of-type(1) {
  background-color: #eeeeee;
  border-bottom: 1px solid #dddddd;
  text-align: left;
  font-size: 14px;
  font-weight: 500;
  padding: 6px 0 6px 7px;
  width: 110px;
  border-right: 1px solid #dddddd;
}
.chmapTable-D tbody tr td:nth-of-type(2) {
  text-align: right;
  color: #ee7951;
  padding-right: 15px;
  border-bottom: 1px solid #dddddd;
}
.chmapTable-D tbody tr td span {
  color: #a9a9a9;
  padding-left: 10px;
}

.Mappagination {
  margin: 0 12px 0 10px;
}

.Ch-Map {
  margin-top: 70px;
}
.ChMapImg {
  margin-left: 100px;
  position: absolute;
  z-index: 2;
}
.ChMapTb-A {
  margin-left: 1015px;
  margin-top: -50px;
  width: 180px;
  position: absolute;
  z-index: 10;
  background-color: #fafafa;
  border: 1px solid #dddddd;
  padding: 10px;
}
.ChMapTb-A table tr td {
  font-weight: 500;
  padding: 2px 0 2px 0;
}
.ChMapTb-A table tr td img {
  width: 12px;
  vertical-align: -1px;
}
.ChMapTb-B {
  margin-left: 1015px;
  margin-top: 140px;
  width: 180px;
  position: absolute;
  z-index: 10;
  background-color: #fafafa;
  border: 1px solid #dddddd;
  padding: 10px;
}
.ChMapTb-B table tr td {
  font-weight: 500;
  padding: 2px 0 2px 0;
}
.ChMapTb-B table tr td img {
  width: 12px;
  vertical-align: -1px;
}

.ChMapTb-C {
  margin-left: 300px;
  margin-top: 300px;
  position: absolute;
  z-index: 10;
  border: 1px solid #dddddd;
  width: 500px;
  -webkit-box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16),
    0 3px 6px rgba(0, 0, 0, 0.23);
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}
.ChMapTb-C table {
  border-spacing: 0px;
  width: 100%;
}
.ChMapTb-C table thead tr th {
  background-color: #fff;
  text-align: left;
  padding: 7px 0 7px 10px;
  border-bottom: 1px solid #dddddd;
}
.ChMapTb-C table thead tr td {
  background-color: #fff;
  padding: 7px 10px 7px 10px;
  border-bottom: 1px solid #dddddd;
  text-align: right;
}
.ChMapTb-C table thead tr th:nth-of-type(2) {
  border-left: 1px solid #dddddd;
  width: 330px;
}
.ChMapTb-C table tbody tr th {
  background-color: #eeeeee;
  text-align: left;
  padding: 7px 0 7px 10px;
  border-bottom: 1px solid #dddddd;
}
.ChMapTb-C table tbody tr td {
  background-color: #fff;
  padding: 7px 10px 7px 10px;
  border-bottom: 1px solid #dddddd;
  text-align: left;
}

/* 지도 위치값 */
.ChMapPin-01 {
  margin-left: 0px;
  margin-top: 100px;
}
.ChMapPin-02 {
  margin-left: 130px;
  margin-top: 100px;
}
.ChMapPin-03 {
  margin-left: 270px;
  margin-top: 30px;
}
.ChMapPin-04 {
  margin-left: 390px;
  margin-top: 80px;
}
.ChMapPin-05 {
  margin-left: 530px;
  margin-top: 20px;
}
.ChMapPin-06 {
  margin-left: 170px;
  margin-top: 260px;
}
.ChMapPin-07 {
  margin-left: 300px;
  margin-top: 180px;
}
.ChMapPin-08 {
  margin-left: 460px;
  margin-top: 300px;
}
.ChMapPin-09 {
  margin-left: 220px;
  margin-top: 470px;
}
.ChMapPin-10 {
  margin-left: 375px;
  margin-top: 370px;
}
.ChMapPin-11 {
  margin-left: 220px;
  margin-top: 600px;
}
.ChMapPin-12 {
  margin-left: 370px;
  margin-top: 470px;
}
.ChMapPin-13 {
  margin-left: 510px;
  margin-top: 550px;
}
.ChMapPin-14 {
  margin-left: 580px;
  margin-top: 460px;
}
.ChMapPin-15 {
  margin-left: 700px;
  margin-top: 550px;
}
/* 지도 위치값 */

/* 크기 컬러 A-F */
.ChMapBg-AA {
  width: 140px;
  height: 140px;
  border: 17px solid #205f257c;
  border-radius: 50%;
  text-align: center;
  position: absolute;
  z-index: 3;
}
.ChMapBg-AABg {
  width: 140px;
  height: 140px;
  background-color: #205f25d8;
  border-radius: 50%;
  color: #fff;
  font-weight: 500;
  font-size: 32px;
  text-align: center;
  position: absolute;
  z-index: 4;
  line-height: 140px;
}

.ChMapBg-AB {
  width: 140px;
  height: 140px;
  border: 17px solid #277b3f7c;
  border-radius: 50%;
  text-align: center;
  position: absolute;
  z-index: 3;
}
.ChMapBg-ABBg {
  width: 140px;
  height: 140px;
  background-color: #277b3fd8;
  border-radius: 50%;
  color: #fff;
  font-weight: 500;
  font-size: 32px;
  text-align: center;
  position: absolute;
  z-index: 4;
  line-height: 140px;
}

.ChMapBg-AC {
  width: 140px;
  height: 140px;
  border: 17px solid #4da5507c;
  border-radius: 50%;
  text-align: center;
  position: absolute;
  z-index: 3;
}
.ChMapBg-ACBg {
  width: 140px;
  height: 140px;
  background-color: #4da550d8;
  border-radius: 50%;
  color: #fff;
  font-weight: 500;
  font-size: 32px;
  text-align: center;
  position: absolute;
  z-index: 4;
  line-height: 140px;
}

.ChMapBg-AD {
  width: 140px;
  height: 140px;
  border: 17px solid #85c9607c;
  border-radius: 50%;
  text-align: center;
  position: absolute;
  z-index: 3;
}
.ChMapBg-ADBg {
  width: 140px;
  height: 140px;
  background-color: #85c960d8;
  border-radius: 50%;
  color: #fff;
  font-weight: 500;
  font-size: 32px;
  text-align: center;
  position: absolute;
  z-index: 4;
  line-height: 140px;
}

.ChMapBg-AE {
  width: 140px;
  height: 140px;
  border: 17px solid #b3de6a7c;
  border-radius: 50%;
  text-align: center;
  position: absolute;
  z-index: 3;
}
.ChMapBg-AEBg {
  width: 140px;
  height: 140px;
  background-color: #b3de6ad8;
  border-radius: 50%;
  color: #fff;
  font-weight: 500;
  font-size: 32px;
  text-align: center;
  position: absolute;
  z-index: 4;
  line-height: 140px;
}

.ChMapBg-AF {
  width: 140px;
  height: 140px;
  border: 17px solid #d1eb7c7c;
  border-radius: 50%;
  text-align: center;
  position: absolute;
  z-index: 3;
}
.ChMapBg-AFBg {
  width: 140px;
  height: 140px;
  background-color: #d1eb7cd8;
  border-radius: 50%;
  color: #fff;
  font-weight: 500;
  font-size: 32px;
  text-align: center;
  position: absolute;
  z-index: 4;
  line-height: 140px;
}

.ChMapBg-BA {
  width: 130px;
  height: 130px;
  border: 15px solid #205f257c;
  border-radius: 50%;
  text-align: center;
  position: absolute;
  z-index: 3;
}
.ChMapBg-BABg {
  width: 130px;
  height: 130px;
  background-color: #205f25d8;
  border-radius: 50%;
  color: #fff;
  font-weight: 500;
  font-size: 30px;
  text-align: center;
  position: absolute;
  z-index: 4;
  line-height: 130px;
}

.ChMapBg-BB {
  width: 130px;
  height: 130px;
  border: 15px solid #277b3f7c;
  border-radius: 50%;
  text-align: center;
  position: absolute;
  z-index: 3;
}
.ChMapBg-BBBg {
  width: 130px;
  height: 130px;
  background-color: #277b3fd8;
  border-radius: 50%;
  color: #fff;
  font-weight: 500;
  font-size: 30px;
  text-align: center;
  position: absolute;
  z-index: 4;
  line-height: 130px;
}

.ChMapBg-BC {
  width: 130px;
  height: 130px;
  border: 15px solid #4da5507c;
  border-radius: 50%;
  text-align: center;
  position: absolute;
  z-index: 3;
}
.ChMapBg-BCBg {
  width: 130px;
  height: 130px;
  background-color: #4da550d8;
  border-radius: 50%;
  color: #fff;
  font-weight: 500;
  font-size: 30px;
  text-align: center;
  position: absolute;
  z-index: 4;
  line-height: 130px;
}

.ChMapBg-BD {
  width: 130px;
  height: 130px;
  border: 15px solid #85c9607c;
  border-radius: 50%;
  text-align: center;
  position: absolute;
  z-index: 3;
}
.ChMapBg-BDBg {
  width: 130px;
  height: 130px;
  background-color: #85c960d8;
  border-radius: 50%;
  color: #fff;
  font-weight: 500;
  font-size: 30px;
  text-align: center;
  position: absolute;
  z-index: 4;
  line-height: 130px;
}

.ChMapBg-BE {
  width: 130px;
  height: 130px;
  border: 15px solid #b3de6a7c;
  border-radius: 50%;
  text-align: center;
  position: absolute;
  z-index: 3;
}
.ChMapBg-BEBg {
  width: 130px;
  height: 130px;
  background-color: #b3de6ad8;
  border-radius: 50%;
  color: #fff;
  font-weight: 500;
  font-size: 30px;
  text-align: center;
  position: absolute;
  z-index: 4;
  line-height: 130px;
}

.ChMapBg-BF {
  width: 130px;
  height: 130px;
  border: 15px solid #d1eb7c7c;
  border-radius: 50%;
  text-align: center;
  position: absolute;
  z-index: 3;
}
.ChMapBg-BFBg {
  width: 130px;
  height: 130px;
  background-color: #d1eb7cd8;
  border-radius: 50%;
  color: #fff;
  font-weight: 500;
  font-size: 30px;
  text-align: center;
  position: absolute;
  z-index: 4;
  line-height: 130px;
}

.ChMapBg-CA {
  width: 110px;
  height: 110px;
  border: 13px solid #205f257c;
  border-radius: 50%;
  text-align: center;
  position: absolute;
  z-index: 3;
}
.ChMapBg-CABg {
  width: 110px;
  height: 110px;
  background-color: #205f25d8;
  border-radius: 50%;
  color: #fff;
  font-weight: 500;
  font-size: 22px;
  text-align: center;
  position: absolute;
  z-index: 4;
  line-height: 110px;
}

.ChMapBg-CB {
  width: 110px;
  height: 110px;
  border: 13px solid #277b3f7c;
  border-radius: 50%;
  text-align: center;
  position: absolute;
  z-index: 3;
}
.ChMapBg-CBBg {
  width: 110px;
  height: 110px;
  background-color: #277b3fd8;
  border-radius: 50%;
  color: #fff;
  font-weight: 500;
  font-size: 22px;
  text-align: center;
  position: absolute;
  z-index: 4;
  line-height: 110px;
}

.ChMapBg-CC {
  width: 110px;
  height: 110px;
  border: 13px solid #4da5507c;
  border-radius: 50%;
  text-align: center;
  position: absolute;
  z-index: 3;
}
.ChMapBg-CCBg {
  width: 110px;
  height: 110px;
  background-color: #4da550d8;
  border-radius: 50%;
  color: #fff;
  font-weight: 500;
  font-size: 22px;
  text-align: center;
  position: absolute;
  z-index: 4;
  line-height: 110px;
}

.ChMapBg-CD {
  width: 110px;
  height: 110px;
  border: 13px solid #85c9607c;
  border-radius: 50%;
  text-align: center;
  position: absolute;
  z-index: 3;
}
.ChMapBg-CDBg {
  width: 110px;
  height: 110px;
  background-color: #85c960d8;
  border-radius: 50%;
  color: #fff;
  font-weight: 500;
  font-size: 22px;
  text-align: center;
  position: absolute;
  z-index: 4;
  line-height: 110px;
}

.ChMapBg-CE {
  width: 110px;
  height: 110px;
  border: 13px solid #b3de6a7c;
  border-radius: 50%;
  text-align: center;
  position: absolute;
  z-index: 3;
}
.ChMapBg-CEBg {
  width: 110px;
  height: 110px;
  background-color: #b3de6ad8;
  border-radius: 50%;
  color: #fff;
  font-weight: 500;
  font-size: 22px;
  text-align: center;
  position: absolute;
  z-index: 4;
  line-height: 110px;
}

.ChMapBg-CF {
  width: 110px;
  height: 110px;
  border: 13px solid #d1eb7c7c;
  border-radius: 50%;
  text-align: center;
  position: absolute;
  z-index: 3;
}
.ChMapBg-CFBg {
  width: 110px;
  height: 110px;
  background-color: #d1eb7cd8;
  border-radius: 50%;
  color: #fff;
  font-weight: 500;
  font-size: 22px;
  text-align: center;
  position: absolute;
  z-index: 4;
  line-height: 110px;
}

.ChMapBg-DA {
  width: 100px;
  height: 100px;
  border: 13px solid #205f257c;
  border-radius: 50%;
  text-align: center;
  position: absolute;
  z-index: 3;
}
.ChMapBg-DABg {
  width: 100px;
  height: 100px;
  background-color: #205f25d8;
  border-radius: 50%;
  color: #fff;
  font-weight: 500;
  font-size: 20px;
  text-align: center;
  position: absolute;
  z-index: 4;
  line-height: 100px;
}

.ChMapBg-DB {
  width: 100px;
  height: 100px;
  border: 13px solid #277b3f7c;
  border-radius: 50%;
  text-align: center;
  position: absolute;
  z-index: 3;
}
.ChMapBg-DBBg {
  width: 100px;
  height: 100px;
  background-color: #277b3fd8;
  border-radius: 50%;
  color: #fff;
  font-weight: 500;
  font-size: 20px;
  text-align: center;
  position: absolute;
  z-index: 4;
  line-height: 100px;
}

.ChMapBg-DC {
  width: 100px;
  height: 100px;
  border: 13px solid #4da5507c;
  border-radius: 50%;
  text-align: center;
  position: absolute;
  z-index: 3;
}
.ChMapBg-DCBg {
  width: 100px;
  height: 100px;
  background-color: #4da550d8;
  border-radius: 50%;
  color: #fff;
  font-weight: 500;
  font-size: 20px;
  text-align: center;
  position: absolute;
  z-index: 4;
  line-height: 100px;
}

.ChMapBg-DD {
  width: 100px;
  height: 100px;
  border: 13px solid #85c9607c;
  border-radius: 50%;
  text-align: center;
  position: absolute;
  z-index: 3;
}
.ChMapBg-DDBg {
  width: 100px;
  height: 100px;
  background-color: #85c960d8;
  border-radius: 50%;
  color: #fff;
  font-weight: 500;
  font-size: 20px;
  text-align: center;
  position: absolute;
  z-index: 4;
  line-height: 100px;
}

.ChMapBg-DE {
  width: 100px;
  height: 100px;
  border: 13px solid #b3de6a7c;
  border-radius: 50%;
  text-align: center;
  position: absolute;
  z-index: 3;
}
.ChMapBg-DEBg {
  width: 100px;
  height: 100px;
  background-color: #b3de6ad8;
  border-radius: 50%;
  color: #fff;
  font-weight: 500;
  font-size: 20px;
  text-align: center;
  position: absolute;
  z-index: 4;
  line-height: 100px;
}

.ChMapBg-DF {
  width: 100px;
  height: 100px;
  border: 13px solid #d1eb7c7c;
  border-radius: 50%;
  text-align: center;
  position: absolute;
  z-index: 3;
}
.ChMapBg-DFBg {
  width: 100px;
  height: 100px;
  background-color: #d1eb7cd8;
  border-radius: 50%;
  color: #fff;
  font-weight: 500;
  font-size: 20px;
  text-align: center;
  position: absolute;
  z-index: 4;
  line-height: 100px;
}

.ChMapBg-EA {
  width: 80px;
  height: 80px;
  border: 12px solid #205f257c;
  border-radius: 50%;
  text-align: center;
  position: absolute;
  z-index: 3;
}
.ChMapBg-EABg {
  width: 80px;
  height: 80px;
  background-color: #205f25d8;
  border-radius: 50%;
  color: #fff;
  font-weight: 500;
  font-size: 17px;
  text-align: center;
  position: absolute;
  z-index: 4;
  line-height: 80px;
}

.ChMapBg-EB {
  width: 80px;
  height: 80px;
  border: 12px solid #277b3f7c;
  border-radius: 50%;
  text-align: center;
  position: absolute;
  z-index: 3;
}
.ChMapBg-EBBg {
  width: 80px;
  height: 80px;
  background-color: #277b3fd8;
  border-radius: 50%;
  color: #fff;
  font-weight: 500;
  font-size: 17px;
  text-align: center;
  position: absolute;
  z-index: 4;
  line-height: 80px;
}

.ChMapBg-EC {
  width: 80px;
  height: 80px;
  border: 12px solid #4da5507c;
  border-radius: 50%;
  text-align: center;
  position: absolute;
  z-index: 3;
}
.ChMapBg-ECBg {
  width: 80px;
  height: 80px;
  background-color: #4da550d8;
  border-radius: 50%;
  color: #fff;
  font-weight: 500;
  font-size: 17px;
  text-align: center;
  position: absolute;
  z-index: 4;
  line-height: 80px;
}

.ChMapBg-ED {
  width: 80px;
  height: 80px;
  border: 12px solid #85c9607c;
  border-radius: 50%;
  text-align: center;
  position: absolute;
  z-index: 3;
}
.ChMapBg-EDBg {
  width: 80px;
  height: 80px;
  background-color: #85c960d8;
  border-radius: 50%;
  color: #fff;
  font-weight: 500;
  font-size: 17px;
  text-align: center;
  position: absolute;
  z-index: 4;
  line-height: 80px;
}

.ChMapBg-EE {
  width: 80px;
  height: 80px;
  border: 12px solid #b3de6a7c;
  border-radius: 50%;
  text-align: center;
  position: absolute;
  z-index: 3;
}
.ChMapBg-EEBg {
  width: 80px;
  height: 80px;
  background-color: #b3de6ad8;
  border-radius: 50%;
  color: #fff;
  font-weight: 500;
  font-size: 17px;
  text-align: center;
  position: absolute;
  z-index: 4;
  line-height: 80px;
}

.ChMapBg-EF {
  width: 80px;
  height: 80px;
  border: 12px solid #d1eb7c7c;
  border-radius: 50%;
  text-align: center;
  position: absolute;
  z-index: 3;
}
.ChMapBg-EFBg {
  width: 80px;
  height: 80px;
  background-color: #d1eb7cd8;
  border-radius: 50%;
  color: #fff;
  font-weight: 500;
  font-size: 17px;
  text-align: center;
  position: absolute;
  z-index: 4;
  line-height: 80px;
}

.ChMapBg-FA {
  width: 60px;
  height: 60px;
  /* border: 10px solid #205f257c; */
  border-radius: 50%;
  text-align: center;
  position: absolute;
  z-index: 3;
}
.ChMapBg-FABg {
  width: 60px;
  height: 60px;
  background-color: #205f25d8;
  border-radius: 50%;
  color: #fff;
  font-weight: 500;
  font-size: 17px;
  text-align: center;
  position: absolute;
  z-index: 4;
  line-height: 60px;
}

.ChMapBg-FB {
  width: 60px;
  height: 60px;
  /* border: 10px solid #277b3f7c; */
  border-radius: 50%;
  text-align: center;
  position: absolute;
  z-index: 3;
}
.ChMapBg-FBBg {
  width: 60px;
  height: 60px;
  background-color: #277b3fd8;
  border-radius: 50%;
  color: #fff;
  font-weight: 500;
  font-size: 17px;
  text-align: center;
  position: absolute;
  z-index: 4;
  line-height: 60px;
}

.ChMapBg-FC {
  width: 60px;
  height: 60px;
  /* border: 10px solid #4da5507c; */
  border-radius: 50%;
  text-align: center;
  position: absolute;
  z-index: 3;
}
.ChMapBg-FCBg {
  width: 60px;
  height: 60px;
  background-color: #4da550d8;
  border-radius: 50%;
  color: #fff;
  font-weight: 500;
  font-size: 17px;
  text-align: center;
  position: absolute;
  z-index: 4;
  line-height: 60px;
}

.ChMapBg-FD {
  width: 60px;
  height: 60px;
  /* border: 10px solid #85c9607c; */
  border-radius: 50%;
  text-align: center;
  position: absolute;
  z-index: 3;
}
.ChMapBg-FDBg {
  width: 60px;
  height: 60px;
  background-color: #85c960d8;
  border-radius: 50%;
  color: #fff;
  font-weight: 500;
  font-size: 17px;
  text-align: center;
  position: absolute;
  z-index: 4;
  line-height: 60px;
}

.ChMapBg-FE {
  width: 60px;
  height: 60px;
  /* border: 10px solid #b3de6a7c; */
  border-radius: 50%;
  text-align: center;
  position: absolute;
  z-index: 3;
}
.ChMapBg-FEBg {
  width: 60px;
  height: 60px;
  background-color: #b3de6ad8;
  border-radius: 50%;
  color: #fff;
  font-weight: 500;
  font-size: 17px;
  text-align: center;
  position: absolute;
  z-index: 4;
  line-height: 60px;
}

.ChMapBg-FF {
  width: 60px;
  height: 60px;
  /* border: 10px solid #d1eb7c7c; */
  border-radius: 50%;
  text-align: center;
  position: absolute;
  z-index: 3;
}
.ChMapBg-FFBg {
  width: 60px;
  height: 60px;
  background-color: #d1eb7cd8;
  border-radius: 50%;
  color: #fff;
  font-weight: 500;
  font-size: 17px;
  text-align: center;
  position: absolute;
  z-index: 4;
  line-height: 60px;
}
/* map */

/* color */
.textcoR {
  color: #ee7951;
}
.textcoB {
  color: #3c9ac9;
}
.textcoG {
  color: #82b440;
}
.TBgA {
  background-color: white;
  border-radius: 0px;
}
.textGre {
  color: #05c270;
}
.textGry {
  color: #9090a7;
}
.textBl {
  color: #0e65ec;
}
.textYl {
  color: #f9cc13;
}
.textRd {
  color: #ff3b3b;
}

.loadingSpinner{
  position: absolute;
  z-index: 10003;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%)
}
/* / color */

.autoFitDropDown{
  min-width: 174px;
  width: auto !important;
}
tspan{
  pointer-events: none;
}