.weathCmp .k-grid thead,
.k-grid tbody,
.k-grid tfoot {
  text-align: center;
  font-family: "Noto Sans KR", sans-serif;
  line-height: 25px;
}
.weathCmp .k-grid-header {
  line-height: 25px;
}

.weathCmp {
  border-spacing: 0px;
  width: 100%;
  border: 1px solid #dddddd;
}
.weathCmp thead tr td {
  color: #fff;
  font-size: 15px;
  padding: 4px 0 4px 8px;
  border-bottom: 1px solid #dddddd;
}
.weathCmp thead tr th {
  padding: 5px 0;
  border-right: 1px solid #dddddd;
  /* cursor: pointer; */
}

.weathCmp tbody tr :nth-of-type(1) {
  text-align: center;
}

.weathCmp thead tr th:nth-of-type(3) {
  border-right: none;
}
.weathCmp tbody tr th {
  color: black;
  background-color: #eeeeee;
  font-size: 15px;
  padding: 5px;
  border-top: 1px solid #dddddd;
}
.weathCmp tbody tr td {
  text-align: right;
  color: rgba(0, 0, 0, 0.822);
  font-size: 1em;
  padding: 5px;
  border-top: 1px solid #dddddd;
  text-overflow: ellipsis;
}

.weathCmp th.k-header.gridHeader {
  text-align: center;
  font-size: 15px;
  font-weight: bold;
  color: black;
}

.weathCmp .k-icon .k-plus:hover{
  image-rendering: "k-icon k-i-collapse";
}

.weathCmp .k-i-add:before, .k-i-plus:before, .k-plus:before{
  content: "\E005"
}
.weathCmp .k-i-kpi-trend-equal:before, .k-i-minus:before, .k-minus:before {
  content: "\E001";
}
.cmpWeathSrchRight{
  float:right;
  margin-right: 10px ;
}
.weathCmpExcelBtn{
  border-radius: 4px;
  padding: 3px 10px;
  text-align: center;
  text-shadow: none;
  text-transform: uppercase;
  -webkit-transition: all 280ms ease;
  transition: all 280ms ease;
  color: #333;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  cursor: pointer;
  -webkit-appearance: none;
  display: inline-block;
  background-color: #fff;
  margin-top: -5px;
}