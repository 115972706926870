/* 배치프로세스 이력 그리드 */
.batchProcHistGrid {
  height: 100%;
}
/* 배치프로세스 이력 그리드 상단 컬럼 */
.batchProcHistGrid table thead tr th {
  /* border: 1px dotted red; */
  border-top: 1px solid #e3e3e3;
  text-align: center;
  font-size: 15px;
  font-weight: bold;
  color: #444444;
}
/* 배치 재수행 모달 폼 */
.batchProcHistDialog {
  
}