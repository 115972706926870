.LocationDropDownRgn {
  border-right: none;
  /* height: 21px; */
  padding: 4px;
  outline: none;
  width: 150px;
}

.searchBtn{
  position: absolute;
  width: 40px;
  height: 28px;
  border: solid 1px #e3e3e3;
  border-left: none;
  background: #fff;
  text-align: center;
  cursor: pointer;
  font-size: 14px;
  margin-top: 5px;
  margin-left: 10px;
}

.expctBuildBsmanColumn {
  text-align: right;
}