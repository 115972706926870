.consmDiv {
  background-color: white;
  min-width: 77.5rem;
  padding: 0.25rem;
  height: 85vh;
  /* background-color: #1d1d1d; */
  border: 0.125rem solid rgba(255, 255, 255, 0.08);
  /* position: relative; */
}

.consmDivIn {
  padding: 10px 20px 10px 20px;
  border: 2px solid #ddd;
  height: calc(85vh - 24px);
  /* padding: 0 1.5625rem; */
  /* height: 100%; */
  /* border: 0.125rem solid rgba(0, 136, 134, 0.5); */
  /* overflow-x: hidden; */
  /* overflow-y: auto; */
}

.uk-gridConsmSeach {
  /* margin-top: 30px; */
  /* margin-bottom: 30px; */
  height: 50px;
  /* padding-left: 10px; */
  /* padding-top: 20px; */
  /* background-color: #F0F2F3; */
}


.reportSearchConsm {
  width: 25%;
  margin: 2px 5px;
}
.searchConsmInput{
  padding:3px;
  width: 80%
}
.searchConsm {
  width: 25%;
  margin-left: 5px;
}
.consmSeachBtn {
  margin-left: 5px;
  margin-bottom: 5px;
  background-color: #fff;
}

/* #parentConsmDiv { */
  /* min-width: 77.5rem; */
    /* padding: 0.25rem; */
    /* background-color: #1d1d1d; */
    /* border: 0.125rem solid rgba(255, 255, 255, 0.08); */
    /* position: relative; */
/* } */

/* #childConsmDivIn { */
  /* padding: 0 1.5625rem; */
  /* height: 100%; */
  /* border: 0.125rem solid rgba(0, 136, 134, 0.5); */
  /* overflow-x: hidden; */
  /* overflow-y: auto; */
/* } */


table.reportConsmEnergyTable {
  padding: 10px;
  border-collapse: collapse;
  text-align: right;
  line-height: 1.5;
}
table.reportConsmEnergyTable thead th {
  text-align: center;
  padding: 10px;
  font-size: 18px;
  font-weight: bold;
  vertical-align: top;
  color: #369;
  border-bottom: 2px solid #036;
}

table.reportConsmEnergyTable tbody th {
  width: 2000px;
  padding: 5px;
  font-size: 14px;
  text-align: left;
  border-bottom: 0px solid rgb(255, 255, 255);
}

table.reportConsmEnergyTable tbody td {
  width: 2000px;
  height: 30px;
  font-weight: bold;
  padding: 1px;
  vertical-align: middle;
  background: #333333;
  background-clip: content-box;
}
table.reportConsmEnergyTable tbody{
  border-bottom: 2px solid #036;
}

.repConsmLeftChart{
  display: inline-block;
  width: 48%;
  height: 100%;
  margin-right: 1%;
  float:left;
}
.repConsmRightGrid{
  display: inline-block;
  width: 48%;
}