/* 모바일 */
@media (max-width: 767px) {
  .noti .k-detail-cell {
    padding: 0px;
    border: none;
  }
  .noti .k-detail-row .k-hierarchy-cell {
    display: none;
  }
  .noti table {
    width: 100%;
    margin: 0px !important;
    table-layout: fixed;
  }
  .noti tbody tr td {
    overflow: scroll;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  /* // 그리드 페이징 CSS */

  .noti .k-pager {
    text-align: center;
  }
  .noti .k-pager-numbers-wrap > select {
    display: block;
    background-color: white;
  }

  .noti .k-pager-numbers-wrap > ul {
    display: none;
  }
}

/* 모바일 */
@media (min-width: 767px) {
  .noti-info .k-toolbar {
    height: 50px;
  }
}
.noti {
  border-spacing: 0px;
  width: 100%;
  border: 1px solid #dddddd;
  height: calc(100vh - 230px);
}
.noti .k-grid-norecords {
  width: 250px;
}
.noti .k-grid thead,
.k-grid tbody,
.k-grid tfoot {
  text-align: center;
  font-family: "Noto Sans KR", sans-serif;
  line-height: 25px;
}
.noti thead tr td {
  color: #fff;
  font-size: 15px;
  border-bottom: 1px solid #dddddd;
}
.noti thead tr th {
  border-right: 1px solid #dddddd;
  /* cursor: pointer; */
}

.noti thead tr th:nth-of-type(3) {
  /* border-right: none; */
}
.noti tbody tr th {
  color: #333;
  background-color: #eee;
  text-align: center;
  font-size: 15px;
  /* border-top: 1px solid #ddd; */
}

.noti tbody tr:hover {
  background-color: #ededed;
}
.noti tbody tr td {
  font-size: 0.9rem;
  text-align: center;
  color: black;
  border-top: 1px solid #dddddd;
  text-overflow: ellipsis;
}

.noti .gridBody {
  color: black;
  font-size: 14px;
}

.noti th.k-header.gridHeader {
  text-align: center;
  font-size: 15px;
  font-weight: bold;
  color: black;
}

.noti-info {
  width: 647px;
  height: 390px;
  /* border: none; */
}
.noti-info .k-window-titlebar {
  padding: 5px 8px;
}

.noti-info table {
  width: 100%;
}

.noti-info table th {
  font-size: 13px;
  width: 20%;
  text-align: center;
  border: 1px solid #dddddd;
}
.noti-info table td {
  width: 30%;
  text-align: right;
  padding-right: 1em;
}

.noti-info .k-editor-content {
  border: #3b3838;
  border-width: 1px;
  border-style: solid;
}
.notiCheckBox .k-checkbox-label,
.k-radio-label {
  margin: 3px;
  min-height: 16px;
  line-height: 17px;
  vertical-align: text-top;
  display: inline-flex;
  align-items: flex-start;
  position: relative;
  cursor: pointer;
}

.notiCheckBox .k-checkbox-label::before,
.k-radio-label::before {
  content: "";
  width: 16px;
  height: 16px;
  border: #3b3838;
  border-width: 1px;
  border-style: solid;
}

.noti-grid {
  pointer-events: none;
  /* padding-top: 1px; */
  width: 94.5%;
  position: absolute;
  opacity: 0.5;
}

.noti .k-grid-norecords {
  display: contents;
}

.btnbox{
  text-align: right;
}