/* 상단 카테고리 날짜검색 드롭다운 */
.ensoCateDateDropDown {
  width: 100px;
}
/* 상단 카테고리 검색 버튼 */
.ensoCateSubmitBtn {
  background: white;
  border-radius: 5px;
  padding: 7.5px 12.5px;
}
/* 하단 엑셀 다운로드 버튼 */
.ensoExcelBtn {
  border: 1px solid #dddddd;
  border-radius: 5px;
  background: #ffffff;
  color: #333333;
  margin: 3px 7px 0 0;
  padding: 5px 7px;
  height: 32px;
  weight: 90px;
  font-size: 13px;
}
/* 하단 그리드 컬럼 */
.ensoGrid div thead {
  text-align: center;
  font-weight: bold;
  font-size: 15px;
}
.ensoGrid div thead tr {
  height: 64px;
}
.ensoGrid div thead tr th {
  padding: 20px 12px;
}
.ensoGrid table tbody tr :nth-of-type(2){
  text-align: right;
}
.ensoGrid table tbody tr :nth-of-type(3){
  text-align: right;
}
.ensoGrid table tbody tr :nth-of-type(4){
  text-align: right;
}

