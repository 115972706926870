.operationPage {
  background-color: white;
}

.operationPage1-3 {
  height: 274px;
  background-color: white;
}

.ListViewItem {
  height: 233px;
}

.operTime {
  padding-top: 8.5px;
}

.scroolYOper {
  position: relative;
  left: 35%;
  display: inline-block;
  margin-top: 100px;
  color: #ddd;
}

.operTitle {
  font-size: 18px;
  font-weight: bold;
}

.timeline {
  height: 53px;
}