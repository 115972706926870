.makrMngtGrid{
  height: 82vh;
  border: 1px solid gainsboro;
}

.makrMngtGrid thead tr th {
  text-align: center;

}

.makrMngtGrid .k-toolbar{
  border: none;
  border-bottom : 1px solid gainsboro;
  height: 60px;
}
