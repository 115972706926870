/* 그리드 */
.iotktLogGrid {
  height: 78vh;
  border: 1px solid gainsboro;
}
/* 그리드 상단 컬럼 */
.iotktLogGrid table thead tr th {
  /* border: 1px dotted red; */
  text-align: center;
  font-size: 15px;
  font-weight: bold;
  color: #444444;
}
/* 그리드 - '데이터가 존재하지 않습니다' */
.iotktLogGrid table tbody tr {
  /* border: 1px dotted red; */
  display: table-row;
}


.iotktLogG {
  pointer-events: none; 
  padding-top: 24px;
  width: 100%;
  position: absolute;
  opacity: 0.7;
}