.searchBtn{
  /* margin-left: 185%; */
  position: absolute;
  width: 40px;
  height: 28px;
  border: solid 1px #e3e3e3;
  border-left: none;
  background: #fff;
  text-align: center;
  cursor: pointer;
  font-size: 14px;
  /* margin-top: -15px; */
  /* margin-left: 62px; */
}