.datePeriodIcon {
    margin-left: 5px;
    margin-right: 5px;
  }
  
  .cidUserTerm {
    /* color : black; */
    /* margin-left: 5px;
    margin-right: 5px; */
    margin: 5px 9px 5px 0;
  }
  
  .dropdownStyle {
    width: 92px;
  }
  .operHistGrid {
    border: 1px solid gainsboro;
    height: calc(100vh - 275px);
    border: 1px solid #e4e4e4;
  }
  .operHistGrid th {
    font-weight: bold;
    font-size: 12px;
    text-align: center;
  }
  .operHistGrid table tbody tr td {
    border-bottom: 1px solid gainsboro;
  }
  
  .searchArea {
    /* margin-left: 5px;
    margin-right: 5px;
    margin-top: -4px; */
    margin: 5px 15px 5px 0;
  }
  
  .k-pager-numbers-wrap .k-dropdown {
    display: none;
  }
  
  .operHistBtn {
    background-color: #ededed;
    width: calc(25px + 1.42857em);
    border-radius: 5px;
  }
  .operHistBtn:hover {
    background-color: white;
  }
  
  .operHistExcelBtn {
    background-color: white;
    height: calc(10px + 1.42857em);
  }
  
  .operHistExcelBtn:hover {
    border: 1px solid #1e88e5;
  }
  .gathRawTable {
    width: 100%;
  }
  .gathRawTable tr th {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 25%;
  }
  .gathRawTable tr td {
    width: 25%;
  }
  .gathRawTable tr td span,
  .gathRawTable tr td input {
    width: 100%;
  }
  
  .gathRawSearchCal {
    width: 47%;
  }
  .gathRawBtnDiv {
    text-align: center;
  }
  .gathRawBtn {
    margin-top: 5px;
    width: 90%;
  }

  /* 수집항목 팁 */
  /* .statusViewHelp {
    padding: 5px;
    display: flex;
    justify-content: flex-end;
  } */
  
  .statusViewHelp {
    font-weight: bold;
    height: 25px;
    color: #bfbfbf;
  }
  
  .statusHelpImg {
    position: absolute;
    top: 17%;
    left: 28%;
    z-index: 9999;
    width: 900px;
    visibility: hidden;
    background-color: #fff;
    color: #231f20;
    font-size: 14px;
    display: block;
    padding: 15px 15px;
    box-shadow: 2px 2px 5px #999;
  }
  
  .statusViewHelp:hover .statusHelpImg {
    visibility: visible;
  }
  
  .helpTxtLine {
    display: block;
  }
  
  .helpCircleSize {
    height: 14px;
  }
  
  /* 모바일 */
  @media (max-width: 767px) {
    .operHistGrid .k-pager {
      text-align: center;
    }
    .operHistGrid .k-pager-numbers-wrap > select {
      display: block;
      background-color: white;
    }
  
    .operHistGrid .k-pager-numbers-wrap > ul {
      display: none;
    }
  
    .operHistGrid table thead tr th {
      padding: 5px;
    }
    .operHistGrid table tbody tr td {
      padding: 5px;
    }
  }