.saveUserInfo{
  background: #fff;
  border-radius: 5px;
  border: 1px solid #dddddd;
  padding: 2px 15px;
  text-align: center;
  text-shadow: none;
  color: #333;
  box-sizing: border-box;
  cursor: pointer;
  display: inline-block;
  vertical-align: -5px;
  width: 75px;
  height: 40px;
}
.usrInfoLeftCont,
.usrInfoRightCont{
  display: inline-block;
  width: 48%;
  margin-right: 1%;
}

.userInfoDiv{
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  height: 85vh;
}