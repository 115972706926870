.headata{
  text-shadow: none;
  border: none;
  line-height: 60px;
  font-family: "Noto Sans KR", sans-serif;
  box-sizing: border-box;
  height: 70px;
  z-index: auto;
  font-size: 19px;
  color: #ffffff;
  margin-right: 17px;
  cursor: default;
}