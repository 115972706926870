/* 그리드 헤더 */
.regiLoraLogGrid div table thead {
  text-align: center;
}

.regiLoraLogGrid k-grid-content k-virtual-content {
  background-color: #ffffff;
}

.regiLoraLogContentGrid{
  width: 100%;
  border: 1px solid gainsboro;
}
.regiLoraLogLoading {
  position: relative;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 60vh;
  pointer-events: none; 
  /* z-index: -1; */
}

.regiLoraLogG {
  pointer-events: none; 
  padding-top: 24px;
  width: 100%;
  position: absolute;
  opacity: 0.7;
}