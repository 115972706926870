.groupMnagerDropDown {
  position: absolute;
  margin: -36px 10px 0px 690px;
}
.groupAddBox {
  display: inline-block;
  width: 5%;
}
.installerBox {
  display: inline-block;
  width: 94%;
}
.left,
.right {
  width: 48.5%;
  position: relative;
}

.middle {
  width: 3%;
  text-align: center;
  padding-top: 2.1875rem;
}

.left,
.middle,
.right {
  float: left;
  height: 100%;
}

.groupButton {
  margin: 9px 0px;
}

.fontLarge {
  font-size: 20px;
}
