.Depth1 .ChMapTb-A{
  /* margin-left: 1015px;
  margin-top: -50px; */
  width: 180px;
  position: absolute;
  z-index: 10;
  background-color: #fafafa;
  border: 1px solid #dddddd;
  padding: 10px;
  margin-top: -20px; 
  margin-left: 975px;
}

.MapdropdownRgn {
  margin: 10px 0px 0px 10px;
}
.locationDropDownRgn {
  width: 96%;
}

.mapLocation1 {
  height: 674px;
  width: 470px;
  height: calc(100vh - 270px);
}

.mapLocation1D {
  padding-right: 15px;
}

.location1 {
  margin-top: -40px;
}

.mapCursor {
  cursor: pointer;
}

.location1ChmapTBox { 
  width: 470px;
  border: 1px solid #e06c5f;
  /* height: 900px; */
  margin: 40px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  background-color: #fafafa;
  margin-top: 55px;
}

.locationDepth {
  pointer-events: none; 
  /* padding-top: 1px; */
  width: 94.5%;
  position: absolute;
  opacity: 0.5;
}

.locationDepthLoading {
  position: relative;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 60vh;
  pointer-events: none; 
  /* z-index: -1; */
}
.locDepTspanDiv tspan{
  font-weight: 500;
  text-shadow: 1px 1px 1px gray;
}
.locDepPath{
  -webkit-filter: drop-shadow( 0px 1px 1px rgba(0, 0, 0, .7));
  filter: drop-shadow( 0px 1px 1px rgba(0, 0, 0, .7));
}


/* 데스크탑 풀사이즈 */
@media (min-height: 1000px) {
  #Mymap{
    width:100%;
    height:968px;
  }
}
/* 데스크탑 일반 */
@media (max-height: 1000px) {
  #Mymap{
    width:100%;
    height:825px;
  }
}