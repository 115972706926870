/* 담당자 모달 폼 */
.managerModalForm {
  /* border: 1px dotted red; */
  display: flex;
  height: 62.5vh;
  width: 20vw;
}
/* 담당자 모달 왼쪽 폼 */
.leftModalItem {
  /* border: 1px dotted blue; */
  width: 40%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
/* 담당자 모달 왼쪽 폼 간격 조정 */
.leftModalItem div {
  /* border: 1px dotted green; */
  padding: 0 1px 1px 0;
  font-weight: bold;
  font-size: 16px;
  text-align: right;
  margin: 0 0 34.5px;
  color: #444444;
}

/* 7번째 div 조절  */
/* .leftModalItem div {
  padding: 0 1px 1px 0;
  font-weight: bold;
  font-size: 16px;
  text-align: right;
  margin: 0 0 34.5px;
  color: #444444;
} */

/* 담당자 모달 오른쪽 폼 */
.rightModalItem {
  /* border: 1px dotted blue; */
  width: 60%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
/* 담당자 모달 오른쪽 폼 간격 조정 */
.rightModalItem form div {
  /* border: 1px dotted red; */
  margin: 27.5px 0;
}
/* 담당자 모달 input 태그 */
.rightModalItem form div input {
  /* border: 1px dotted red; */
  width: 185px;
}
/* 담당자 모달 dropdown 태그 */
.rightModalItem form div span {
  /* border: 1px dotted blue; */
  width: 185px;
}

.rightModalItem form div span {
  /* border: 1px dotted blue; */
  width: 185px;
}

/* 담당자 모달 오른쪽 아래 저장, 취소 버튼 */
.rightModalItem form div button {
  float: right;
  margin-left: 10px;
  background: white;
}

/* 멀티 셀렉터  */
.rightModalItem form .k-multiselect-wrap .k-clear-value {
  /* width: 50%; */
  display: none;
}

.rightModalItem form .k-multiselect-wrap .k-button .k-select {
  width: 50%;
  /* display: none; */
}

.rightModalItem form .k-multiselect-wrap li.k-button {
  width: 175px
}

.servrRgnListSelect {
  height: 50px;
  overflow-x: hidden;
  overflow-y: auto;
}






.mngModalTable{
  width: 100%;
  table-layout: fixed;
}
.mngModalTable tbody tr th{
  vertical-align: top;
  font-size: 15px;
  width: 40%;
  padding: 10px;
  padding-left: 2%;
  text-align: left;
}
.mngModalTable tbody tr td{
  width: 60%;
}
.mngModalTable tbody tr td input{
  width: 100%;
}
.mngModalTable > tbody > tr > td > span{
  width: 100% !important;
}

.mngModalTable .k-multiselect-wrap{
  min-height: 100% !important;
}

.mngModalSubmitBtnArea{
  margin-top: 15px;
  float: right;
}
.mngModalSubmitBtnArea button{
  margin: 5px;
  background-color: white;
  font-weight: 500;
}