/* 좌측 그리드 */
.iotktGrid {
  border:1px solid gainsboro;
  height: 91.8%;
}
/* 좌측 그리드 상단 컬럼 */
.iotktGrid table thead tr th {
  /* border: 1px dotted red; */
  text-align: center;
  font-size: 15px;
  font-weight: bold;
  color: #444444;
}
/* 좌측 그리드 필터 */
.iotktGrid table thead tr:nth-child(2) {
  /* border: 1px dotted red; */
  background: white;
}
/* 좌측 그리드 필터 input 태그 */
.iotktGrid table thead tr input {
  /* border: 1px dotted red; */
  border-radius: 5px;
}
/* 좌측 그리드 필터 연산자버튼 */
.iotktGrid table thead tr th div div div {
  /* border: 1px dotted red; */
  display: none;
}
/* 좌측 그리드 - '데이터가 존재하지 않습니다' */
.iotktGrid table tbody tr {
  /* border: 1px dotted red; */
  display: table-row;
}
/* 우측 그리드 드롭다운리스트*/
.rightFormDropDownList {
  /* border: 1px dotted red; */
  width: 99%;
}
/* 우측 그리드 인풋 */
.rightFormInputs {
  width: 99%;
}
/* 엑셀파일 가져오기 - 영역 */
.iotktUploadDiv {
  width: 600px;
  height: 150px;
  display: flex;
  flex-direction: column;
}
/* 엑셀파일 가져오기 - 업로드 버튼 */
.iotktUploadBtn {
  flex: none;
  margin-top: 15px;
  margin-left: auto;
}