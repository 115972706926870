.areaRankGrid table thead tr th {
  font-size: 13px;
  text-align: center;
  vertical-align: middle !important;
}
.areaRankGrid{
  border-top: 1px solid gainsboro;
}

.areaRankGrid table tbody tr :nth-of-type(3){
  text-align: right;
}
.areaRankGrid table tbody tr :nth-of-type(4){
  text-align: right;
}
.areaRankGrid table tbody tr :nth-of-type(5){
  text-align: right;
}
.areaRankGrid table tbody tr :nth-of-type(6){
  text-align: right;
}
.areaRankGrid table tbody tr :nth-of-type(7){
  text-align: right;
}
.areaMakerGrid{
  border-top: 1px solid gainsboro;
}

.areaMakerGrid table thead tr th{
  font-size: 13px;
  text-align: center;
  vertical-align: middle !important;
}

.areaMakerGrid table tbody tr :nth-of-type(3){
  text-align: right;
}
.areaMakerGrid table tbody tr :nth-of-type(4){
  text-align: right;
}
.areaMakerGrid table tbody tr :nth-of-type(5){
  text-align: right;
}
.areaMakerGrid table tbody tr :nth-of-type(6){
  text-align: right;
}
.areaMakerGrid table tbody tr :nth-of-type(7){
  text-align: right;
}
.cmpAreaMedal{
  position: absolute;
  /* top: 5px; */
  left: 10px;
}
.cmpAreaExcelBtn{
  background-color: white;
  margin-top: -7px;
}
.cmpAreaMedal{
  margin-top : -3px;
}
.cmpAreaSrchRight{
  float: right;
  margin-right: 10px;
}